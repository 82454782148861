import { sendEvent } from '../../../../lib/mixpanel';

export const trackButtonClickEvent = () => {
  sendEvent({
    name: 'BUTTON_CLICKED',
    customProperties: {
      button_name: 'reenviar_comunicado',
    },
    pageName: 'CAIXA_DE_ENTRADA',
  });
};
