import axios from 'axios';
import moment from 'moment-timezone';
import getSymbolFromCurrencyRaw from 'currency-symbol-map';
import { asset, getNextWorkingDayOfDate, zeroFill } from '../utils';
import banks from './banks';
import { __ } from '../i18n';
import { capitalize } from 'lodash';
import ChargeItem from '../containers/ChargeResult/Item';

export const paymentSituations = {
  ANTICIPATION_DISCOUNT: 'ANTICIPATION_DISCOUNT',
  FINE: 'FINE',
  INTEREST: 'INTEREST',
  FINE_INTEREST: 'FINE_INTEREST',
  NORMAL: 'NORMAL',
};

export const paymentMethods = {
  CARD: __('Credit Card'),
  BOLETO: __('Bank Boleto'),
  PIX: __('Pix'),
  EXTERNAL: __('Manual')
};

export const paymentMethodsShort = {
  CARD: __('Card'),
  BOLETO: __('Boleto'),
  PIX: __('Pix'),
  EXTERNAL: __('Manual')
};

export const statusInfo = {
  PAID: {
    icon: 'check',
    color: '#37b24d',
    chargeText: __('Paid'),
    paymentText: __('Paid'),
  },
  UNDER_PAID: {
    icon: 'check',
    color: '#37b24d',
    chargeText: __('Under paid'),

    paymentText: __('Under paid'),
  },
  OVER_PAID: {
    icon: 'check',
    color: '#37b24d',
    chargeText: __('Over paid'),
    paymentText: __('Over paid')
  },
  PENDING: {
    icon: 'clock',
    color: '#FBB23C',
    chargeText: __('To due'),
    paymentText: __('Waiting payment')
  },
  PROCESSING: {
    icon: 'clock',
    color: '#FBB23C',
    chargeText: __('To due'),
    paymentText: __('Waiting payment')
  },
  FAILED: {
    icon: 'times',
    color: '#ED335F',
    chargeText: __('Refused'),
    paymentText: __('Refused'),
  },
  CANCELED: {
    icon: 'hand holding usd',
    color: '#0080FF',
    chargeText: __('Refunded'),
    paymentText: __('Refunded'),
  },
  EXTERNAL: {
    icon: 'hand holding usd',
    color: '#37b24d',
    chargeText: __('Confirmed'),
    paymentText: __('Confirmed'),
  },
  PAID_MANUALLY: {
    icon: 'hand holding usd',
    color: '#37b24d',
    chargeText: __('Confirmed'),
    paymentText: __('Confirmed'),
  },
  OVERDUE: {
    icon: 'clock',
    color: '#ED335F',
    chargeText: __('Overdue'),
    paymentText: __('Overdue'),
  },
  EXPIRED: {
    icon: 'circle xmark',
    color: '#00000099',
    chargeText: __('Expired (fem)'),
    paymentText: __('Expired (fem)'),
  }
};

export const getBrazilianBanks = async () => {
  const apiBanks = await axios({
    method: 'get',
    url: 'https://brasilapi.com.br/api/banks/v1',
  }).catch(e => console.error(e));
  const banksWithCode = apiBanks.data.filter(bank => !!bank.code);
  const banksWithValue = banksWithCode.map(bank => ({ ...bank, label: zeroFill(bank.code, 3), value: `${zeroFill(bank.code, 3)} - ${bank.name}` }));
  return banksWithValue;
};

const AmexLogo = '/brands/Amex@3x.png';
const DinersClubLogo = '/brands/DinersClub@3x.png';
const DiscoverLogo = '/brands/Discover@3x.png';
const EloLogo = '/brands/Elo@3x.png';
const HipercardLogo = '/brands/Hipercard@3x.png';
const JCBLogo = '/brands/JCB@3x.png';
const MasterCardLogo = '/brands/MasterCard@3x.png';
const VisaLogo = '/brands/Visa@3x.png';

const AmexLogoNoColor = '/brands/Amex@3x@noColor.png';
const DinersClubLogoNoColor = '/brands/DinersClub@3x@noColor.png';
const DiscoverLogoNoColor = '/brands/Discover@3x@noColor.png';
const EloLogoNoColor = '/brands/Elo@3x@noColor.png';
const HipercardLogoNoColor = '/brands/Hipercard@3x@noColor.png';
const JCBLogoNoColor = '/brands/JCB@3x@noColor.png';
const MasterCardLogoNoColor = '/brands/MasterCard@3x@noColor.png';
const VisaLogoNoColor = '/brands/Visa@3x@noColor.png';

// Keep brands in the same order within all array/objects!
export const acceptedBrands = [
  'VISA',
  'MASTERCARD',
  'ELO',
  'DISCOVER',
  'HIPERCARD',
  'AMEX',
  'DINERS',
  'JCB',
];

const brandSecurityCodes = {
  VISA: 'CVV2',
  MASTERCARD: 'CVC',
  ELO: 'CVE',
  DISCOVER: 'CID/CVD',
  HIPERCARD: 'CVV',
  AMEX: 'CID/CSC',
  DINERS: 'cc-dinersclub',
  JCB: 'CVV',
};

export const brandIcons = {
  VISA: 'cc visa',
  MASTERCARD: 'cc mastercard',
  //   ELO: '',
  DISCOVER: 'cc discover',
  //   HIPERCARD: ''
  AMEX: 'cc amex',
  DINERS: 'cc dinersclub',
  JCB: 'cc jcb',
};

const brandAssets = {
  VISA: { colored: asset(VisaLogo), noColor: asset(VisaLogoNoColor) },
  MASTERCARD: { colored: asset(MasterCardLogo), noColor: asset(MasterCardLogoNoColor) },
  ELO: { colored: asset(EloLogo), noColor: asset(EloLogoNoColor) },
  DISCOVER: { colored: asset(DiscoverLogo), noColor: asset(DiscoverLogoNoColor) },
  HIPERCARD: { colored: asset(HipercardLogo), noColor: asset(HipercardLogoNoColor) },
  AMEX: { colored: asset(AmexLogo), noColor: asset(AmexLogoNoColor) },
  DINERS: { colored: asset(DinersClubLogo), noColor: asset(DinersClubLogoNoColor) },
  JCB: { colored: asset(JCBLogo), noColor: asset(JCBLogoNoColor) },
};

export const brandToIcon = brand => brandIcons[brand] || 'credit card front';

export const brandToLogo = brand => brandAssets[brand];

export const brandLogos = Object.values(brandAssets);

// Format: (\b(<startsWith>))(\d<numberOfRemainingCharacters>)
const brandRegexComplete = [
  ['VISA', /\b(4)(\d{12}|\d{15}|\d{18})$/],
  ['MASTERCARD', /(\b(222[1-9]|22[3-9][0-9]|2[3-6][0-9]|27[01][0-9]|2720))(\d{12}$)|\b(5[1-5])(\d{14}$)/],
  ['ELO', /(\b(636368)|\b(636369)|\b(438935)|\b(504175)|\b(451416)|\b(636297)|\b(506699))(\d{10}$)|(\b(4576)|\b(5067)|\b(4011))(\d{12}$)/],
  ['DISCOVER', /(\b(6[4|5]))(\d{14}$)|(\b(6011))(\d{12}$)|(\b(622))(\d{13}$)/],
  ['HIPERCARD', /(\b(38)|\b(38)|\b(60))((\d{11}|\d{14}|\d{17})$)/],
  ['AMEX', /(\b(34)|\b(37))(\d{13}$)/],
  ['DINERS', /((\b(54)|\b(55))(\d{14}$))|((\b(36))(\d{12}$))|((\b(2014)|\b(2049))(\d{11}$))|((\b((30[0-5])))(\d{11}$))/],
  ['JCB', /((\b((352[89]|35[3-8][0-9]))|\b(37))(\d{12}$))|((\b(3088)|\b(3096)|\b(3112)|\b(3158)|\b(3337)|\b(3528)|\b(3596))(\d{12}$))/],
];

const brandRegexPartial = [
  ['VISA', /\b(4)/],
  ['MASTERCARD', /(\b(222[1-9]|22[3-9][0-9]|2[3-6][0-9|27[01][0-9]|2720))|\b(5[1-5])/],
  ['ELO', /(\b(636368)|\b(636369)|\b(438935)|\b(504175)|\b(451416)|\b(636297)|\b(506699))|(\b(4576)|\b(5067)|\b(4011))/],
  ['DISCOVER', /(\b(6[4|5]))|(\b(6011))|(\b(622))/],
  ['HIPERCARD', /(\b(38)|\b(38)|\b(60))/],
  ['AMEX', /(\b(34)|\b(37))/],
  ['DINERS', /\b(54)|\b(55)|\b(36)|(\b(2014)|\b(2049))|\b(30[0-5])/],
  ['JCB', /(^(?:2131|1800|35\d{3})\d{11})|(\b(3088)|\b(3096)|\b(3112)|\b(3158)|\b(3337)|\b(3528)|\b(3596))/],
];

export function numberToBrand(number, partial = true) {
  if (typeof number !== 'string') return null;

  number = number.replace(/[\.\-\s_]/g, '');

  if (partial) {
    for (const regExp of brandRegexPartial) {
      if (number.match(regExp[1])) {
        return regExp[0];
      }
    }
  } else {
    for (const regExp of brandRegexComplete) {
      if (number.match(regExp[1])) {
        return regExp[0];
      }
    }
  }

  return null;
}

const brandMasks = {
  VISA: '9999-9999-9999-9999',
  MASTERCARD: '9999-9999-9999-9999',
  ELO: '9999-9999-9999-9999',
  DISCOVER: '9999-9999-9999-9999',
  HIPERCARD: '9999-9999-9999-9999',
  AMEX: '9999-999999-99999',
  DINERS: '9999-999999-9999',
  JCB: '9999-9999-9999-9999'
};

export function brandToMask(brand) {
  if (!brand) return brandMasks.VISA;
  return brandMasks[brand];
}

export const isBoletoMethod = stringMethod => (stringMethod === 'boleto' || stringMethod === 'BOLETO');

export const isCardMethodIncluded = arrayOfEnum => (arrayOfEnum && (arrayOfEnum.includes('card') || arrayOfEnum.includes('CARD')));

export const isBoletoMethodIncluded = arrayOfEnum => (arrayOfEnum && (arrayOfEnum.includes('boleto') || arrayOfEnum.includes('BOLETO')));

export const isPixMethodIncluded = arrayOfEnum => (arrayOfEnum && (arrayOfEnum.includes('pix') || arrayOfEnum.includes('PIX')));

export const isCardMethodAllowed = (charge) => {
  const { account } = charge;
  return isCardMethodIncluded(account?.methods) && charge.paymentMethods && isCardMethodIncluded(charge.paymentMethods);
};

export const isBoletoMethodAllowed = (charge) => {
  const { account } = charge;
  return isBoletoMethodIncluded(account?.methods) && charge.paymentMethods && isBoletoMethodIncluded(charge.paymentMethods);
};

export const isPixMethodAllowed = (charge) => {
  const { account } = charge;
  return isPixMethodIncluded(account?.methods) && charge.paymentMethods && isPixMethodIncluded(charge.paymentMethods);
};

export const wasPaymentSituationAnticipationDiscount = (charge, date) => {
  const { anticipationDiscountUntil, dateLimit } = charge;

  const expirationDate = new Date(dateLimit);
  expirationDate.setHours(0, 0, 0, 0);

  const today = new Date(date);
  today.setHours(0, 0, 0, 0);

  if (charge.hasAnticipationDiscount) {
    const anticipationUnitlDate = new Date(expirationDate);
    anticipationUnitlDate.setDate(anticipationUnitlDate.getDate() - anticipationDiscountUntil);

    if (today.getTime() <= anticipationUnitlDate.getTime()) return true;
  }
  return false;
};

export const isPercentageType = type => type === 'percentage' || type === 'PERCENTAGE';

const round = a => Math.round((a + Number.EPSILON) * 100) / 100;

export const calculateAbsorbedAmount = (paymentMethodList, amount, absorbedFee) => {
  if (!paymentMethodList
    || !paymentMethodList.CARD?.fee
    || !paymentMethodList.BOLETO?.fee
    || !amount) return { card: 0, boleto: 0 };
  const { CARD, BOLETO } = paymentMethodList;

  let cardFinal;
  if (CARD.feeType === 'FIXED') {
    cardFinal = isCardMethodIncluded(absorbedFee) ? round(amount) : round(amount + CARD.fee);
  } else {
    cardFinal = isCardMethodIncluded(absorbedFee) ? round(amount) : round(amount * (1 + (CARD.fee / 100)));
  }

  const boletoFinal = isBoletoMethodIncluded(absorbedFee) ? round(amount) : round(amount + BOLETO.fee);

  return { card: cardFinal, boleto: boletoFinal };
};

export const calculateInstallments = (account, amount) => {
  if (!account || !amount) return [0];
  const { maxInstallments } = account.rates.card;
  const installments = [];
  for (let i = 0; i < maxInstallments; i++) {
    installments[i] = parseFloat(amount / (i + 1));
  }
  return installments;
};

export const lastBoletoExpirationDate = (momentChargeDateLimit, recentPayment) => {
  if (moment(recentPayment.created).isBefore(momentChargeDateLimit)) {
    return momentChargeDateLimit;
  }
  return getNextWorkingDayOfDate(moment.utc(recentPayment.created).endOf('day'));
};

/**
 * @param {PaymentItem[]} payments
 * @param {ChargeItem} charge
 * @param {boolean} notIgnore
 * @param {string} orgTimezone
 */
export const filterPayment = (payments, charge, notIgnore, orgTimezone) => {
  if (!payments || !payments.length) return null;

  const notDeletedPayments = payments.filter(payment => !payment.deleted);
  const orderedPayments = notDeletedPayments.sort((p1, p2) => {
    const p1Date = moment(p1.created);
    const p2Date = moment(p2.created);
    if (p1Date.isAfter(p2Date)) {
      return -1;
    } if (p1Date.isBefore(p2Date)) {
      return 1;
    }
    return 0;
  });

  const paidPayments = orderedPayments.filter(payment => payment.status === 'paid' || payment.status === 'PAID');
  if (paidPayments.length) return paidPayments[0];

  const recentPayment = orderedPayments[0];
  const momentChargeDateLimit = moment.utc(charge.dateLimit).tz(orgTimezone, true);
  const recentPaymentCreatedTz = moment.utc(recentPayment.created).tz(orgTimezone);

  if (charge.hasAnticipationDiscount
    && !(charge.paymentSituation === paymentSituations.ANTICIPATION_DISCOUNT)
    && wasPaymentSituationAnticipationDiscount(charge, recentPayment.created)) {
    return notIgnore ? recentPayment : { ignoringDiscount: true }; // it is not discount time anymore so ignore charge that was create in discount time
  } if (moment().isAfter(momentChargeDateLimit) && (recentPayment && moment.tz(orgTimezone).isAfter(recentPaymentCreatedTz,'day')) && moment().isAfter(lastBoletoExpirationDate(momentChargeDateLimit, recentPayment))) {
    return { ignoringExpiredBoleto: true };
  }
  return recentPayment;
};

/**
 * Calculate the value that will be paid by the reciver based on the payment and absorbed fee methods selected
 *
 * @param {Object} account // bank account that should receive the payment
 * @param {Number} amount // amount of charge
 * @param {Array} absorbedFee array of the payment methods that should have fee absorbed
 * @param {Array} paymentMethods array of payment methods
 *
 * @return Value that the reciver of the charge will pay
 */

export const calculateMinimumAmount = (account, amount, absorbedFee, paymentMethods) => {
  if (!account || !amount || !paymentMethods || !paymentMethods.length) return 0;

  const finalAmount = calculateAbsorbedAmount(account, amount, absorbedFee);
  if (isCardMethodIncluded(paymentMethods) && !isBoletoMethodIncluded(paymentMethods)) {
    //only card
    return finalAmount.card;
  } if (!isCardMethodIncluded(paymentMethods) && isBoletoMethodIncluded(paymentMethods)) {
    //only boleto
    return finalAmount.boleto;
  } if (isCardMethodIncluded(paymentMethods) && isBoletoMethodIncluded(paymentMethods)) {
    // both so get the smaller one
    return finalAmount.boleto < finalAmount.card ? finalAmount.boleto : finalAmount.card;
  }
  console.error('Error no payment method seletected in calculateMinimumAmount in payment.js');
  return finalAmount.boleto < finalAmount.card ? finalAmount.boleto : finalAmount.card;
};

export const brandToSecurityCodeName = brand => brandSecurityCodes[brand] || 'CVV';

export const numberToSecurityCodeName = number => brandSecurityCodes[numberToBrand(number)] || 'CVV';

export const getSymbolFromCurrency = currency => getSymbolFromCurrencyRaw(currency) || 'R$';

export const idToBankName = id => banks.find(bank => bank.value === id);

export const bankNameToId = bankName => banks.find(bank => bank.label === bankName);

export const tokenizeCreditCard = async (card, toggles, env) => {
  const isSandbox = env !== 'production' && env !== 'beta';
  const { ccNumber, name, expiration, cvv, documentNumber } = card;
  const [month, year] = expiration.split('/');
  const pagarme_v5_tokenize_credit_card = toggles.find(
    (toggle) => toggle.name === 'pagarme_v5_tokenize_credit_card',
  );
  const service = pagarme_v5_tokenize_credit_card?.enabled
    ? 'pagarmeV5'
    : 'mundipagg';
  const baseUrl =
    service === 'pagarmeV5'
      ? 'https://api.pagar.me/core/v5/tokens'
      : 'https://api.mundipagg.com/core/v1/tokens';

  if (service) {
    const publicKey = isSandbox
      ? 'pk_test_kYzE4brcLBu23o4b'
      : 'pk_voJp5bPulcAzB4z0';

    const response = await axios({
      method: 'post',
      url: baseUrl + '?appId=' + publicKey,
      data: {
        type: 'card',
        card: {
          number: ccNumber.replace(' ', ''),
          holder_name: name,
          holder_document: documentNumber,
          exp_month: month,
          exp_year: year,
          cvv,
        },
      },
    });

    return { cardHash: response.data.id };
  }
  throw Error('Payment service not defined');
};

/**
 * @param {[{
 *  name: string,
 *  splitConfiguration: {
 *    nodes: {
 *      fee: number,
 *      feeType: string
 *    }
 *  }
 * }]} [accountPaymentMethods]
 * @param {{
 *  absorbCard: boolean,
 *  absorbBoleto: boolean,
 *  absorbPix: boolean,
 * }} values
 * @returns
 */
export const getAccountFormPaymentMethods = (accountPaymentMethods, values) => {
  if (!accountPaymentMethods) return {
    CARD: {
      absorb: values.absorbCard,
      methodName: __('Credit Card'),
      methodType: 'CARD',
      feeString: __('The fee is 2,9%% per transaction')
    },
    BOLETO: {
      absorb: values.absorbBoleto,
      methodName: __('Bank Boleto'),
      methodType: 'BOLETO',
      feeString: __('The fee is R$2,90 per transaction'),
    },
    PIX: {
      absorb: values.absorbPix,
      methodName: __('Pix'),
      methodType: 'PIX',
      feeString: __('The fee is R$2,50 per transaction'),
    }
  };;

  return accountPaymentMethods?.nodes.map((paymentMethod) => {
    const absorbPaymentMethodField = `absorb${capitalize(paymentMethod.name)}`
    const { fee, feeType } = paymentMethod.splitConfiguration.nodes[0];
    const formattedFee =  (feeType === 'PERCENTAGE') ? `${fee}%` : `${fee.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
    return  {
      feeString: __('The fee is %s per transaction', formattedFee),
      methodName: paymentMethods[paymentMethod.name],
      methodType: paymentMethod.name.toUpperCase(),
      absorb: values[absorbPaymentMethodField]
    };
  })
}

const payment = {
  paymentSituations,
  paymentMethods,
  paymentMethodsShort,
  statusInfo,
  getBrazilianBanks,
  acceptedBrands,
  brandToIcon,
  brandToLogo,
  brandIcons,
  brandLogos,
  isBoletoMethod,
  isCardMethodIncluded,
  isBoletoMethodIncluded,
  isCardMethodAllowed,
  isBoletoMethodAllowed,
  wasPaymentSituationAnticipationDiscount,
  isPercentageType,
  calculateAbsorbedAmount,
  lastBoletoExpirationDate,
  filterPayment,
  calculateMinimumAmount,
  brandToSecurityCodeName,
  numberToSecurityCodeName,
  getSymbolFromCurrency,
  idToBankName,
  bankNameToId,
  tokenizeCreditCard,
  getAccountFormPaymentMethods
};

export default payment;
