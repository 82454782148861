import React from 'react';
import { listenHostHistory } from '@classapp/sebastiao-routes';
import Mixpanel from '../lib/mixpanel';

if (typeof window === 'undefined') {
  global.window = {};
}

export default class App extends React.Component {
  componentDidMount() {
    listenHostHistory();
    Mixpanel.initialize();
  }

  render() {
    return this.props.children;
  }
}
