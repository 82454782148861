import { mergeGroupsWithRoles } from '../mergeGroupsWithRoles';

describe('mergeGroupsWithRoles', () => {
  let props;

  beforeEach(() => {
    jest.clearAllMocks();

    props = {
      groups: [
        {
          entities: {
            nodes: [
              {
                id: 1,
              },
            ],
          },
        },
      ],
      recipients: [
        {
          id: 1,
          roles: {
            nodes: [
              {
                id: 1,
                name: 'role1',
              }
            ]
          }
        },
      ],
    };
  });

  describe('when params is not provided', () => {
    it('should return empty array when groups is not provided', () => {
      props.groups = null;
      const result = mergeGroupsWithRoles(props.groups, props.recipients);
      expect(result).toStrictEqual([]);
    });

    it('should return empty array when recipients is not provided', () => {
      props.recipients = null;
      const result = mergeGroupsWithRoles(props.groups, props.recipients);
      expect(result).toStrictEqual([]);
    });
  });

  describe('when props is provided ', () => {
    it('should return groups list with recipients', () => {
      const result = mergeGroupsWithRoles(props.groups, props.recipients);
      expect(result).toStrictEqual([
            {
             "entities":  {
               "nodes":  [
                  {
                  "id": 1,
                   "roles":  {
                     "nodes":  [
                        {
                        "id": 1,
                         "name": "role1",
                       },
                     ],
                   },
                 },
               ],
             },
           },
         ]);
    });
  });
});
