import { hasInvalidSendLaterItem } from '../hasInvalidSendLaterItem';

describe('hasInvalidSendLaterItem', () => {
  it('should return true when sendAt is greater than or equal to dateLimit', () => {
    const dateLimit = new Date();
    dateLimit.setHours(23, 59, 59);
    const sendAt = new Date(dateLimit.getTime() + 1);
    const result = hasInvalidSendLaterItem({
      currentValue: { dateLimit },
      sendAt,
    });
    expect(result).toBe(true);
  });

  it('should return false when sendAt is less than dateLimit', () => {
    const sendAt = new Date();
    const dateLimit = new Date();
    dateLimit.setHours(22, 59, 59);
    const result = hasInvalidSendLaterItem({
      currentValue: { dateLimit },
      sendAt,
    });
    expect(result).toBe(false);
  });
});
