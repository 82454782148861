import { sendEvent } from '../../../../../lib/mixpanel';
import { trackButtonClickEvent } from '../trackEvent';

jest.mock('../../../../../lib/mixpanel', () => ({
  sendEvent: jest.fn(),
}));

describe('trackButtonClickEvent', () => {
  beforeEach(() => {
    jest.clearAllMocks();
  });
  it('should call sendEvent with the correct parameters', () => {
    trackButtonClickEvent();

    expect(sendEvent).toHaveBeenCalledWith({
      name: 'BUTTON_CLICKED',
      customProperties: {
        button_name: 'reenviar_comunicado',
      },
      pageName: 'CAIXA_DE_ENTRADA',
    });
  });
  it('should call sendEvent exactly once', () => {
    trackButtonClickEvent();

    expect(sendEvent).toHaveBeenCalledTimes(1);
    expect(sendEvent).toHaveBeenCalledWith({
      name: 'BUTTON_CLICKED',
      customProperties: {
        button_name: 'reenviar_comunicado',
      },
      pageName: 'CAIXA_DE_ENTRADA',
    });
  });

  it('should not call sendEvent when trackButtonClickEvent is not called', () => {
    expect(sendEvent).not.toHaveBeenCalled();
  });

  it('should call sendEvent with a valid object structure', () => {
    trackButtonClickEvent();

    const expectedKeys = ['name', 'customProperties', 'pageName'];
    const callArgs = sendEvent.mock.calls[0][0];

    expectedKeys.forEach((key) => {
      expect(callArgs).toHaveProperty(key);
    });
  });

  it('should handle errors in sendEvent gracefully', () => {
    sendEvent.mockImplementation(() => {
      throw new Error('sendEvent failed');
    });

    expect(() => trackButtonClickEvent()).toThrow('sendEvent failed');
  });
});
