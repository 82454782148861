import { deleteFolderRepository } from '../DeleteFolder';
import * as DataSource from '../../../DataSource/ApolloDataSource';

jest.mock('graphql-tag', () => ({
  __esModule: true,
  default: jest.fn(),
}));

describe('given the deleteFolderRepository function', () => {
  it('should call Mutation with the correct params', async () => {
    const mutationMock = jest.fn();
    jest.spyOn(DataSource, 'Mutation').mockImplementation(() => mutationMock);
    const variables = {
      folderName: 'id',
    };

    await deleteFolderRepository(variables.folderName);

    expect(DataSource.Mutation).toHaveBeenCalledWith(undefined, variables);
  });

  it('should call use default values in Mutation function', async () => {
    const mutationMock = jest.fn();
    jest.spyOn(DataSource, 'Mutation').mockImplementation(() => mutationMock);
    const variables = {
      folderName: 'id',
    };

    await deleteFolderRepository(variables.folderName);

    expect(DataSource.Mutation).toHaveBeenCalledWith(undefined, variables);
  });
});
