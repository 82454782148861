/*eslint prefer-const: off*/
/*eslint-env es6*/
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Loader, Button } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import TopCover from '../../components/TopCover';
import Modal from '../../components/Modal';

import EntityHeader from '../Entity/Header';
import EntityItem from '../Entity/Item';
import Container from '../../components/Container';

import '../../assets/css/MainLayout.module.scss';

import { __ } from '../../i18n';
import { Query } from '../../app/Data/DataSource/ApolloDataSource';
import * as analytics from '../../lib/analytics';
import getEntityMenuItems from '../../app/helpers/getEntityMenuItems';

@inject('store')
@graphql(
  gql`
    query EntityNodeQuery($id: ID!) {
      node(id: $id) @connection(key: "Entity", filter: ["id"]) {
        ... on Entity {
          id: dbId
          eid
          type
          disabled
          invisible
          isChannel
          hasViewer
          scope
          policy
          confAttendanceEnabled
          hasUnreadDirectMessages
          organization {
            id: dbId
            color
            live
            link
            type
            username
            fullname
            confArrived
            confMessage
            confMessageStudent
            confMessageStaff
            confMedia
            confCommitment
            confSurvey
            confCharge
            confReport
            confForm
            confReplyStudent
            confRepliesStudent
            confMediaStudent
            confTerms
            confPresence
            confAdminList
            network {
              id
            }
            confChannelMetrics
            confRecurrence
            created
            plan
            premiumPeriod
            isDemonstration
            monthlyMessagesCount
            monthlyMessageQuota
            confChannelMetrics
            usage
            timezone
            unleashStatus {
              approve_message
              approve_reply
              web_menu_conversations
              show_banner_menu_conversations
              network_communication
              show_banner_network_communication
              web_conversations_automatic_messages
              new_student_flow
              custom_media_button
              custom_media
              communication_status_feedback
              recipients_list_screen
              pagarme_v5_tokenize_credit_card
            }
            organizationBanners {
              nodes {
                id
                bannerType
              }
            }
            links {
              totalCount
              nodes {
                key
                id: dbId
                icon
                title
                url
                type
                replaces
              }
            }
            productFeatures {
              totalCount
              nodes {
                id
                identifier
                plan
                paid
                live
                # isEnabled(organizationUsername: $username)
              }
            }
            features {
              messages
              messageManager
              readingControl
              files
              surveys
              commitments
              moments
              reports
              videos
              audios
              charges
              forms
              otherFiles
              dashboard
              api
              support
              labels
              deleteMessages
              editMessages
              links
              videoConference
            }
            featureToggles {
              web_menu_conversations_notification_bell
            }
            tags {
              nodes {
                id: dbId
                name
              }
            }
            permissions {
              entityScopes
              hasAdmin
            }
            paymentMethods {
              name
            }
            ...TopCover
          }
          messages(folder: UNREAD) {
            totalCount
          }
          moments(folder: NEW) {
            totalCount
          }
          ...EntityHeader
        }
      }
    }
    ${EntityHeader.fragments.entity}
    ${TopCover.fragments.organization}
  `,
  {
    options: (ownProps) => ({
      variables: {
        id: ownProps.params.entity_id,
      },
    }),
  },
)
@observer
export default class EntityNode extends React.Component {
  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { store } = this.props;
    const { data } = nextProps;
    const { unleash } = store;

    if ((data.loading && !data.node) || !data.node) return;

    const { hasViewer, organization } = data.node;

    store.entity = data.node;
    store.currentOrganization = organization;
    if (unleash && store.currentOrganization)
      unleash.setContext({ organizationId: [store.currentOrganization.id] });

    store.userEntityData = await this.getUserEntityData(organization?.id ?? 1);

    const { viewingUser } = store.userEntityData;

    if (hasViewer) {
      store.currentEntity = data.node;
    } else if (
      !store.currentEntity ||
      store.currentEntity.type !== 'ADMIN' ||
      !store.currentEntity.organization ||
      store.currentEntity.organization.id !== organization.id
    ) {
      let myEntity =
        viewingUser.entities &&
        viewingUser.entities.nodes &&
        viewingUser.entities.nodes.find(
          (e) => e.type === 'ADMIN' && e.organization.id == organization.id,
        );
      if (myEntity) {
        store.currentEntity = myEntity;
      } else {
        myEntity =
          store.currentUser.entities &&
          store.currentUser.entities.find((e) => e.type === 'ADMIN');
        (viewingUser?.entities?.nodes ?? []).push(myEntity);
        store.currentEntity = myEntity;
      }
    }

    // Force page refetch info
    store.entity = data.node;
    store.currentOrganization = organization;
  }

  componentWillUnmount() {
    // this.props.store.currentEntity = null;
    this.props.store.currentOrganization = null;
    this.props.store.entity = null;
  }

  /**
   * @param {Number} organizationId
   */
  async getUserEntityData(organizationId) {
    const userEntityNodeQuery = gql`
      query UserEntityNodeQuery($organizationId: ID!) {
        viewingUser: viewer {
          id: dbId
          fullname
          phone
          email
          isMaster
          language
          entities(organizationId: $organizationId, limit: 500) {
            nodes {
              id: dbId
              type
              code
              disabled
              invisible
              hasViewer
              picture {
                uri
                id: dbId
                key
              }
              organization {
                id: dbId
              }
              ...EntityItemEntity
            }
          }
        }
      }
      ${EntityItem.fragments.entity}
    `;

    const { data } = await Query(userEntityNodeQuery, {
      organizationId: organizationId,
    });
    return data;
  }

  render() {
    const { data, store, router } = this.props;
    const {
      currentEntity,
      userEntityData,
      unleash,
      currentOrganization,
      currentUser,
    } = store;

    if (data.error) {
      return (
        <Modal
          header={__('Error')}
          content={__('An error has occurred.')}
          actions={[
            <Button
              data-action="cancel"
              key={1}
              primary
              content={__('OK')}
              onClick={() => {
                this.setState({ onLeave: true }, () => {
                  this.props.router.push('/');
                });
              }}
            />,
          ]}
        />
      );
    } else if (
      (data.loading && !data.node) ||
      !data.node ||
      !userEntityData ||
      userEntityData.loading ||
      !userEntityData.viewingUser ||
      !store.currentEntity
    ) {
      return <Loader active />;
    }

    const { type, invisible, id, organization, messages, moments, isChannel } =
      data.node;
    const { viewingUser } = userEntityData;

    const { items, mobileItems } = getEntityMenuItems({
      type,
      invisible,
      id,
      viewingUser,
      organization,
      store,
      messages,
      isChannel,
      moments,
      unleash: unleash,
      currentOrganization: currentOrganization,
      currentEntityType: currentEntity?.type,
      currentEntityHasUnreadDirectMessages:
        currentEntity?.hasUnreadDirectMessages,
      currentUserOrganization: currentUser?.organization,
      accessAccountAnalytics: (category) => {
        analytics.sendGoogleAnalyticsEvent(
          {
            name: 'Account',
            category: category,
            label: `OrganizationId: ${store?.currentOrganization?.id} | EntityId: ${store?.currentEntity?.id}`,
          },
          { store },
        );
      },
    });

    const canSendMessage =
      currentEntity &&
      currentEntity.organization &&
      currentEntity.organization.confMessage &&
      (currentEntity.organization.confMessageStaff ||
        currentEntity.type !== 'STAFF') &&
      (currentEntity.organization.confMessageStudent ||
        currentEntity.type !== 'STUDENT') &&
      currentEntity.organization.features.messages &&
      !currentEntity.disabled;

    return (
      <Container
        desktopItems={items}
        mobileItems={mobileItems}
        router={router}
        id="EntityNodeMenu"
        headerButton={
          (store.currentEntity && store.currentEntity.id) ===
            (store.entity && store.entity.id) && canSendMessage
            ? 'write'
            : 'profile'
        }
      >
        {this.props.children}
      </Container>
    );
  }
}
