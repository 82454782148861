import * as repository from '../../../../Data/Repository/Permissions/getOrganizationsByNetworkIdRepository';
import { getOrganizationsByNetworkIdUseCase } from '../getOrganizationsByNetworkIdUseCase';

describe('given getOrganizationsByNetworkId use case', () => {
  const mocks = {
    organizationId: '1',
    entityId: '1',
    search: '',
    limit: 30,
    offset: 0,
  };

  const returnMock = {
    data: {
      node: {
        organizations: {
          nodes: [
            {
              id: '1',
              fullname: 'John Doe',
              logo: {
                uri: 'uri',
              },
            },
          ],
          totalCount: 1,
        },
      },
    },
    error: [],
  };

  beforeAll(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    jest
      .spyOn(repository, 'getOrganizationsByNetworkIdRepository')
      .mockResolvedValue(returnMock);
  });

  it('should be able to call use case correctly', async () => {
    const result = await getOrganizationsByNetworkIdUseCase(mocks);
    expect(result).toStrictEqual(returnMock);
  });
});
