/***
 * @param {{
 *   currentValue: Object,
 *   sendAt: Date
 * }} params
 */
export function hasInvalidSendLaterItem({ currentValue, sendAt }) {
  currentValue.dateLimit.setHours(23, 59, 59);
  return sendAt.getTime() >= currentValue.dateLimit.getTime();
}
