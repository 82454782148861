import { getAvailableTopBannerRepository } from '../getAvailableTopBanner';
import * as dataSource from '../../../DataSource/ApolloDataSource';

describe('getAvailableTopBannerRepository', () => {
  beforeEach(() => {
    jest.spyOn(dataSource, 'Query').mockResolvedValue({
      data: {
        node: null,
      },
      errors: null,
    });
  });

  it('should return the correct data', async () => {
    const entityId = '1';

    const response = await getAvailableTopBannerRepository({ entityId });

    expect(response).toEqual({
      data: {
        node: null,
      },
      errors: null,
    });
  });
});
