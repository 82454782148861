import { sendGroupSavedEvent } from '../sendGroupEvent';
import { sendEvent } from '../../../../lib/mixpanel';

jest.mock('../../../../lib/mixpanel', () => ({
  sendEvent: jest.fn(),
  TRACK_EVENT_NAME: {
    BUTTON_CLICKED: 'BUTTON_CLICKED',
  },
}));

jest.mock('../../../../lib/mixpanel/pages.js', () => ({
  TRACK_EVENT_PAGE_NAME: {
    GROUPS: 'GRUPOS',
  },
}));

describe('sendGroupSavedEvent', () => {
  afterEach(() => {
    jest.clearAllMocks();
  });

  it('should call sendEvent with the correct parameters', () => {
    const buttonName = 'save_group';
    const groupName = 'Group 1';
    const markerIds = [1, 2, 3];

    sendGroupSavedEvent({ buttonName, groupName, markerIds });

    expect(sendEvent).toHaveBeenCalledWith({
      name: 'BUTTON_CLICKED',
      pageName: 'GRUPOS',
      customProperties: {
        button_name: buttonName,
        group_name: groupName,
        quantity_markers: markerIds.length,
        markers_type: markerIds.join(', '),
      },
    });
  });

  it('should call sendEvent with default values if some parameters are missing', () => {
    sendGroupSavedEvent({});

    expect(sendEvent).toHaveBeenCalledWith({
      name: 'BUTTON_CLICKED',
      pageName: 'GRUPOS',
      customProperties: {
        button_name: 'salvar_grupo',
        group_name: undefined,
        quantity_markers: 0,
        markers_type: 'Nenhum',
      },
    });
  });

  it('should handle an empty markerIds array', () => {
    sendGroupSavedEvent({
      buttonName: 'save_group',
      groupName: 'Group 1',
      markerIds: [],
    });

    expect(sendEvent).toHaveBeenCalledWith({
      name: 'BUTTON_CLICKED',
      customProperties: {
        button_name: 'save_group',
        group_name: 'Group 1',
        quantity_markers: 0,
        markers_type: 'Nenhum',
      },
      pageName: 'GRUPOS',
    });
  });

  it('should handle markerIds with non-numeric values', () => {
    sendGroupSavedEvent({
      buttonName: 'save_group',
      groupName: 'Group 1',
      markerIds: ['a', 'b', 'c'],
    });

    expect(sendEvent).toHaveBeenCalledWith({
      name: 'BUTTON_CLICKED',
      customProperties: {
        button_name: 'save_group',
        group_name: 'Group 1',
        quantity_markers: 3,
        markers_type: 'a, b, c',
      },
      pageName: 'GRUPOS',
    });
  });

  it('should handle undefined parameters gracefully', () => {
    sendGroupSavedEvent();

    expect(sendEvent).toHaveBeenCalledWith({
      name: 'BUTTON_CLICKED',
      customProperties: {
        button_name: 'salvar_grupo',
        group_name: undefined,
        quantity_markers: 0,
        markers_type: 'Nenhum',
      },
      pageName: 'GRUPOS',
    });
  });

  it('should handle empty buttonName and groupName correctly', () => {
    sendGroupSavedEvent({
      buttonName: '',
      groupName: '',
      markerIds: [1, 2],
    });

    expect(sendEvent).toHaveBeenCalledWith({
      name: 'BUTTON_CLICKED',
      customProperties: {
        button_name: '',
        group_name: '',
        quantity_markers: 2,
        markers_type: '1, 2',
      },
      pageName: 'GRUPOS',
    });
  });

  it('should handle null or undefined markerIds gracefully', () => {
    sendGroupSavedEvent({
      buttonName: 'save_group',
      groupName: 'Group 1',
      markerIds: null,
    });

    expect(sendEvent).toHaveBeenCalledWith({
      name: 'BUTTON_CLICKED',
      customProperties: {
        button_name: 'save_group',
        group_name: 'Group 1',
        quantity_markers: 0,
        markers_type: 'Nenhum',
      },
      pageName: 'GRUPOS',
    });
  });

  it('should handle markerIds containing invalid values', () => {
    const groupName = 'Group 1';
    const markerIds = [1, null, undefined, 'a'];

    sendGroupSavedEvent({ groupName, markerIds });

    expect(sendEvent).toHaveBeenCalledWith({
      name: 'BUTTON_CLICKED',
      pageName: 'GRUPOS',
      customProperties: {
        button_name: 'salvar_grupo',
        group_name: groupName,
        quantity_markers: markerIds.length,
        markers_type: '1, , , a',
      },
    });
  });

  it('should handle empty or undefined groupName gracefully', () => {
    sendGroupSavedEvent({
      buttonName: 'save_group',
      markerIds: [1, 2],
    });

    expect(sendEvent).toHaveBeenCalledWith({
      name: 'BUTTON_CLICKED',
      pageName: 'GRUPOS',
      customProperties: {
        button_name: 'save_group',
        group_name: undefined,
        quantity_markers: 2,
        markers_type: '1, 2',
      },
    });
  });

  it('should call sendEvent exactly once', () => {
    sendGroupSavedEvent({
      buttonName: 'save_group',
      groupName: 'Group 1',
      markerIds: [1, 2, 3],
    });

    expect(sendEvent).toHaveBeenCalledTimes(1);
  });

  it('should use default values when all parameters are missing', () => {
    sendGroupSavedEvent();

    expect(sendEvent).toHaveBeenCalledWith({
      name: 'BUTTON_CLICKED',
      pageName: 'GRUPOS',
      customProperties: {
        button_name: 'salvar_grupo',
        group_name: undefined,
        quantity_markers: 0,
        markers_type: 'Nenhum',
      },
    });
  });

  it('should handle a large array of markerIds', () => {
    const markerIds = Array.from({ length: 1000 }, (_, i) => i + 1);
    const groupName = 'Large Group';

    sendGroupSavedEvent({ groupName, markerIds });

    expect(sendEvent).toHaveBeenCalledWith({
      name: 'BUTTON_CLICKED',
      pageName: 'GRUPOS',
      customProperties: {
        button_name: 'salvar_grupo',
        group_name: groupName,
        quantity_markers: 1000,
        markers_type: markerIds.join(', '),
      },
    });
  });
});
