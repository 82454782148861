import { deleteFolderUseCase } from '../DeleteFolder';
import * as DataSource from '../../../../Data/DataSource/ApolloDataSource';

describe('given the deleteFolderUseCase function', () => {
  it('should call deleteFolderRepository function', async () => {
    jest.spyOn(DataSource, 'Mutation').mockImplementation(() => ({
      data: {
        deleteMessageFolder: {
          success: true,
        },
      },
    }));
    const variables = {
      folderName: 'id',
    };

    const result = await deleteFolderUseCase(variables.folderName);

    expect(result).toEqual(true);
  });

  it('should return error', async () => {
    const consoleSpy = jest.spyOn(console, 'error');
    const error = new Error('Error');
    jest.spyOn(DataSource, 'Mutation').mockImplementation(() => {
      throw error;
    });

    const result = await deleteFolderUseCase();

    expect(result).toEqual(false);
    expect(consoleSpy).toHaveBeenCalledWith(error);
  });
});
