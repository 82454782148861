import { sendEvent } from '../lib/mixpanel';
import { TRACK_EVENT_NAME } from '../lib/mixpanel';
import { TRACK_EVENT_PAGE_NAME } from '../lib/mixpanel/pages';

/**
 * Tracks the event for adding a group.
 *
 * @param {Object} params - The parameters for the event.
 * @param {string} params.groupName - The name of the group.
 * @param {Array} params.markerIds - The list of marker IDs.
 */
export const trackAddGroupEvent = ({ groupName, markerIds = [] }) => {
  sendEvent({
    name: TRACK_EVENT_NAME.BUTTON_CLICKED,
    pageName: TRACK_EVENT_PAGE_NAME.GROUPS,
    customProperties: {
      button_name: 'adicionar_grupo',
      group_name: groupName,
      quantity_markers: markerIds.length || 0,
      markers_type: markerIds.join(', ') || 'Nenhum',
    },
  });
};
