export function mergeRecipientsWithRoles(recipients, recipientsWithRoles) {
  if (!recipients || !recipientsWithRoles) return [];

  return recipients.map((recipient) => {
    const recipientWithRoles = recipientsWithRoles.find(
      (r) => r.id === recipient.id,
    );
    recipient.roles = recipientWithRoles ? recipientWithRoles.roles : [];
    return recipient;
  });
}
