import { getOrganizationUnleashRepository } from '../../../../Data/Repository/Unleash/GetOrganizationUnleashRepository';

/**
 * @param {number} orgId
 *
 * @returns {Promise<{
 *  data: {
 *    node: {
 *      unleashStatus: {
 *        approve_message: boolean,
 *        approve_reply: boolean,
 *        feature_arquivos: boolean,
 *        web_menu_conversations: boolean,
 *        show_banner_menu_conversations: boolean,
 *        menu_conversations_organization_direct_list: boolean,
 *        menu_conversations_organization_dashboard: boolean,
 *        network_communication: boolean,
 *        show_banner_network_communication: boolean,
 *        communication_status_feedback: boolean,
 *        recipients_list_screen: boolean,
 *        web_conversations_automatic_messages: boolean,
 *        custom_media_button: boolean,
 *        custom_media: boolean,
 *        pagarme_v5_tokenize_credit_card: boolean,
 *      }
 *    }
 *  }
 * }>}
 */
export async function getOrganizationUnleashUseCase(orgId) {
  return getOrganizationUnleashRepository({
    id: orgId,
  });
}
