import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { Menu, Icon, Divider, Segment, Label, Grid, Header } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';

import Modal from '../../components/Modal';
import History from '../../components/History';
import Avatar from '../../components/Avatar';

import MessageMedias from './Medias';
import MessageItem from './Item';
import MessageFiles from './Files';
import MessageCommitmentReminds from './CommitmentReminds';
import MessageSurveyResults from './SurveyResults';
import MessageReportResults from './ReportResults';
import EntityItem from '../Entity/Item';
import SurveyResultForm from '../SurveyResult/Form';
import CommitmentRemindForm from '../CommitmentRemind/Form';
import ReportResultItem from '../ReportResult/Item';
import MessageCharges from './Charges';
import MessageFormResults from './FormResults';
import FormResultItem from '../FormResult/Item';
import ChargeItem from '../ChargeResult/Item';

import * as utils from '../../utils';
import { __ } from '../../i18n';

const styles = {
  moreRecipients: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '32px',
    height: '32px',
    backgroundColor: '#9696a0',
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: '4em',
    border: 'solid 2px #ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

@inject('store')
@graphql(gql`query MessageEntityHistoryQuery (
  $entityId: ID!,
  $messageId: ID!
) {
    node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        message (id: $messageId ) {
          id: dbId
          commitments (limit: 40) {
            nodes {
              id: dbId
              ...CommitmentRemindForm
            }
          }
          surveys (limit: 40) {
            nodes {
              id: dbId
              ...SurveyResultForm
            }
          }
          reports (limit: 40) {
            nodes {
              id: dbId
              ...ReportResultItem
            }
          }
          forms (limit: 40) {
            nodes {
              id: formMessageId
              ...FormResultItem
            }
          }
          messageHistory {
            id: key
            subject
            content
            rendered
            summary
            created
            modified
            deleted
            fwMessage {
              id: dbId
              ...MessageItemEntity
            }
            label {
              id: dbId
              title
              color
            }
            ...MessageMedias
            ...MessageFiles
            ...MessageReportResults
            ...MessageSurveyResultsHistory
            ...MessageCommitmentReminds
            ...MessageFormResults
            ...MessageChargeHistory
          }
        }
      }
    }
  }
  ${MessageMedias.fragments.medias}
  ${MessageFiles.fragments.files}
  ${MessageReportResults.fragments.reports}
  ${MessageItem.fragments.entityMessage}
  ${MessageSurveyResults.fragments.surveysHistory}
  ${MessageCommitmentReminds.fragments.commitments}
  ${CommitmentRemindForm.fragments.commitment}
  ${SurveyResultForm.fragments.survey}
  ${ReportResultItem.fragments.report}
  ${MessageFormResults.fragments.forms}
  ${FormResultItem.fragments.form}
  ${ChargeItem.fragments.chargeHistory}
`, {
  options: ownProps => ({
    variables: {
    entityId: ownProps.params.entity_id,
    messageId: ownProps.params.message_id
    }
    }),
  skip: ownProps => ownProps.params.organization_id
  })
@graphql(gql`query MessageOrganizationHistoryQuery (
    $messageId: ID!,
  ) {
      node(id: $messageId) @connection(key: "Message", filter: ["messageId"]) {
        ... on Message {
          id: dbId
          commitments (limit: 40) {
            nodes {
              id: dbId
              ...CommitmentRemindForm
            }
          }
          surveys (limit: 40) {
            nodes {
              id: dbId
              ...SurveyResultForm
            }
          }
          reports (limit: 40) {
            nodes {
              id: dbId
              ...ReportResultItem
            }
          }
          forms (limit: 40) {
            nodes {
              id: formMessageId
              ...FormResultItem
            }
          }
          messageHistory {
            id: key
            subject
            content
            rendered
            summary
            created
            modified
            deleted
            labelId
            label{
              id: dbId
              color
              title
            }
            fwMessage {
              id: dbId
              ...MessageItemOrganization
            }
            ...MessageMedias
            ...MessageFiles
            ...MessageReportResults
            ...MessageSurveyResultsHistory
            ...MessageCommitmentReminds
            ...MessageFormResults
            ...MessageChargeHistory
          }
        }
      }
    }
    ${MessageMedias.fragments.medias}
    ${MessageFiles.fragments.files}
    ${MessageReportResults.fragments.reports}
    ${MessageItem.fragments.organizationMessage}
    ${MessageSurveyResults.fragments.surveysHistory}
    ${MessageCommitmentReminds.fragments.commitments}
    ${CommitmentRemindForm.fragments.commitment}
    ${SurveyResultForm.fragments.survey}
    ${ReportResultItem.fragments.report}
    ${MessageFormResults.fragments.forms}
    ${FormResultItem.fragments.form}
    ${ChargeItem.fragments.chargeHistory}
  `, {
  options: ownProps => ({
    variables: {
    messageId: ownProps.params.message_id,
    }
    }),
  skip: ownProps => !ownProps.params.organization_id
  })
@observer
export default class MessageHistory extends React.Component {
  renderRow = (message, key) => {
    const { data, params, store } = this.props;
    const { app } = store;

    const { type, organizationId, disabled } = data.node;

    const baseLink = params.entity_id ?
      `/entities/${params.entity_id}` : `/organizations/${params.organization_id}`;

    const lang = app.locale ? (app.locale === 'pt' ? 'pt-BR' : app.locale) : 'en';

    const entityLink = e =>
      (store.currentUser.isMaster || params.organization_id || (type === 'ADMIN' && organizationId === ((message && message.entity && message.entity.organizationId) || (message && message.organizationId))) ?
        `/organizations/${e.organizationId}/entities?search=${e.fullname}` : null);

    return (
      <Segment.Group key={key} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Segment secondary>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={13} style={{ display: 'flex', alignItems: 'center' }}>
                {
                  message.type === 'SENT' && !params.organization_id ?
                    (
                      message.recipientsCount === 1 ?
                        <span className="thirteen wide column" style={{ display: 'flex' }}>
                          {message.toEntity && <EntityItem to={entityLink(message.toEntity)} entity={filter(EntityItem.fragments.entity, message.toEntity)} fromMessage />}
                        </span>
                        :
                        <div style={{ width: '40px', height: '40px', position: 'relative', marginBottom: 3 }}>
                          <Avatar
                            avatar
                            src={message.toEntity && message.toEntity.picture && message.toEntity.picture.uri}
                            alt={message.toEntity && message.toEntity.fullname}
                            style={message.deleted ? (message.recipientsCount > 1 ? { width: '32px', height: '32px', opacity: 0.65 } : { width: '40px', height: '40px', opacity: 0.65 }) : (message.recipientsCount > 1 ? { width: '32px', height: '32px' } : { width: '40px', height: '40px' })}
                          />
                          {
                            message.recipientsCount > 1 ?
                              <div style={styles.moreRecipients}>
                                +{message.recipientsCount - 1}
                              </div>
                              :
                              null
                          }
                        </div>
                    )
                    :
                    (
                      <EntityItem
                        to={entityLink(message.entity)}
                        entity={message.entity}
                        fromMessage
                      />
                    )
                }
                <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                  <div>
                    {
                      message.type === 'SENT' && !params.organization_id ?
                        (
                          message.recipientsCount === 1 ?
                            <span style={{ display: 'flex', alignItems: 'baseline' }}>
                              <span>{__('to')}</span>
                              {
                                message.toEntity &&
                                (
                                  entityLink(message.toEntity) ?
                                    <Link to={entityLink(message.toEntity)} style={{ color: '#007fff', fontWeight: 500, fontSize: '16px', paddingLeft: '5px' }}>{message.toEntity.fullname}</Link>
                                    :
                                    <span style={{ fontWeight: 500, fontSize: '16px', paddingLeft: '5px' }}>{message.toEntity.fullname}</span>
                                )
                              }
                            </span>
                            :
                            <span style={{ display: 'flex', alignItems: 'baseline' }}>
                              <span>{__('to')}</span>
                              <Link to={baseLink + `/messages/${params.message_id}/entities`} style={{ color: '#007fff', fontWeight: 500, fontSize: '16px', paddingLeft: '5px' }}>{message.recipientsCount.toString() + ' ' + __('recipients')}</Link>
                            </span>
                        )
                        :
                        (
                          <span style={{ display: 'flex', alignItems: 'baseline' }}>
                            <span>{__('from')}</span>
                            <span style={{ fontSize: '16px', paddingLeft: '5px' }}>{message && message.entity && message.entity.fullname}</span>
                            {
                              (message.user && message.user.fullname && <Label basic size="tiny" className="uppercase" content={message.user.fullname} style={{ alignSelf: 'center', marginLeft: '8px', fontSize: '10px' }} />)
                            }
                          </span>
                        )
                    }
                  </div>
                  <div style={{ whiteSpace: 'nowrap', color: '#9696a0', fontSize: '12px' }}>
                    <span>{utils.simpleDate(!key ? message.modified : message.created, true, 'LLL', lang)}</span>
                    {
                      message.toEntity && message.toEntity.status > 0 && message.toEntity.status < 1 ?
                        <Icon color="black" name="check double" style={{ marginLeft: '8px', marginRight: '8px' }} />
                        :
                        ''
                    }
                    {
                      message.toEntity && message.toEntity.status > 1 ?
                        <Icon name="check double" style={{ marginRight: '8px', marginLeft: '8px', color: '#0080FF' }} />
                        :
                        ''
                    }
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment className="wordWrapped">
          {(!!message.subject || message.label) &&
            <div className="horizontallySpacedItems" style={{ marginBottom: '8px' }}>
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Header as="h3" style={{ fontSize: 'larger', fontWeight: 'bold' }}>
                  {message.subject !== null ?
                    (message.subject.length > 45 ?
                      message.subject.replace(/^(.{43}).+/, '$1...')
                      : message.subject)
                    : __('No Subject')}
                </Header>
              </div>
              <div style={{ display: 'flex' }}>
                {!!message.label && <Label data-name="label" size="mini" style={{ color: 'white', backgroundColor: `#${message.label.color}`, marginRight: '8px' }}>{message.label.title}</Label>}
              </div>
            </div>
          }
          {!!message.content && <div
            className="content clsp-content"
            dangerouslySetInnerHTML={{
              __html: message.content
            }}
          />}
          <Divider hidden />
          {
            message.fwMessage &&
            <Menu.Item
              data-action="open-forwarded-message"
              as={Link}
              to={baseLink + `/messages/${message.fwMessage.id}?forwarded=true`}
              onClick={store.appends.pop}
            >
              <Segment>
                <MessageItem message={filter(MessageItem.fragments.entityMessage, message.fwMessage)} />
              </Segment>
            </Menu.Item>
          }
          <MessageMedias images={message.images} videos={message.videos} audios={message.audios} />
          <MessageFiles files={message.files} />
          <MessageCommitmentReminds commitments={message.commitments} params={params} messageType={message.type} entity={{ disabled }} fromHistory />
          <MessageSurveyResults surveys={message.surveys} params={params} messageType={message.type} entity={{ disabled }} fromHistory />
          <MessageReportResults reports={message.reports} params={params} messageType={message.type} fromHistory />
          <MessageCharges charges={message.charges} params={params} messageType={message.type} entity={{ disabled }} fromHistory />
          <MessageFormResults forms={message.forms} params={params} messageType={message.type} entity={{ disabled }} fromHistory />
        </Segment>
      </Segment.Group>);
  }

  render() {
    const { data, params } = this.props;

    if ((data.loading && !data.node) || !data.node) { return <Modal loading />; }

    let { message } = data.node;

    if (params.organization_id) {
      message = data.node;
    }

    const { messageHistory, commitments, surveys, reports, forms } = message;
    const messagesForEditHistory = [{ ...this.props.message, commitments, surveys, reports, forms }].concat(messageHistory.map(m => ({ ...this.props.message, ...m })));

    return (
      <History
        id={'MessageEditHistory'}
        header={__('Edit History')}
        renderRow={this.renderRow}
        source={messagesForEditHistory}
      />
    );
  }
}
