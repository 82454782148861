import { observable, extendObservable, toJS } from 'mobx';

/**
 * @typedef {{
 *  app: {
 *    env: 'production' | 'stage' | 'local' | 'internal' | 'beta';
 *    host: string;
 *  },
 *  banners: string[],
 *  currentUser: {
 *    id: import('./utils').ID,
 *    isMaster: boolean,
 *  },
 *  currentEntity:{
 *    id: import('./utils').ID,
 *    hasUnreadDirectMessages: boolean,
 *  },
 *  entity: {
 *    type: import('./utils').EntityType,
 *    organization: {
 *      id: import('./utils').ID
 *      plan: import('./utils').OrganizationPlan,
 *    },
 *  },
 *  currentOrganization: {
 *    id: import('./utils').ID
 *  },
 *  snackbar: {
 *    active: boolean,
 *    message: string,
 *    success: boolean,
 *    dismissAfter: number
 *  }
 * }} Store
 */

// Default store structure
export const defaultStore = {
  app: {
    url: null,
    uploadUrl: null,
    name: 'ClassApp',
    title: '',
    description: '',
    host: '',
    color: '0080ff',
    locale: 'en',
    env: 'production',
    mixpanelToken: '',
  },
  admin: {
    organizations: null,
    users: null,
  },
  access_token: null,
  refresh_token: null,
  currentEntity: null,
  currentOrganization: null,
  currentUser: null,
  entity: null,
  group: {
    add: null,
    edit: null,
    delete: null,
    node: null,
    item: {
      name: null,
      id: null,
    },
    entities: null,
  },
  message: {
    node: null,
    add: null,
    replies: null,
    surveys: null,
    entities: null,
  },
  application: {
    node: null,
    edit: null,
  },
  report: {
    add: null,
    edit: null,
    delete: null,
  },
  auth: {
    email: '',
    phone: '',
    type: 'email',
    address: null,
    user: null,
  },
  prospect: {
    user: null,
    organization: null,
    entities: null,
    finished: null,
  },
  simulation: {
    user: null,
    organization: null,
    entity: null,
  },
  snackbar: {
    active: false,
    message: '',
    success: false,
  },
  dashboard: {
    channels: null,
    entities: null,
    pending: null,
    read: null,
    received: null,
    registered: null,
    sent: null,
  },
  apollo: {
    initialState: null,
  },
  firstLoad: true,
  loadingRequest: false,

  // Controls number of open modals
  modals: [],
  // Previous request, used to minimize request size
  previous: {
    params: {},
    components: [],
  },
  // Renders content at start of document
  prepends: [],
  // Renders content at end of document
  appends: [],
  // Stores the announcements that the user has already seen
  announcements: [],
  // Stores the banners that the user has already seen
  banners: [],

  // Page loading for history push
  pageLoading: false,
  history: [],
  currentLocation: null,
  isMenuExpanded: true,
  isCoverExpanded: true,
};

const observableDefaultStore = observable(defaultStore);

// Export an instance of our store ( function so that we don't re-use same object for every session )
export const createStore = (store = {}) =>
  process.env.IS_CLIENT
    ? extendObservable(observableDefaultStore, store)
    : toJS(observableDefaultStore);

const store = {
  defaultStore,
  createStore,
};

export default store;
