import gql from 'graphql-tag';
import { Query } from '../../DataSource/ApolloDataSource';

/**
 * @typedef {{
 *  entityId: string | number;
 * }} TVariables
 */

/**
 * @typedef {{
 *  data: {
 *    node: {
 *      webAvailablesTopBanner: {
 *        name: string;
 *        backgroundColor: string;
 *        backgroundSize: string | null;
 *        backgroundPosition: string | null;
 *        images: null | {
 *          left: string | null;
 *          right: string | null;
 *        };
 *        content: {
 *          plainText: string[];
 *          boldText: string | null;
 *          color: string;
 *          textAlign: string | null;
 *        };
 *        bannerActionButton: {
 *          text: string;
 *          color: string;
 *          backgroundColor: string;
 *          url: string | null;
 *        } | null;
 *        closeButton: {
 *          visible: boolean;
 *          color: string;
 *        } | null;
 *      } | null;
 *    };
 *  };
 * }} TResponse
 */

/**
 * @param {TVariables} variables
 * @returns {Promise<TResponse>}
 */
export async function getAvailableTopBannerRepository(variables) {
  const { entityId } = variables;

  const query = gql`
    query GetAvailableTopBanner($entityId: ID!) {
      node(id: $entityId) {
        ... on Entity {
          webAvailablesTopBanner {
            name
            backgroundColor
            backgroundSize
            backgroundPosition
            images {
              left
              right
            }
            content {
              plainText
              boldText
              color
              textAlign
            }
            actionButton {
              text
              color
              backgroundColor
              url
            }
            closeButton {
              color
              backgroundColor
            }
          }
        }
      }
    }
  `;

  const { data, errors } = await Query(query, { entityId });

  return {
    data,
    errors,
  };
}
