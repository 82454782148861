import { sendEvent } from '../../../lib/mixpanel';
import { TRACK_EVENT_NAME } from '../../../lib/mixpanel';
import { TRACK_EVENT_PAGE_NAME } from '../../../lib/mixpanel/pages';

export const sendGroupSavedEvent = ({
  buttonName = 'salvar_grupo',
  groupName = undefined,
  markerIds = [],
} = {}) => {
  const validMarkerIds = Array.isArray(markerIds) ? markerIds : [];

  sendEvent({
    name: TRACK_EVENT_NAME.BUTTON_CLICKED,
    pageName: TRACK_EVENT_PAGE_NAME.GROUPS,
    customProperties: {
      button_name: buttonName,
      group_name: groupName,
      quantity_markers: validMarkerIds.length,
      markers_type: validMarkerIds.join(', ') || 'Nenhum',
    },
  });
};
