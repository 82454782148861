import '@testing-library/jest-dom';

global.document.createRange = () => ({
  setStart: () => {},
  setEnd: () => {},
  commonAncestorContainer: {
    nodeName: 'BODY',
    ownerDocument: document,
  },
});

export const mockedMixpanel = {
  init: jest.fn(),
  track: jest.fn(),
  track_pageview: jest.fn(),
  people: {
    set: jest.fn(),
  },
  identify: jest.fn(),
};

jest.mock('mixpanel-browser', () => mockedMixpanel);

global.document.getSelection = jest.fn();
