import { getOrganizationsByNetworkIdRepository } from '../getOrganizationsByNetworkIdRepository';
import * as DataSource from '../../../DataSource/ApolloDataSource';

describe('getOrganizationsByNetworkIdRepository', () => {
  beforeEach(() => {
    jest.clearAllMocks();
  });

  it('should call query with the correct params', async () => {
    jest.spyOn(DataSource, 'Query').mockImplementation(() => ({
      data: {
        node: {
          organizations: {
            nodes: [
              {
                id: 1,
                fullname: 'name',
                logo: {
                  uri: 'uri',
                },
              },
            ],
          },
        },
      },
    }));

    const variables = {
      organizationId: 10,
      entityId: 10,
      search: undefined,
      limit: undefined,
      offset: undefined,
    };

    await getOrganizationsByNetworkIdRepository(variables);
    expect(DataSource.Query).toBeCalled();
  });

  it('should return data and errors', async () => {
    jest.spyOn(DataSource, 'Query').mockImplementation(() => ({
      data: null,
      error: ['error'],
    }));

    const variables = {
      organizationId: 10,
      entityId: 10,
      search: undefined,
      limit: undefined,
      offset: undefined,
    };

    const response = await getOrganizationsByNetworkIdRepository(variables);

    expect(response).toEqual({
      data: null,
      error: ['error'],
    });
  });
});
