import { hasInvalidSendLaterCommitment } from '../hasInvalidSendLaterCommitment';

describe('hasInvalidSendLaterCommitment', () => {
  it('should return true when sendAt is greater than or equal to dateLimit', () => {
    const dateLimit = new Date();
    dateLimit.setHours(23, 59, 59);
    const sendAt = new Date(dateLimit.getTime() + 1);
    const result = hasInvalidSendLaterCommitment({
      currentValue: {
        dateLimit,
        time: '23:59',
        noConfirm: false,
        date: new Date(),
      },
      sendAt,
    });
    expect(result).toBe(true);
  });

  it('should return false when sendAt is less than dateLimit', () => {
    const sendAt = new Date();
    const dateLimit = new Date();
    dateLimit.setHours(22, 59, 59);
    const result = hasInvalidSendLaterCommitment({
      currentValue: {
        dateLimit,
        time: '23:59',
        noConfirm: false,
        date: new Date(),
      },
      sendAt,
    });
    expect(result).toBe(false);
  });

  it('should return false when noConfirm is true', () => {
    const sendAt = new Date();
    const dateLimit = new Date();
    dateLimit.setHours(22, 59, 59);
    const result = hasInvalidSendLaterCommitment({
      currentValue: {
        dateLimit,
        time: '23:59',
        noConfirm: true,
        date: new Date(),
      },
      sendAt,
    });
    expect(result).toBe(false);
  });
});
