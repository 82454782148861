import { getAccessNetwork } from '../getAccessNetwork';

describe('getAccessNetwork', () => {
  it('should return access network with allow communication', () => {
    const markersState = {
      canAccessAllMarkers: true,
      excludeMarkers: [],
      markers: [],
      canAccessAllOrganizations: true,
      excludeOrganizations: [],
      organizations: [],
    };

    const allowComunication = true;

    const result = getAccessNetwork(markersState, allowComunication);

    expect(result).toEqual({
      communicationToAll: true,
    });
  });

  it('should return access network without allow communication', () => {
    const markersState = {
      canAccessAllMarkers: false,
      excludeMarkers: [],
      markers: ['2', '3'],
      isAllOrganizations: false,
      excludeOrganizations: [],
      organizations: ['4', '5'],
    };

    const allowComunication = false;

    const result = getAccessNetwork(markersState, allowComunication);

    expect(result).toEqual({
      markers: {
        ids: ['2', '3'],
        exceptionIds: [],
        all: false,
      },
      organizations: {
        ids: ['4', '5'],
        all: false,
        exceptionIds: [],
      },
    });
  });

  it('should return markers when canAccessAllMarkers is true', () => {
    const markersState = {
      canAccessAllMarkers: true,
      excludeMarkers: [],
      markers: ['2', '3'],
      isAllOrganizations: false,
      excludeOrganizations: [],
      organizations: ['4', '5'],
    };

    const allowComunication = false;

    const result = getAccessNetwork(markersState, allowComunication);

    expect(result).toEqual({
      markers: { all: true, exceptionIds: [], ids: [] },
      organizations: { all: false, exceptionIds: [], ids: ['4', '5'] },
    });
  });

  it('should return markers when canAccessAllOrganizations is true', () => {
    const markersState = {
      canAccessAllMarkers: false,
      canAccessAllOrganizations: true,
      excludeMarkers: [],
      markers: ['2', '3'],
      isAllOrganizations: false,
      excludeOrganizations: [],
      organizations: ['4', '5'],
    };

    const allowComunication = false;

    const result = getAccessNetwork(markersState, allowComunication);

    expect(result).toEqual({
      markers: { all: false, exceptionIds: [], ids: ['2', '3'] },
      organizations: { all: true, exceptionIds: [], ids: [] },
    });
  });
});
