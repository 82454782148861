import React from 'react';
import { inject, observer } from 'mobx-react';
import { Menu, Container, Button } from 'semantic-ui-react';
import { Link } from 'react-router';
import cookie from 'react-cookie';

import { __ } from '../i18n';
import * as analytics from '../lib/analytics';
import * as utils from '../utils';

/**
 * @typedef {{
 *  store: import('../store').Store;
 *  additionalClass?: string;
 *  shouldUseRemoteImages?: boolean;
 *  images: {
 *    left: string;
 *    right?: string;
 *  };
 *  content: {
 *    plainText: string[];
 *    boldText?: string;
 *    color: string;
 *    fontSize: string;
 *    textAlign: string;
 *  };
 *  actionButton?: {
 *    text: string;
 *    color: string;
 *    backgroundColor: string;
 *    url: string;
 *  };
 *  closeButton: {
 *    visible?: boolean;
 *    color: string;
 *  };
 *  name: string;
 *  backgroundColor: string;
 *  backgroundSize?: string | null;
 *  backgroundPosition?: string | null;
 *  onActionButtonClick?: () => void;
 *  actionButtonEventLabel?: string;
 * }} Props
 * @extends {React.Component<Props>}
 */
export class BaseBanner extends React.Component {
  constructor(/** @type {Props} */ props) {
    super(props);

    const { store, name } = props;

    this.state = {
      visible: props.closeButton?.visible
        ? store && store.banners && !store.banners.includes(`hasSeen${name}Banner`)
        : true,
    };
  }

  onDismiss = () => {
    const { store, name } = this.props;

    this.setState({ visible: false });

    const banners = store.banners;

    cookie.save(
      `hasSeen${name}Banner`,
      'yes',
      {
        path: '/',
        domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''),
        maxAge: 60 * 60 * 24 * 30
      }
    );
    banners.push(`hasSeen${name}Banner`);

    store.banners = banners;

    analytics.sendGoogleAnalyticsEvent({
      name: `${name}Banner`,
      category: `test-close-${store.app.env}`,
      label: store.currentUser.id,
    }, { store });
  }

  render() {
    const {
      store, images, content, actionButton, name, backgroundColor, onActionButtonClick, /* ,closeButton */
      additionalClass, closeButton, shouldUseRemoteImages, backgroundSize, backgroundPosition,
      actionButtonEventLabel
    } = this.props;
    //return null; // uncomment to remove banner

    const leftImage = `url(${utils.asset(images.left, 'banner', shouldUseRemoteImages ? store.app.env : undefined)})`;
    const rightImage = `url(${utils.asset(images.right || images.left, 'banner', shouldUseRemoteImages ? store.app.env : undefined)})`;
    return (
    <div style={{ display: this.state.visible ? 'block' : 'none' }}>
      <Menu
        borderless
        style={{
          backgroundImage: `${leftImage}, ${rightImage}`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: backgroundSize ?? 'auto 75%',
          backgroundColor: backgroundColor,
          backgroundPosition: backgroundPosition,
          padding: 5,
          zIndex: 3,
          border: 0,
        }}
        className={`conversation-news-top-banner ${additionalClass}`}
      >
        <Container
          className='conversation-news-top-banner-content'
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <p style={{
            color: content.color,
            fontSize: content.fontSize || 'inherit',
            // @ts-ignore
            textAlign: content.textAlign || 'inherit',
          }}>
            <span>
              {content.plainText?.map((text) => (
                <div>{text}</div>
              ))}
            </span>

            {!!content.boldText && (
              <>
                {' '}
                <b>
                  {content.boldText}
                </b>
              </>
            )}
          </p>

          {!!actionButton && (
            <Button
              as={Link}
              to={actionButton.url}
              rel="noopener noreferrer"
              target="_blank"
              content={actionButton.text}
              style={{
                backgroundColor: actionButton.backgroundColor,
                color: actionButton.color,
                borderRadius: '200px',
                marginRight: 0,
                marginLeft: '1rem',
                padding: '.625em 1em',
                fontWeight: 'bold',
              }}
              circular
              onClick={() => {
                analytics.sendGoogleAnalyticsEvent({
                  name: `${name}Banner`,
                  category: `test-open-${store.app.env}`,
                  label: actionButtonEventLabel ?? store.currentUser.id,
                }, { store });
                onActionButtonClick && onActionButtonClick()
              }}
            />
          )}

          {closeButton?.visible && (
            <Button
              style={{
                position: 'absolute',
                right: 10,
                padding: 0,
                color: closeButton.color,
                background: 'transparent'
              }}
              onClick={this.onDismiss}
            >
              <i
                className="icon times"
                style={{
                  margin: 0,
                  fontSize: '20px',
                  lineHeight: '20px',
                }}
              />
            </Button>
          )}
        </Container>
      </Menu>
    </div>);
  }
}

@inject('store') @observer
export default class Banner extends BaseBanner {
  /** @arg {Props} props */
  constructor(props) {
    super(props);
  }
};
