import React, { useEffect } from 'react';
import { RemoteMountedComponent } from '@classapp/sebastiao-orchestrator';
import PubSub from 'pubsub-js';
import { Loading } from '@classapp-tech/edna';

import { createUrlWithHash } from '../../../../utils';
import config from '../../../../../../config/server.config';

/**
 * @param {{
 *   env: 'local' | 'development' | 'stage' | 'internal' | 'production',
 *   folderName: string
 * }} props
 */
export function PersonalizedFilePreview({ env = 'local', folderName }) {
  useEffect(() => {
    const token = PubSub.subscribeOnce('MICROFRONTEND_READY', (_, mcfName) => {
      PubSub.publish(`${mcfName}_MICROFRONTEND_DATA`, {});
    });

    return () => {
      PubSub.unsubscribe(token);
    };
  }, []);

  useEffect(() => {
    PubSub.publish('SULLY_MICROFRONTEND_DATA', {
      folderName,
    });
  }, [folderName]);

  return (
    <RemoteMountedComponent
      url={createUrlWithHash(
        `${config.microfrontends.sully[env]}/remoteEntry.js`,
      )}
      name="sully"
      noStyle
      moduleName="./PersonalizedFile/Preview"
      fallbackComponent={null}
      loadingComponent={<Loading variation="basic" size={20} />}
      data-testid="remote-mounted-component"
    />
  );
}
