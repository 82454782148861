import { getAvailableTopBannerUseCase } from '../getAvailableTopBanner';
import * as repository from '../../../../Data/Repository/Banner/getAvailableTopBanner';

const mockRepository = jest.fn();

describe('getAvailableTopBannerUseCase', () => {
  beforeEach(() => {
    jest
      .spyOn(repository, 'getAvailableTopBannerRepository')
      .mockImplementation(mockRepository);
  });

  it('should call getAvailableTopBannerRepository with the correct entityId', async () => {
    const entityId = '1';
    mockRepository.mockResolvedValue({
      data: { node: { webAvailablesTopBanner: {} } },
    });
    await getAvailableTopBannerUseCase({ entityId });

    expect(repository.getAvailableTopBannerRepository).toHaveBeenCalledWith({
      entityId,
    });
  });

  it('should return the data from getAvailableTopBannerRepository', async () => {
    const entityId = '1';
    const data = { node: { webAvailablesTopBanner: {} } };
    mockRepository.mockResolvedValue({ data });
    const result = await getAvailableTopBannerUseCase({ entityId });

    expect(result).toEqual(data.node.webAvailablesTopBanner);
  });

  it('should throw an error if there are errors', async () => {
    const entityId = '1';
    const errors = [{ message: 'error' }];
    mockRepository.mockResolvedValue({ errors });

    await expect(getAvailableTopBannerUseCase({ entityId })).rejects.toThrow(
      errors[0].message,
    );
  });
});
