/**
 * @param {{
 *  currentValue: Object,
 *  sendAt: Date
 * }} params
 */
export function hasInvalidSendLaterCommitment({ currentValue, sendAt }) {
  let { time, dateLimit, noConfirm, date } = currentValue;
  let adjustedDate;
  let hour;
  if (time) {
    hour = time.split(':');
    adjustedDate = date.setHours(hour[0], hour[1]);
  } else {
    adjustedDate = date;
  }
  if (!noConfirm) {
    if (dateLimit.getDate() !== date.getDate() || !time) {
      dateLimit.setHours(23, 59, 59);
    } else {
      dateLimit.setHours(hour[0], hour[1]);
    }
    if (sendAt.getTime() >= dateLimit) return true;
  }
  return sendAt.getTime() >= adjustedDate;
}
