import mixpanel, { TRACK_EVENT_NAME } from '../../../lib/mixpanel';
import { TRACK_EVENT_IDENTIFIER } from '../../../lib/mixpanel/identifiers';
import { TRACK_EVENT_PAGE_NAME } from '../../../lib/mixpanel/pages';
import { TRACK_EVENT_SCOPE } from '../../../lib/mixpanel/scopes';

export function emitSendCommunicationEvent({ tags, values, labels }) {
  try {
    const allGroups = tags?.filter((tag) => !!tag);
    const groupNames = allGroups?.map((group) => group?.name);

    const hasCommitments = values.commitments.length > 0 && 'Compromisso';
    const hasReports = values.reports.length > 0 && 'Relatório';
    const hasSurveys = values.surveys.length > 0 && 'Enquete';
    const hasCharges = values.charges.length > 0 && 'Cobrança';
    const hasForms = values.forms.length > 0 && 'Formulário';

    const type = [hasCommitments, hasReports, hasSurveys, hasCharges, hasForms];

    const label =
      labels?.find((label) => label?.id === values?.labelId)?.title ||
      undefined;
    const sendAt = values?.sendAt;
    let type_scheduling = undefined;
    let advanced_settings = undefined;
    let advanced_settings_options = undefined;

    if (sendAt) {
      const currentDate = new Date();
      const schedulingDate = new Date(sendAt);

      const nextDay = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
      const nextWeek = new Date(
        currentDate.getTime() + 7 * 24 * 60 * 60 * 1000,
      );

      if (
        nextDay.getDate() === schedulingDate.getDate() &&
        nextDay.getMonth() === schedulingDate.getMonth() &&
        nextDay.getFullYear() === schedulingDate.getFullYear()
      ) {
        type_scheduling = 'Próximo dia';
      } else if (
        nextWeek.getDate() === schedulingDate.getDate() &&
        nextWeek.getMonth() === schedulingDate.getMonth() &&
        nextWeek.getFullYear() === schedulingDate.getFullYear()
      ) {
        type_scheduling = 'Próxima semana';
      } else {
        type_scheduling = 'Customizado';
      }
    }

    if (values?.pin || values?.noReply || values?.public) {
      advanced_settings = 'yes';
      advanced_settings_options = [
        values.pin ? 'Importante' : undefined,
        values.public ? 'Pública' : undefined,
        values.noReply ? 'Deasabilitar respostas' : undefined,
      ];
    } else {
      advanced_settings = 'no';
    }

    mixpanel.sendEvent({
      name: TRACK_EVENT_NAME.BUTTON_CLICKED,
      scope: TRACK_EVENT_SCOPE.COMMUNICATIONS,
      pageName: TRACK_EVENT_PAGE_NAME.INBOX,
      identifierName: TRACK_EVENT_IDENTIFIER.SEND_COMMUNICATION,
      customProperties: {
        button_name: 'enviar_comunicado',
        type: type,
        tags: groupNames,
        labels: label,
        scheduling: values?.sendAt ? 'yes' : 'no',
        type_scheduling,
        advanced_settings,
        advanced_settings_options,
        quantity_recipients_selected: values.recipients.length,
      },
    });
  } catch (err) {
    console.error('Error sending event to Mixpanel', err);

    return err;
  }
}
