import { deleteFolderRepository } from '../../../Data/Repository/PersonalizedFiles/DeleteFolder';

/**
 * @param {string} folderName
 * @returns {Promise<boolean>}
 */
export async function deleteFolderUseCase(folderName) {
  try {
    const { data } = await deleteFolderRepository(folderName);
    return data.deleteMessageFolder.success;
  } catch (error) {
    console.error(error);
    return false;
  }
}
