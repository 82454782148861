import { sendApprovalMessageEvent } from '../../../Message/Edit/utils/sendApprovalMessageEvent';
import { sendGoogleAnalyticsEvent } from '../../../../lib/analytics';
import * as analytics from '../../../../lib/analytics';

jest.mock('../../../../lib/analytics', () => ({
  sendGoogleAnalyticsEvent: jest.fn(),
}));

describe('sendApprovalMessageEvent util', () => {
  it('should call sendGoogleAnalyticsEvent with correct parameters', () => {
    const category = 'test-label';
    const store = {
      currentUser: { isMaster: false },
      app: { env: 'development' },
      currentOrganization: { id: 1 },
    };
    sendApprovalMessageEvent(category, store);
    expect(sendGoogleAnalyticsEvent).toBeCalledWith(
      {
        name: 'Approval Message',
        category,
        label: 'OrganizationID: 1',
      },
      { store },
    );
  });

  describe('sendApprovalMessageEvent', () => {
    const setup = () => ({
      sendGoogleAnalyticsEventSpy: jest.spyOn(
        analytics,
        'sendGoogleAnalyticsEvent',
      ),
    });

    afterEach(() => {
      jest.clearAllMocks();
    });

    it('should call sendGoogleAnalyticsEvent with correct parameters', () => {
      const { sendGoogleAnalyticsEventSpy } = setup();
      const category = 'test-label';
      const store = {
        currentUser: { isMaster: false },
        app: { env: 'development' },
        currentOrganization: { id: 1 },
      };
      sendApprovalMessageEvent(category, store);
      expect(sendGoogleAnalyticsEventSpy).toHaveBeenCalledWith(
        {
          name: 'Approval Message',
          category,
          label: 'OrganizationID: 1',
        },
        { store },
      );
    });
  });
});
