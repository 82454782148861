import { mergeRecipientsWithRoles } from '../mergeRecipientsWithRoles';

describe('mergeRecipientsWithRoles', () => {
  let props;

  beforeEach(() => {
    jest.clearAllMocks();

    props = {
      recipients: [
        {
          id: 1,
        },
      ],
      recipientsWithRoles: [
        {
          id: 1,
          roles: {
            nodes: [
              {
                id: 1,
                name: 'role1',
              }
            ]
          }
        },
      ],
    };
  });

  describe('when params is not provided', () => {
    it('should return empty array when groups is not provided', () => {
      props.recipients = null;
      const result = mergeRecipientsWithRoles(props.recipients, props.recipientsWithRoles);
      expect(result).toStrictEqual([]);
    });

    it('should return empty array when recipients is not provided', () => {
      props.recipients = null;
      const result = mergeRecipientsWithRoles(props.recipients, props.recipientsWithRoles);
      expect(result).toStrictEqual([]);
    });
  });

  describe('when props is provided ', () => {
    it('should return recipients list with roles', () => {
      const result = mergeRecipientsWithRoles(props.recipients, props.recipientsWithRoles);
      expect(result).toStrictEqual([{"id": 1, "roles": {"nodes": [{"id": 1, "name": "role1"}]}}]);
    });
  });
});
