/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { Menu, Icon, Divider, Segment, Label, Grid, Dropdown, Popup, Header, Button, Image, Container } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Modal from '../../../../components/Modal';
import Avatar from '../../../../components/Avatar';
import FeaturesModal from '../../../../components/FeaturesModal';

import BetaModal from '../../../../components/ui/Modal';
import BetaButton from '../../../../components/ui/Button';
import ElementModifier from '../../../../components/ElementModifier';
import Responsive from '../../../../components/Responsive';

import MessageMedias from '../../Medias';
import MessageItem from '../../Item';
import MessageFiles from '../../Files';
import MessageCommitmentReminds from '../../CommitmentReminds';
import MessageSurveyResults from '../../SurveyResults';
import MessageReplies from '../../Replies';
import MessageReportResults from '../../ReportResults';
import MessageCharges from '../../Charges';
import MessageLogs from '../../Logs';
import EntityItem from '../../../Entity/Item';
import MessageEdit from '../../Edit';
import MessageHistory from '../../History';
import MessageFormResults from '../../FormResults';
import MessageSignatures from '../../Signatures';
import ReviewStatusTooltip from '../../../../components/ui/ReviewStatusTooltip';
import onClose from '../../utils/onClose';
import MessageStatus from '../ui/MessageStatus';
import MessageLabel from '../ui/MessageLabel';

import * as utils from '../../../../utils';
import recoverMessage from '../../utils/recoverMessage';
import { backAndRefresh } from '../../utils/backAndRefresh';
import { __ } from '../../../../i18n';
import MoreOptionsMenu from '../ui/MoreOptionsMenu';
import { deleteMessage } from '../../utils/deleteMessage';

import { EntityNameHeader } from '../components/EntityNameHeader';
import { isMessageFromNetwork } from '../helpers/isMessageFromNetwork';
import { isNetwork } from '../../../../app/Domain/UseCases/Unleash/validations/IsNetwork';
import { hasCommunicationStatusFeedback } from '../../../../app/Domain/UseCases/Unleash/validations/HasCommunicationStatusFeedback';
import { HeaderEntity } from '../components/HeaderEntity';
import mixpanel, { TRACK_EVENT_NAME } from '../../../../lib/mixpanel';
import { TRACK_EVENT_IDENTIFIER } from '../../../../lib/mixpanel/identifiers';
import { TRACK_EVENT_SCOPE } from '../../../../lib/mixpanel/scopes';
import { TRACK_EVENT_PAGE_NAME } from '../../../../lib/mixpanel/pages';
import { PersonalizedFilePreview } from '../components/PersonalizedFilePreview';

const styles = {
  moreRecipients: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '32px',
    height: '32px',
    backgroundColor: '#9696a0',
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: '4em',
    border: 'solid 2px #ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

export class MessageModalComponent extends Responsive {
  mixpanelObj = {};
  constructor(props) {
    super(props);

    const { params, store } = props;
    const userHasEntity = params.entity_id && store.currentUser.entities &&
      (store.currentUser.entities.filter(entity => entity.code && entity.id && entity.id.toString() === params.entity_id).length > 0);

    this.state = {
      information: false,
      userHasEntity,
      writingReply: false,
    };
  }

  componentDidMount() {
    const { params, client } = this.props;
    const { userHasEntity } = this.state;

    if (!userHasEntity) return;

    client.query({
      query: gql`query MessageNodeEntityUpdateQuery (
        $entityId: ID!,
        $messageId: ID!,
        $updateStatus: RecipientStatus,
        $deleteNotifications: Boolean,
        $updateAccessed: Boolean
      ) {
          node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
            ... on Entity {
              id: dbId
              message (id: $messageId, deleteNotifications: $deleteNotifications , updateAccessed: $updateAccessed, updateStatus: $updateStatus ) {
                id: dbId
              }
            }
          }
        }`,
      fetchPolicy: 'network-only',
      variables: {
        entityId: params.entity_id,
        messageId: params.message_id,
        updateStatus: userHasEntity ? 'READ' : undefined,
        deleteNotifications: userHasEntity,
        updateAccessed: userHasEntity
      }
    });
  }

  shouldComponentUpdate(nextProps) {
    // fix for apollo bug, node disappears.. walle#3219
    if (this.props.data.node && !nextProps.data.node && isEqual(this.props.data.variables, nextProps.data.variables)) return false;

    return true;
  }

  onClose = () => {
    const { writingReply } = this.state;
    const { store } = this.props;

    const onClick = () => {
      this.closeAction();
      store.appends.pop();
      this.setState({ onLeave: true }, () => {
        store.snackbar = { active: true, message: __('Reply discarded'), success: true };
      });
    };

    onClose(writingReply, store, this.closeAction, onClick, this.props.object);
  };

  closeAction = async () => {
    const { store, router, data, client, location } = this.props;
    const { userHasEntity } = this.state;
    if (userHasEntity) {
      await data.updateQuery((previousResult) => {
        const nextResult = cloneDeep(previousResult);
        const forwarded = location.search.search('forwarded=true') != -1;

        if (nextResult.node.message.unread !== null && !forwarded) {
          nextResult.node.messages.totalCount -= 1;
        }

        nextResult.node.message.unread = null;
        nextResult.node.message.notifications.totalCount = 0;

        return nextResult;
      });

      client.query({
        query: gql`query MessageNodeUserNotificationsQuery {
          viewer {
            id: dbId
            notifications {
              totalCount
            }
          }
        }`,
        fetchPolicy: 'network-only'
      });
    }

    if (store.history.length > 1) {
      router.goBack();
    } else {
      const link = store.previous.params.entity_id ? `/entities/${store.previous.params.entity_id}/messages` :
        (store.previous.params.organization_id ? `/organizations/${store.previous.params.organization_id}/messages` : '/');
      router.push(link);
    }
  };

  /**
   * @param {'AS_UNREAD' | 'ARCHIVED' | 'UNARCHIVED'} to
   * @param {boolean} [hasUndoOption]
   */
  updateStatus = async (to, hasUndoOption = true) => {
    this.props.super.setLoading(true);
    if (hasUndoOption) this.setState({ loading: true });

    const { params, router, updateRecipient, location } = this.props;

    let snackbarMessage;
    let onClick;

    switch (to) {
      case 'UNARCHIVED':
        snackbarMessage = __('Message moved to inbox');
        onClick = () => this.updateStatus('ARCHIVED', false);
        break;
      case 'AS_UNREAD':
        snackbarMessage = __('Message marked as unread');
        onClick = () => this.updateStatus('READ', false);
        break;
      case 'ARCHIVED':
      default:
        snackbarMessage = __('Message archived');
        onClick = () => this.updateStatus('UNARCHIVED', false);
        break;
    }

    if (!hasUndoOption) snackbarMessage = __('Action successfully undone');

    await updateRecipient({
      variables: {
        updateRecipientMutation: {
          messageId: params.message_id,
          entityId: params.entity_id,
          status: to,
          legacyMode: false,
        }
      }
    });

    if (hasUndoOption) router.goBack();
    await this.props.super.refetch();

    utils.clearSnackbar(this.props.store);

    const snackbar = { active: true, message: snackbarMessage, success: true, action: hasUndoOption ? __('Undo') : __('Close'), dismissAfter: 10000 };
    if (hasUndoOption) snackbar.onClick = onClick;

    this.props.store.snackbar = snackbar;
    this.props.super.setLoading(false);
  };

  recoverMessage = async (hasUndoOption = true, messageStatus = null, isUndo = false) => {
    const { store, router, location } = this.props;
    recoverMessage(hasUndoOption, messageStatus, this.props, this.deleteMessage, backAndRefresh(store, router, location, true, isUndo));
  };

  deleteMessage = (hasUndoOption = true, isUndo = false) => {
    const { params, router, location} = this.props;
    deleteMessage(params, router, location, this.props, hasUndoOption, isUndo);
  };

  openPaywall() {
    this.props.store.appends.push(<FeaturesModal feature={'DELETE_MESSAGE'} />);
  }

  reload = async () => {
    const { data } = this.props;
    this.setState({ loading: true });
    await data.refetch();
    this.setState({ loading: false });
  };

  setMixpanelObj = (key, value) => {
    this.mixpanelObj[key] = value;
  };

  render() {
    const { data, params, store, location, router } = this.props;
    const { information, userHasEntity, loading } = this.state;
    const { app } = store;
    const hasMessageApproval = store.currentOrganization && store.currentOrganization.unleashStatus && store.currentOrganization.unleashStatus.approve_message
      && store.currentOrganization.plan === 'PREMIUM';

    if (!params.organization_id && ((data && data.node && data.node.message && data.node.message.deleted) || (data && data.error && data.error.message === 'GraphQL error: Can\'t access deleted messages'))) {
      return (<Modal
        size="small"
        closeOnRootNodeClick={false}
      >
        <Modal.Content style={{ backgroundColor: '#ffffff', borderRadius: '4px', boxShadow: '0 1px 0 0 #dededf' }}>
          <Container textAlign="center">
            <Image
              src={utils.asset('/images/deletedMessage.png')}
              style={{
                width: '300px',
                height: '300px',
                margin: 'auto',
                marginTop: '48px'
              }}
            />
            <div style={{ display: 'grid', marginTop: '24px' }}>
              <span style={{ fontSize: '18px', fontWeight: '500', color: '#212529' }}>{__('Unavailable Message')}</span>
              <span style={{ fontSize: '14px', color: '#868e96', marginBottom: '48px', marginTop: '8px' }}>{__('This message has been deleted and can no longer be viewed!')}</span>
            </div>
          </Container>
        </Modal.Content>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            data-action="goBack"
            style={{ color: '#666666', margin: '14px', backgroundColor: 'white', border: '.5px solid rgba(102, 102, 102, 0.3)' }}
            key={1}
            primary
            content={__('Close')}
            onClick={() => {
              this.setState({ onLeave: true }, () => {
                backAndRefresh(store, router, location);
              });
            }}
          />
        </div>
      </Modal>);
    } else if (data.error) {
      return (<Modal
        header={__('Error')}
        content={__('An error has occurred.')}
        actions={[<Button
          data-action="cancel"
          key={1}
          primary
          content={__('OK')}
          onClick={() => {
            this.setState({ onLeave: true }, () => {
              this.props.router.goBack();
            });
          }}
        />]}
      />);
    } else if ((data.loading && !data.node) || !data.node || loading) { return <Modal loading />; }

    const { type, organizationId, disabled } = data.node;
    let { message } = data.node;

    if (message?.type) {
      const isLoaded = !data.loading;

      if (isLoaded && !this.mixpanelObj?.sendedToMixpanel) {
        this.mixpanelObj.sendedToMixpanel = true;
        const hasMedias = message?.images?.nodes?.length > 0 || message?.videos?.nodes?.length > 0;

        const messageType = [];
        hasMedias && messageType.push('Mídia');
        message?.files?.nodes?.length > 0 && messageType.push('Arquivo');
        message?.audios?.nodes?.length > 0 && messageType.push('Áudio');
        message?.reports?.totalCount > 0 && messageType.push('Relatório');
        message?.commitments?.totalCount > 0 && messageType.push('Compromisso');
        message?.surveys?.totalCount > 0 && messageType.push('Enquete');
        message?.charges?.nodes?.length > 0 && messageType.push('Cobrança');
        message?.forms?.totalCount > 0 && messageType.push('Formulário');

        mixpanel.sendEvent({
          name: TRACK_EVENT_NAME.PAGE_VIEWED,
          identifierName: TRACK_EVENT_IDENTIFIER.VIEW_COMMUNICATION,
          scope: TRACK_EVENT_SCOPE.COMMUNICATIONS,
          pageName: TRACK_EVENT_PAGE_NAME.VIEW_COMMUNICATION,
          customProperties: {
            type: messageType
          },
        });
      }
    }

    if (params.organization_id) {
      message = data.node;
    }
    const baseLink = params.entity_id ?
      `/entities/${params.entity_id}` : `/organizations/${params.organization_id}`;
    let publicContent = '';

    if (message.public && message.content) {
      publicContent = message.content.replace(/<\/(p|h[1-6])>/gm, '\n').replace(/<br>/gm, '@').replace(/<(?:.|\n)*?>/gm, '');
    }

    const entityLink = e =>
    ((store.currentUser.isMaster || params.organization_id || (type === 'ADMIN' && organizationId === message.entity.organizationId)) && e.organizationId ?
      `/organizations/${e.organizationId}/${e.isChannel ? 'channels' : 'entities'}?search=${e.fullname}` : null);

    const isMessageOwner = !!message.user
      && !!message.entity
      && store.currentEntity.id === message.entity.id
      && store.currentUser.id === message.user.id;

    const currentEntityIsAdmin = store.currentEntity.type === 'ADMIN';
    const isMessageWriter = utils.isMessageWriter({ store });
    const isMessageApprover = utils.isMessageApprover({ store });


    const canModifyMessage = (
      store.currentEntity.type !== 'STUDENT'
      && message.entity.type !== 'STUDENT'
      && (message.type === 'SENT' || params.organization_id)
    );

    const messageReview = message && message?.review;
    const isWriterAndApproved = isMessageWriter && messageReview && messageReview?.reviewStatus === 'approved';

    const { features } = store.currentOrganization || {};

    let newParams = params;
    if (isMessageOwner) {
      newParams = { ...params, entity_id: store.currentEntity.id };
    }

    const canDeleteMessage = (
      (features && features.deleteMessages)
      && (isMessageOwner || canModifyMessage)
      && (moment(message.created) >= moment().subtract(3, 'days'))
      && !message.deleted
    ) || (store.currentUser.isMaster && !message.deleted);

    /* const moreOptions = [];

    if (!message.deleted && (isMessageOwner || canModifyMessage) && ((features && features.editMessages) || store.currentUser.isMaster)) {
      moreOptions.push(<Dropdown.Item
        data-action="update-message"
        data-params="edit"
        text={__('Edit message')}
        disabled={isWriterAndApproved}
        icon="edit"
        onClick={() => this.props.store.appends.push(<MessageEdit shouldSkipDraft message={message} params={newParams} location={location} />)}
      />);
    } else if ((!message.deleted && (isMessageOwner || canModifyMessage) && !utils.isEntityType(store, 'STUDENT'))) {
      moreOptions.push(<Dropdown.Item
        data-action="update-message"
        data-params="edit"
        onClick={() => this.openPaywall()}
      >
        <Icon name="edit" />
        <span>{__('Edit message')}</span>
        <Icon name="stars" style={{ marginLeft: '3px', color: '#A5A5A5' }} />
      </Dropdown.Item>);
    }

    if ((message.type === 'SENT' || params.organization_id || store.currentEntity.type === 'ADMIN') && message.logs.totalCount > 0) {
      moreOptions.push(<Dropdown.Item
        data-action="open-edit-history"
        data-params="history"
        text={__('View edit history')}
        icon="history"
        onClick={() => this.props.store.appends.push(<MessageHistory params={newParams} message={message} />)}
      />);
    }

    if (userHasEntity && !location.query.forwarded) {
      moreOptions.push(<Dropdown.Item
        data-action="update-status"
        data-params="unread"
        text={__('Mark as unread')}
        icon="envelope"
        onClick={() => this.updateStatus('AS_UNREAD')}
      />);

      if ((features && features.deleteMessages) || store.currentUser.isMaster) {
        moreOptions.push(<Dropdown.Item
          data-action="update-status"
          data-params="delete"
          text={__('Delete for me')}
          disabled={isWriterAndApproved}
          icon="trash"
          onClick={() => this.deleteMessage()}
        />);
      } else if (store.currentEntity.type !== 'STUDENT') {
        moreOptions.push(<Dropdown.Item
          data-action="update-status"
          data-params="delete"
          onClick={() => this.openPaywall()}
        >
          <Icon name="trash" />
          <span>{__('Delete for me')}</span>
          <Icon name="stars" style={{ marginLeft: '3px', color: '#A5A5A5' }} />
        </Dropdown.Item>);
      }
    }

    if (canDeleteMessage) {
      moreOptions.push(<Dropdown.Item
        as={Link}
        data-action="update-status"
        data-params="delete"
        disabled={isWriterAndApproved}
        text={__('Delete for everyone')}
        icon="trash alt"
        to={`${baseLink}/messages/${params.message_id}/delete?deleteForEveryone=true`}
      />);
    } else if ((!message.deleted && (isMessageOwner || canModifyMessage)) && store.currentEntity.type !== 'STUDENT' && (moment(message.created) >= moment().subtract(1, 'hour'))) {
      moreOptions.push(<Dropdown.Item
        data-action="update-message"
        data-params="delete"
        onClick={() => this.openPaywall()}
        disabled={isWriterAndApproved}
      >
        <Icon name="trash alt" />
        <span>{__('Delete for everyone')}</span>
        <Icon name="stars" style={{ marginLeft: '3px', color: '#A5A5A5' }} />
      </Dropdown.Item>);
    }

    if (userHasEntity && !location.query.forwarded) {
      moreOptions.push(<Dropdown.Item
        data-action="update-status"
        data-params="archive"
        text={(message.status === 4) ?
          __('Unarchive') : __('Archive')}
        icon="archive"
        onClick={() => this.updateStatus(message.status !== 4 ? 'ARCHIVED' : 'UNARCHIVED')}
      />);
    } */

    const isDeletedForMe = location.query.deletedForMe;

    return (
      <Modal
        id="MessageNode"
        open
        size="small"
        closeIcon={'times close inside'}
        onClose={() => this.onClose()}
        closeOnRootNodeClick={false}
        key={message.id}
      >
        {isNetwork(store.currentOrganization) && hasCommunicationStatusFeedback(store.currentOrganization) && message.statusSend !== 'DONE' && message.statusSend !== null ? (
          <Modal.Header style={{ padding: '1.5em' }} />
        ) : (
        <Modal.Header style={{ padding: '0.5em' }}>
          <Menu compact secondary>
            {
              (store.currentUser.isMaster || message.type === 'SENT' || params.organization_id || (type === 'ADMIN' && organizationId === message.entity.organizationId)) && !isDeletedForMe &&
              (
                this.isMobile() ? <Menu.Item as={Link} to={baseLink + `/messages/${params.message_id}/entities`} icon><Icon data-name="status" name="info circle" /></Menu.Item>
                  : <Popup
                    trigger={
                      <Menu.Item as={Link} to={baseLink + `/messages/${params.message_id}/entities`} icon><Icon data-name="status" name="info circle" /></Menu.Item>
                    }
                    content={__('Status')}
                  />
              )
            }
            {
              (organizationId && type !== 'STUDENT' && !disabled && store.currentEntity.id == params.entity_id) && !isDeletedForMe &&
              (
                !message.fwMessage ?
                  <Popup
                    trigger={<Menu.Item
                      as={Link}
                      to={`/entities/${params.entity_id || store.currentEntity.id}/messages/add?fwMessageId=${params.message_id}`}
                      icon
                    >
                      <Icon data-name="forward" name="share" />
                    </Menu.Item>}
                    content={__('Forward')}
                  />
                  :
                  <Popup
                    trigger={<Menu.Item
                      icon
                      disabled
                    >
                      <Icon data-name="forward" name="share" />
                    </Menu.Item>}
                    content={<span style={{ display: 'inline-block' }}>
                      <Icon name="exclamation triangle" color="red" />
                      {__('You can\'t forward an forwarded message!')}
                    </span>}
                  />
              )
            }
            {
              message.public && !isDeletedForMe ?
                <Popup
                  trigger={<Dropdown data-action="open-options" data-params="share" icon="share alt" className="link item icon" >
                    <Dropdown.Menu>
                      <Dropdown.Item
                        data-name="facebook"
                        as="a"
                        target="_blank"
                        rel="noreferrer noopener"
                        href={`https://www.facebook.com/dialog/share?app_id=192130234603738&quote=${message.summary}&display=page&href=https://${app.host}/messages/${params.message_id}/&redirect_uri=https://${app.host}/`}
                        text={__('Facebook')}
                        icon="facebook f brand"
                      />
                      <Dropdown.Item
                        data-name="twitter"
                        as="a"
                        target="_blank"
                        rel="noreferrer noopener"
                        href={`https://twitter.com/share?url=https://${app.host}/messages/${params.message_id}/&text=${message.summary}`}
                        text={__('Twitter')}
                        icon="twitter brand"
                      />
                      <Dropdown.Item
                        data-name="email"
                        as="a"
                        target="_blank"
                        rel="noreferrer noopener"
                        href={`mailto:?body=${publicContent}%0D%0A%0D%0Ahttps://${app.host}/messages/${params.message_id}/&subject=${message.subject}`}
                        text={__('Email')}
                        icon="envelope"
                      />
                      <CopyToClipboard text={`${app.env === 'production' ? 'https' : 'http'}://${app.host}/messages/${params.message_id}`}>
                        <Dropdown.Item
                          data-name="link"
                          as="a"
                          target="_blank"
                          rel="noreferrer noopener"
                          onClick={() => {
                            this.props.store.snackbar = {
                              active: true,
                              message: __('Message link copied'),
                              success: true
                            };
                          }}
                          text={__('Copy sharable link')}
                          icon="link"
                        />
                      </CopyToClipboard>
                    </Dropdown.Menu>
                  </Dropdown>}
                  content={__('Share')}
                />
                :
                null
            }
            {
              !isDeletedForMe && <MoreOptionsMenu
                isMobile={this.isMobile()}
                message={message}
                isMessageOwner={isMessageOwner}
                canModifyMessage={canModifyMessage}
                hasEditMessagesFeature={features && features.editMessages}
                hasDeleteMessagesFeature={features && features.deleteMessages}
                isMaster={store.currentUser.isMaster}
                isWriterAndApproved={isWriterAndApproved}
                store={store}
                newParams={newParams}
                location={location}
                params={params}
                userHasEntity={userHasEntity}
                canDeleteMessage={canDeleteMessage}
                baseLink={baseLink}
                setOpenPaywall={() => this.openPaywall()}
                setUpdateStatus={(status) => this.updateStatus(status)}
                setDeleteMessage={() => this.deleteMessage()}
              />
            }
          </Menu>
        </Modal.Header>
        )}
        <Modal.Content style={{ paddingBottom: 24 }}>
          <Segment.Group style={{ margin: 0 }}>
            <Segment secondary>
              <Grid>
                {isNetwork(store.currentOrganization) && hasCommunicationStatusFeedback(store.currentOrganization) && message.statusSend !== 'DONE' && message.statusSend !== null ? (
                  <HeaderEntity message={message} client={this.props.client} messageId={params.message_id} onClose={this.onClose} store={store}/>
                ) : (
                <Grid.Row columns={2}>
                  <Grid.Column width={13} style={{ display: 'flex', alignItems: 'center' }}>
                    {
                      message.type === 'SENT' && !params.organization_id ?
                        (
                          message.recipientsCount === 1 ?
                            <span className="thirteen wide column" style={{ display: 'flex' }}>
                              {message.toEntity && <EntityItem to={entityLink(message.toEntity)} entity={filter(EntityItem.fragments.entity, message.toEntity)} fromMessage />}
                            </span>
                            :
                            <div style={{ width: '40px', height: '40px', position: 'relative', marginBottom: 3 }}>
                              <Avatar
                                avatar
                                src={message.toEntity && message.toEntity.picture && message.toEntity.picture.uri}
                                alt={message.toEntity && message.toEntity.fullname}
                                style={message.deleted ? (message.recipientsCount > 1 ? { width: '32px', height: '32px', opacity: 0.65 } : { width: '40px', height: '40px', opacity: 0.65 }) : (message.recipientsCount > 1 ? { width: '32px', height: '32px' } : { width: '40px', height: '40px' })}
                              />
                              {
                                message.recipientsCount > 1 ?
                                  <div style={styles.moreRecipients}>
                                    +{message.recipientsCount - 1}
                                  </div>
                                  :
                                  null
                              }
                            </div>
                        )
                        :
                        (
                          <EntityItem
                            to={(store.currentEntity.type === 'ADMIN' || (store.currentEntity.organization && store.currentEntity.organization.permissions && (store.currentEntity.organization.permissions.hasAdmin || (store.currentEntity.organization.permissions.entityScopes.includes('READ_ENTITY') && !!params.organization_id)))) && entityLink(message.entity)}
                            entity={filter(EntityItem.fragments.entity, message.entity)}
                            indicator="user"
                            fromMessage
                          />
                        )
                    }
                    <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                      <div>
                        {
                          message.type === 'SENT' && !params.organization_id ?
                            (
                              message.recipientsCount === 1 ?
                                <span style={{ display: 'flex', alignItems: 'baseline' }}>
                                  <span>{__('to')}</span>
                                  {
                                    message.toEntity &&
                                    (
                                      entityLink(message.toEntity) ?
                                        <Link to={entityLink(message.toEntity)} style={{ color: '#007fff', fontWeight: 500, fontSize: '16px', paddingLeft: '5px' }}>{message.toEntity.fullname}</Link>
                                        :
                                        <span style={{ fontWeight: 500, fontSize: '16px', paddingLeft: '5px' }}>{message.toEntity.fullname}</span>
                                    )
                                  }
                                </span>
                                :
                                <span style={{ display: 'flex', alignItems: 'baseline' }}>
                                  <span>{__('to')}</span>
                                  <Link to={baseLink + `/messages/${params.message_id}/entities`} style={{ color: '#007fff', fontWeight: 500, fontSize: '16px', paddingLeft: '5px' }}>{message.recipientsCount.toString() + ' ' + __('recipients')}</Link>
                                </span>
                            )
                            :
                            (
                              <span style={{ display: 'flex', alignItems: 'baseline' }}>
                                <EntityNameHeader
                                  entity={message && message.entity}
                                  store={store}
                                  params={params}
                                  entityType={type}
                                  organizationId={organizationId}
                                  message={message}
                                  isMessageFromNetwork={isMessageFromNetwork({
                                    organization: store.currentOrganization,
                                    message
                                  })}
                                />
                              </span>
                            )
                        }
                      </div>
                      <div style={{ whiteSpace: 'nowrap', color: '#9696a0', fontSize: '12px' }}>
                        <span>{utils.simpleDate(message.modified, true, 'LLL', app.locale)}</span>
                        {!this.isMobile() &&
                          <MessageStatus message={message} isMobile={this.isMobile()} />}
                      </div>

                      {this.isMobile() && <div style={{ whiteSpace: 'nowrap', color: '#9696a0', fontSize: '12px' }}>
                        <MessageStatus message={message} isMobile={this.isMobile()} />
                      </div>}
                    </div>
                  </Grid.Column>
                  <Grid.Column data-action="toggle-information" textAlign="right" width={3} onClick={() => this.setState({ information: !information })}>
                    {
                      (message.tags.nodes.length > 0) ||
                        (message.entity.type === 'STUDENT' && message.entity.roles.nodes.length > 0) ?
                        <Icon data-name="tag-detail" name={information ? 'chevron up' : 'chevron down'} size="small" />
                        :
                        null
                    }
                  </Grid.Column>
                </Grid.Row>
                )}
                {
                  information && message.tags.nodes.length > 0 &&
                  <Grid.Row>
                    <Grid.Column>
                      <Icon name="tag" />
                      {message.tags.nodes.map(tag =>
                        <Label data-name="label" data-value={tag.name} basic key={tag.id}>
                          {tag.name}
                        </Label>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                }
                {
                  information && message.entity.type === 'STUDENT' && message.entity.roles.nodes.length > 0 &&
                  <Grid.Row>
                    <Grid.Column>
                      <Icon name="graduation cap" />
                      {message.entity.roles.nodes.map((role, key) =>
                        <Label basic key={key}>
                          {(role.name.length > 36) ? (role.name.substring(0, 36) + '...') : role.name}
                        </Label>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                }
              </Grid>
              {
                isDeletedForMe &&
                <Grid style={{ background: '#00000029' }}>
                  <Grid.Row>
                    <Grid.Column>
                      {userHasEntity ?
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <span>
                            <Icon name="trash" style={{ margin: '0 0.25rem 0 0', fontWeight: '300' }} />
                            {__('Message deleted for you on %s', utils.simpleDate(message.statuses.nodes[0].created, false, 'lll', app.locale).toLowerCase())}
                          </span>
                          <button
                            style={{ border: 'none', background: 'transparent', color: '#084FFF', fontWeight: 'bold', cursor: 'pointer' }}
                            onClick={() => this.recoverMessage()}
                          >{__('Recover')}</button>
                        </div>
                        : message.statuses.nodes.length === 1 ?
                          <span>
                            <Icon name="trash" style={{ margin: '0 0.25rem 0 0', fontWeight: '300' }} />
                            {utils.parseFormattedTranslation(
                              {
                                text: __(
                                  'Message deleted for **%s** on %s',
                                  message.statuses.nodes[0].user.fullname,
                                  utils.simpleDate(message.statuses.nodes[0].created, false, 'lll', app.locale).toLowerCase()
                                ),
                                Wrapper: ({ children }) =>
                                  <span style={{ fontWeight: 'bold' }}>{utils.renderLongText(children, 13)}</span>
                              }
                            )}
                          </span>
                          : [
                            <Icon name="trash" style={{ margin: '0 0.25rem 0 0', fontWeight: '300' }} />,
                            utils.parseFormattedTranslation(
                              {
                                text: __('Message deleted for **%s users**', message.statuses.nodes.length),
                                Wrapper: ({ children }) =>
                                  <Popup
                                    basic
                                    className="deletedForUserList"
                                    trigger={
                                      <span
                                        style={{
                                          fontWeight: 'bold',
                                          textDecoration: 'underline',
                                          cursor: 'pointer'
                                        }}
                                        className="lighten-on-hover"
                                      >
                                        {children}
                                      </span>
                                    }
                                    on="click"
                                    header={
                                      <div className="userListHeader">
                                        {__('Deleted for')}
                                      </div>
                                    }
                                    content={
                                      <ul className="userList">
                                        {message.statuses.nodes.map(({ created, user, id }) => (
                                          <li key={id}>
                                            <span>{utils.renderLongText(user.fullname, 13)}</span> <time>{__('on %s', utils.simpleDate(created, false, 'lll', app.locale).toLowerCase())} </time>
                                          </li>
                                        ))}
                                      </ul>
                                    }
                                    position="bottom left"
                                    wide="very"
                                  />
                              }
                            )]
                      }
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              }
            </Segment>

            {
              hasMessageApproval && messageReview && ((isMessageWriter && isMessageOwner) || isMessageApprover || currentEntityIsAdmin) &&
              <ReviewStatusTooltip
                status={messageReview?.reviewStatus === 'approved' ? 'approved' : 'inReview'}
                entityFullname={messageReview?.user && messageReview?.user?.fullname}
                date={utils.simpleDate(messageReview?.reviewIn, true, 'DD MMM, HH:mm', app.locale)}
              />
            }

            <Segment className="wordWrapped">
              {
                (!!message.subject || !!message.label) &&
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {message.subject ? <Header>{message.subject}</Header> : <div />}
                  {
                    /* !!message.label && <div style={{ marginLeft: 8 }}>
                      <Label data-name="label" size="tiny" style={{ color: 'white', backgroundColor: `#${message.label.color}`, wordBreak: 'initial' }}>{message.label.title}</Label>
                    </div> */
                  }
                  <MessageLabel label={message.label} />
                </div>
              }
              {!!message.rendered && <div
                className="content clsp-content ql-editor"
                dangerouslySetInnerHTML={{
                  __html: message.rendered
                }}
              />}
              <Divider hidden />
              {
                message.fwMessage &&
                <div style={{ marginBottom: '1em' }}>
                  <Menu.Item data-action="open-forwarded-message" as={Link} to={baseLink + `/messages/${message.fwMessage.id}?forwarded=true`}>
                    <Segment>
                      <MessageItem message={filter(MessageItem.fragments.entityMessage, message.fwMessage)} />
                    </Segment>
                  </Menu.Item>
                </div>
              }
              {message.folderName && (
                <PersonalizedFilePreview env={store?.app?.env} folderName={message.folderName} />
              )}
              <MessageMedias images={message.images} videos={message.videos} audios={message.audios} />
              <MessageFiles files={message.files} />
              <MessageCommitmentReminds params={params} messageType={message.type} entity={{ disabled }} />
              <MessageSurveyResults params={params} messageType={message.type} entity={{ disabled }} />
              <MessageReportResults params={params} messageType={message.type} />
              <MessageCharges charges={message.charges} params={params} reload={() => this.reload()} messageType={message.type} entity={{ disabled }} />
              <MessageFormResults params={params} messageType={message.type} entity={{ disabled }} />
              <MessageSignatures signatures={message.signatures} params={params} messageType={message.type} entity={{ disabled }} updateQuery={data.refetch} />
            </Segment>
          </Segment.Group>
          <MessageLogs logs={message.logs} />
          {location.query.type !== 'messagesToApprove' &&
            <MessageReplies params={{ entity_id: message.entity.id, ...this.props.params }} forwarded={location.query.forwarded} location={location} setWritingReply={value => (this.state.writingReply !== value ? this.setState({ writingReply: value }) : null)} />
          }
          {this.props.children && React.cloneElement(this.props.children,
            {
              super: this.props.super
            }
          )}
          {!this.isMobile() && <ElementModifier element={document.body} newClassName="paddingOnModalDimmer" />}
        </Modal.Content>
      </Modal>
    );
  }
}

@inject('store', 'client')
@graphql(gql`query MessageNodeEntityQuery (
  $entityId: ID!,
  $messageId: ID!
) {
    node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        type
        organizationId
        message (id: $messageId, filterTagsByEntity: true) {
          id: dbId
          type
          subject
          content
          rendered
          summary
          public
          created
          modified
          statusSend
          recipientsCount
          status
          unread
          deleted
          notifications {
            totalCount
          }
          fwMessage {
            id: dbId
            ...MessageItemEntity
          }
          label {
            id: dbId
            title
            color
          }
          entity {
            id: dbId
            type
            isChannel
            roles (limit: 40) {
              nodes {
                name
              }
            }
            organizationId
            ...EntityItemEntity
          }
          toEntity {
            isChannel
            status
            organizationId
            ...EntityItemEntity
          }
          user {
            ...EntityItemUser
          }
          tags (limit: 40) {
            nodes {
              name
              id: dbId
            }
          }
          review {
            id
            user {
              id: dbId
              fullname
            }
            reviewStatus
            reviewIn
          }
          ...MessageMedias
          ...MessageFiles
          ...MessageLogs
          ...MessageCharges
          ...MessageSignatures
          forms {
            totalCount
          }
          reports {
            totalCount
          }
          surveys {
            totalCount
          }
          commitments {
            totalCount
          }
        }
        messages (folder: UNREAD) {
          totalCount
        }
      }
    }
  }
${EntityItem.fragments.entity}
${EntityItem.fragments.user}
${MessageItem.fragments.entityMessage}
${MessageMedias.fragments.medias}
${MessageFiles.fragments.files}
${MessageLogs.fragments.logs}
${MessageCharges.fragments.charges}
${MessageSignatures.fragments.signatures}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      entityId: ownProps.params.entity_id,
      messageId: ownProps.params.message_id
    }
  }),
  skip: ownProps => !ownProps.params.entity_id
    || ownProps.location.search.search('forwarded=true') > -1
    || ownProps.location.search.search('deletedForMe=true') > -1
})
@graphql(gql`query MessageNodeEntityQuery (
    $entityId: ID!,
    $messageId: ID!
  ) {
      node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
        ... on Entity {
          id: dbId
          type
          organizationId
          message (id: $messageId ) {
            id: dbId
            type
            subject
            content
            rendered
            summary
            public
            created
            modified
            statusSend
            recipientsCount
            status
            unread
            deleted
            notifications {
              totalCount
            }
            fwMessage {
              id: dbId
              ...MessageItemEntity
            }
            label {
              id: dbId
              title
              color
            }
            entity {
              type
              isChannel
              roles (limit: 40) {
                nodes {
                  name
                }
              }
              organizationId
              ...EntityItemEntity
            }
            toEntity {
              status
              isChannel
              organizationId
              ...EntityItemEntity
            }
            user {
              ...EntityItemUser
            }
            tags (limit: 40) {
              nodes {
                name
                id: dbId
              }
            }
            ...MessageMedias
            ...MessageFiles
            ...MessageLogs
            ...MessageCharges
            ...MessageSignatures
          }
        }
      }
    }
  ${EntityItem.fragments.entity}
  ${EntityItem.fragments.user}
  ${MessageItem.fragments.entityMessage}
  ${MessageMedias.fragments.medias}
  ${MessageFiles.fragments.files}
  ${MessageCharges.fragments.charges}
  ${MessageLogs.fragments.logs}
  ${MessageSignatures.fragments.signatures}
  `, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      entityId: ownProps.params.entity_id,
      messageId: ownProps.params.message_id
    }
  }),
  skip: ownProps => !ownProps.params.entity_id
    || ownProps.location.search.search('forwarded=true') == -1
    || ownProps.location.search.search('deletedForMe=true') > -1
})
@graphql(gql`query MessageNodeEntityQuery (
  $entityId: ID!,
  $messageId: ID!
) {
    node(id: $entityId) @connection(key: "Entity", filter: ["entityId"]) {
      ... on Entity {
        id: dbId
        type
        organizationId
        message (id: $messageId, filterTagsByEntity: true) {
          id: dbId
          type
          subject
          content
          rendered
          summary
          public
          created
          modified
          statusSend
          recipientsCount
          status
          unread
          deleted
          statuses(status: DELETED) {
            nodes {
              id: dbId
              entityId
              user {
                id: dbId
                fullname
              }
              created
            }
          }
          notifications {
            totalCount
          }
          fwMessage {
            id: dbId
            ...MessageItemEntity
          }
          label {
            id: dbId
            title
            color
          }
          entity {
            id: dbId
            type
            isChannel
            roles (limit: 40) {
              nodes {
                name
              }
            }
            organizationId
            ...EntityItemEntity
          }
          toEntity {
            isChannel
            status
            organizationId
            ...EntityItemEntity
          }
          user {
            ...EntityItemUser
          }
          tags (limit: 40) {
            nodes {
              name
              id: dbId
            }
          }
          ...MessageMedias
          ...MessageFiles
          ...MessageLogs
          ...MessageCharges
          ...MessageSignatures
        }
        messages (folder: UNREAD) {
          totalCount
        }
      }
    }
  }
${EntityItem.fragments.entity}
${EntityItem.fragments.user}
${MessageItem.fragments.entityMessage}
${MessageMedias.fragments.medias}
${MessageFiles.fragments.files}
${MessageLogs.fragments.logs}
${MessageCharges.fragments.charges}
${MessageSignatures.fragments.signatures}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      entityId: ownProps.params.entity_id,
      messageId: ownProps.params.message_id
    }
  }),
  skip: ownProps => !ownProps.params.entity_id
    || ownProps.location.search.search('forwarded=true') > -1
    || ownProps.location.search.search('deletedForMe=true') === -1
})
@graphql(gql`query MessageNodeOrganizationQuery (
  $messageId: ID!,
  $entityId: ID!,
) {
    node(id: $messageId) @connection(key: "Message", filter: ["messageId"]) {
      ... on Message {
        id: dbId
        type
        subject
        content
        rendered
        summary
        public
        created
        modified
        statusSend
        recipientsCount
        status
        deleted
        fwMessage {
          id: dbId
          ...MessageItemOrganization
        }
        label {
          id: dbId
          title
          color
        }
        entity {
          id: dbId
          type
          isChannel
          roles (limit: 40) {
            nodes {
              name
            }
          }
          organizationId
          ...EntityItemEntity
        }
        toEntity {
          isChannel
          status
          organizationId
          ...EntityItemEntity
        }
        user {
          ...EntityItemUser
        }
        tags (limit: 40) {
          nodes {
            name
            id: dbId
          }
        }
        ...MessageMedias
        ...MessageFiles
        ...MessageLogs
        ...MessageCharges
        ...MessageSignatures
      }
    }
  }
${EntityItem.fragments.entity}
${EntityItem.fragments.user}
${MessageItem.fragments.organizationMessage}
${MessageMedias.fragments.medias}
${MessageFiles.fragments.files}
${MessageLogs.fragments.logs}
${MessageCharges.fragments.charges}
${MessageSignatures.fragments.signatures}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      messageId: ownProps.params.message_id,
      entityId: ownProps.store.currentEntity.id
    }
  }),
  skip: ownProps => !ownProps.params.organization_id
})
@graphql(gql`mutation updateRecipient($updateRecipientMutation: UpdateRecipientInput!) {
  updateRecipient(input: $updateRecipientMutation) {
    clientMutationId
  }
}`, {
  name: 'updateRecipient',
  options: {
    refetchQueries: ['EntityMessagesQuery', 'AppQuery'],
    awaitRefetchQueries: true
  }
})
@graphql(gql`mutation deleteForMe($CreateMessageStatusMutation:CreateMessageStatusInput!){
  createMessageStatus(input:$CreateMessageStatusMutation){
    clientMutationId
    messageStatus {
      id: dbId
    }
  }
}`, {
  name: 'deleteForMe',
  options: {
    refetchQueries: ['OrganizationMessagesQuery', 'EntityMessagesQuery'],
    awaitRefetchQueries: true
  }
})
@graphql(gql`mutation recoverForMe($DeleteMessageStatusMutation:DeleteMessageStatusInput!){
  deleteMessageStatus(input:$DeleteMessageStatusMutation){
    clientMutationId
  }
}`, {
  name: 'recoverForMe',
  options: {
    refetchQueries: ['EntityMessagesQuery'],
    awaitRefetchQueries: true
  }
})
@observer
export default class MessageModal extends MessageModalComponent {
  constructor(props) {
    super(props);
  }
}
