/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { render } from '../../../../../newRender';
import { PersonalizedFilePreview } from '../PersonalizedFilePreview';
import PubSub from 'pubsub-js';

jest.mock('pubsub-js', () => ({
  subscribeOnce: jest.fn(),
  publish: jest.fn(),
  unsubscribe: jest.fn(),
}));

jest.mock('../../../../../utils', () => ({
  createUrlWithHash: jest.fn(() => 'https://example.com/remoteEntry.js'),
}));

jest.mock('../../../../../../../config/server.config', () => ({
  microfrontends: {
    sully: {
      local: 'https://example.com/sully/local',
      development: 'https://example.com/sully/development',
      stage: 'https://example.com/sully/stage',
      internal: 'https://example.com/sully/internal',
      production: 'https://example.com/sully/production',
    },
  },
}));

describe('PersonalizedFilePreview', () => {
  it('should call PubSub.subscribeOnce when component mounts', () => {
    render(<PersonalizedFilePreview env="local" folderName="teste" />);
    expect(PubSub.subscribeOnce).toHaveBeenCalledTimes(1);
    expect(PubSub.subscribeOnce).toHaveBeenCalledWith(
      'MICROFRONTEND_READY',
      expect.any(Function),
    );
  });

  it('should call PubSub.publish when component mounts', () => {
    render(<PersonalizedFilePreview env="local" folderName="teste" />);
    expect(PubSub.publish).toHaveBeenCalledTimes(2);
    expect(PubSub.publish).toHaveBeenCalledWith('SULLY_MICROFRONTEND_DATA', {
      folderName: 'teste',
    });
  });

  it('should call PubSub.unsubscribe when component unmounts', () => {
    const { unmount } = render(
      <PersonalizedFilePreview env="local" folderName="teste" />,
    );
    unmount();
    expect(PubSub.unsubscribe).toHaveBeenCalledTimes(3);
  });
});
