/*eslint prefer-const: off*/
/*eslint-env es6*/
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Icon, Segment, Grid, Input, Popup, Menu, Dropdown, Container, Image, Label, Message } from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { uniqueId, cloneDeep, isEqual, sum } from 'lodash';
import 'react-html5video/dist/styles.css';
import { Button as DSButton } from '@classapp-tech/edna';
import { ThumbsUpLight} from '@classapp-tech/edna-icons';

import BetaButton from '../../../components/ui/Button';
import Form from '../../../components/ui/Form';
import Modal from '../../../components/Modal';
import BetaModal from '../../../components/ui/Modal';
import QuillEditor from '../../../components/QuillEditor';
import Avatar from '../../../components/Avatar';
import DatePicker from '../../../components/DatePicker';
import ColoredCheckbox from '../../../components/ColoredCheckbox';
import SingleAutosuggest from '../../../components/SingleAutosuggest';
import AudioPlayer from '../../../components/AudioPlayer';
import AttachmentPreview from '../../../components/AttachmentPreview';
import ExpiredLimitBanner from '../../../components/ExpiredLimitBanner';
import FeaturesModal from '../../../components/FeaturesModal';

import SurveyForm from '../../Survey/Form';
import CommitmentForm from '../../Commitment/Form';
import ReportResultForm from '../../ReportResult/Form';
import ChargeForm from '../../Charge/Form';
import FormResultForm from '../../FormResult/Form';

import SurveyItem from '../../Survey/Item';
import CommitmentItem from '../../Commitment/Item';
import ReportItem from '../../Report/Item';
import ChargeItem from '../../Charge/Item';
import FormItem from '../../Form/Item';
import SignatureItem from '../../Signature/Item';

import EntityRecipients from '../../Entity/ui/Recipients';
import MessageRecipients from '../Recipients';
import AdminRecipients from '../../Admin/Recipients';

import MessageItem from '../../Message/Item';
import EntityItem from '../../Entity/Item';
import MessageLabel from '../../Message/Label';

import config from '../../../../../config/server.config';
import i18n, { __ } from '../../../i18n';
import * as utils from '../../../utils';
import { sendApprovalMessageEvent } from '../Edit/utils/sendApprovalMessageEvent';
import VideoPlayer from '../../../components/VideoPlayer';

import { PersonalizedFile } from './components/PersonalizedFile';
import { EntityLabel } from './components/EntityLabel';

import { shouldShowButton } from './helpers/shouldShowButton';
import { getMediaButtonStyles } from './helpers/getMediaButtonStyles';
import { getAttachmentButtonStyles } from './helpers/getAttachmentButtonStyles';
import { filterSelectedRecipients } from './helpers/filterSelectedRecipients';
import { openKnowMoreModal } from './helpers/openKnowMoreModal';

import { hasAttachments } from './helpers/hasAttachments';
import { getRecipients } from './helpers/getRecipients';
import { hasCustomFileRecipientsModal } from '../../../app/Domain/UseCases/Unleash/validations/HasCustomFileRecipientsModal';
import { isNetwork } from '../../../app/Domain/UseCases/Unleash/validations/IsNetwork';

import { hasInvalidSendLaterItem } from './helpers/hasInvalidSendLaterItem';
import { hasInvalidSendLaterCommitment } from './helpers/hasInvalidSendLaterCommitment'; 
import { AttachmentFile } from './components/AttachmentFile';

import { mergeRecipientsWithRoles } from './helpers/mergeRecipientsWithRoles';
import { mergeGroupsWithRoles } from './helpers/mergeGroupsWithRoles';

const ATTACHMENT_LIMIT = 20;
const ATTACHMENT_SIZE_MB = 25;
const BYTES_IN_ONE_MB = 1e+6;

const today = new Date();

const entityRecipientsQuery = gql`
  query EntityRecipientsQuery($entityId: ID!, $search: String) {
  node (id: $entityId) {
    ... on Entity {
      id: dbId
      recipients(search: $search) {
        nodes {
          id: dbId
          fullname
          type
          description
          picture {
            uri
            id: dbId
            key
          }
          ntfStartTime
          ntfEndTime
          dailyMessagesMetrics (offset: 0, limit: 1, period: 7) {
            directReplyTime
            directReplyMessages
            forwardedReplyTime
            forwardedReplyMessages
        }
          organization {
            id: dbId
            type
            confChannelMetrics
          }
        }
      }
    }
  }
}`;

const uploadMediaMutation = `mutation UploadMedia ($entityId: Int!) {
  uploadMedia (input: {entityId: $entityId, transcode: true}) {
    media {
      id: dbId
      filename
      uri(size:"w1280")
    }
  }
}`;

@inject('store', 'api')
@graphql(gql`query MessageFormQuery ($id: ID!) {
  node(id: $id) @connection(key: "Message", filter: ["id"]) {
    ... on Message {
      ... MessageItemEntity
      hasCustomMedia
    }
  }
}
  ${MessageItem.fragments.entityMessage}
`, {
  options: ownProps => ({
    variables: {
      id: ownProps.location.query.fwMessageId || ownProps.fwMessageId
    },
  }),
  skip: ownProps => !ownProps.location.query.fwMessageId && !ownProps.fwMessageId
})
@observer
export default class MessageForm extends Form {
  constructor(props) {
    super(props);
    this.styles = {
      row: {
        padding: '8px 24px',
        borderBottom: this.isMobile() ? '1px solid #f1f3f5' : null,
        width: '100%'
      },
    };

    this.attachments = {
      report: {
        plural: 'reports',
        title: __('Report'),
        modalTitle: __('Add Report'),
        icon: 'list layout',
        form: ReportResultForm,
        item: ReportItem,
        options: {
          canDeleteOnEdit: true
        }
      },
      commitment: {
        plural: 'commitments',
        title: __('Commitment'),
        modalTitle: __('Add Commitment'),
        icon: 'calendar',
        form: CommitmentForm,
        item: CommitmentItem,
        options: {
          canDeleteOnEdit: true,
        }
      },
      survey: {
        plural: 'surveys',
        title: __('Survey'),
        modalTitle: __('Add Survey'),
        icon: 'bar chart',
        form: SurveyForm,
        item: SurveyItem,
        options: {
          canDeleteOnEdit: true
        }
      },
      charge: {
        plural: 'charges',
        title: __('Charge'),
        modalTitle: __('Add Charge'),
        icon: 'donate',
        form: ChargeForm,
        item: ChargeItem,
        options: {
          canDeleteOnEdit: false
        }
      },
      form: {
        plural: 'forms',
        title: __('Form'),
        modalTitle: __('Add Form'),
        icon: 'poll h',
        form: FormResultForm,
        item: FormItem,
        options: {
          canDeleteOnEdit: true
        }
      },
      signature: {
        plural: 'signatures',
        title: __('Signature'),
        // modalTitle: __('Add Signature'),
        icon: 'file signature',
        // form: SignatureResultForm,
        item: SignatureItem,
        options: {
          canDeleteOnEdit: false
        }
      },
    };
  }

  state = {
    fetchAll: false,
    recipientButtonKeyDown: false,
    recipientError: '',
    messageError: '',
    advancedOptionsToast: false,
    recipientsFetched: false,
    hasPersonalizedFilesAdded: false,
    openPersonalizedMediaModal: false,
    totalFilesSize: 0,
    totalFileQuantity: 0,
  };

  defaultValues = {
    singleRecipient: null,
    recipients: [],
    groups: [],
    tags: [],
    labelId: null,

    subject: '',
    content: '',

    sendAt: null,
    type: 'DRAFT',

    fwMessageId: null,
    pin: false,
    public: false,
    forum: false,
    noReply: false,

    medias: [],
    signatures: [],
    surveys: [],
    commitments: [],
    reports: [],
    charges: [],
    forms: [],
    userAuthorHasEntity: true,
    folderName: '',
    
  }

  maybes = {
    medias: ['notEmpty', values => this.validateContent(values)]
  }

  rules = {
    medias: `maxLength:${ATTACHMENT_LIMIT}`,
    sendAt: [() => this.validateDates()]
  }

  componentDidMount() {
    const { organization, entity, edit, shouldSkipDraft } = this.props;

    if (edit && !shouldSkipDraft) this.checkRemovedFromGroup();
    const initiallySelectedTags = (organization) ?
      (this.props.edit ? this.state.values.tags : organization.tags && organization.tags.map((tag) => {
        if (tag.checked && !(entity.type === 'STUDENT')) {
          return tag.id;
        }
        return null;
      }).filter(tag => !!tag))
      : null;

    this.setValue('tags', initiallySelectedTags);
  }

  validateContent = (values) => {
    const div = document.createElement('div');
    div.innerHTML = values.content;
    return !(div.textContent.trim().length || (values.subject && values.subject.trim().length) || values.medias.length || values.surveys.length || values.commitments.length || values.reports.length || values.charges.length || values.forms.length);
  }

  validateDates = () => {
    const { values } = this.state;

    if (values.commitments.length > 0 && values.commitments.some((commitment) => hasInvalidSendLaterCommitment({ currentValue: commitment, sendAt: values.sendAt }))) {
      this.props.store.snackbar = { active: true, cssTags: 'big-height', message: __('The message cannot be scheduled because the attached commitment has a date and time or expiration prior to the scheduling'), success: false, dismissAfter: 5000 };
      throw new Error(__('The message cannot be scheduled because the attached commitment has a date and time or expiration prior to the scheduling'));
    }
    if (values.forms.length > 0 && values.forms.some((form) => hasInvalidSendLaterItem({ currentValue: form, sendAt: values.sendAt }))) {
      this.props.store.snackbar = { active: true, message: __('Form date limit cannot be before the scheduled message date'), success: false, dismissAfter: 3500 };
      throw new Error(__('Form date limit cannot be before the scheduled message date'));
    }
    if (values.charges.length > 0 && values.charges.some((charge) => hasInvalidSendLaterItem({ currentValue: charge, sendAt: values.sendAt }))) {
      this.props.store.snackbar = { active: true, message: __('Charge date limit cannot be before the scheduled message date'), success: false, dismissAfter: 3500 };
      throw new Error(__('Charge date limit cannot be before the scheduled message date'));
    }
    if (values.surveys.length > 0 && values.surveys.some((survey) => hasInvalidSendLaterItem({ currentValue: survey, sendAt: values.sendAt }))) {
      this.props.store.snackbar = { active: true, message: __('Survey date limit cannot be before the scheduled message date'), success: false, dismissAfter: 3500 };
      throw new Error(__('Survey date limit cannot be before the scheduled message date'));
    }
    return values;
  }

  onSuggestionSelect = (singleRecipient) => {
    const { values } = this.state;

    values.recipients = [singleRecipient.entity];
    values.singleRecipient = singleRecipient;
    values.allRecipients = values.recipients;

    this.setState({ values });

    this.props?.refreshRecipients?.([singleRecipient.entity], values.groups);
  }

  onSuggestionRemove = () => {
    const { values } = this.state;

    values.recipients = [];
    values.singleRecipient = null;
    values.allRecipients = values.recipients;

    this.setState({ values });

    this.props?.refreshRecipients?.([], values.groups);
  }

  handleSelectedItems = (data, openPersonalizedMediaModal = false, recipients = []) => {
    const { values } = this.state;
    const selectedAll = data.selected;
    const fetchedAll = this.state.fetchedAll || selectedAll;

    const groupsFiltered = data.groups.nodes.filter((group) => group.selected);
    const recipientsFiltered = filterSelectedRecipients({
      recipients: data.recipients.nodes,
      groups: groupsFiltered,
    });
 
    values.groups = mergeGroupsWithRoles(groupsFiltered, recipients);
    values.recipients = mergeRecipientsWithRoles(recipientsFiltered, recipients);
    values.allGroups = data.groups.nodes;
    values.allRecipients = data.recipients.nodes;

    this.setState({ values, selectedAll, fetchedAll, openPersonalizedMediaModal });
    this.props?.refreshRecipients?.(recipientsFiltered, groupsFiltered);
  }

  handleConsultantSelectedItens = (data) => {
    const selectedEntities = [];
    const { values } = this.state;

    const { nodes } = data;

    nodes.forEach((org) => {
      org.entities.nodes.forEach((entity) => {
        if (entity.selected) {
          selectedEntities.push(entity);
        }
      });
    });

    values.recipients = selectedEntities;
    values.allRecipients = selectedEntities;
    this.setState({
      values,
      recipientFormData: data
    });
    this.props?.refreshRecipients?.(selectedEntities, values.groups);
  }

  onFileDrop = (accepted, rejected) => {
    const { store } = this.props;

    const canSendVideos = !!this.props.consultantForm || (this.props.organization && this.props.organization.features && this.props.organization.features.videos);
    const canSendAudio = !!this.props.consultantForm || (this.props.organization && this.props.organization.features && this.props.organization.features.audios);
    const canSendOtherFiles = !!this.props.consultantForm || (this.props.organization && this.props.organization.features && this.props.organization.features.otherFiles);

    if (rejected.length > 0) {
      if (rejected.find(file => file.size > ATTACHMENT_SIZE_MB * BYTES_IN_ONE_MB)) {
        store.snackbar = { active: true, message: __('File too big, maximum size is %s MB.', ATTACHMENT_SIZE_MB), success: false, dismissAfter: 5000 };
      } else {
        store.snackbar = { active: true, message: __('File format not accepted, please provide an image, video or PDF.'), success: false };
      }
    }

    const attachmentsCount = this.state.values.medias.length + this.state.values.surveys.length + this.state.values.commitments.length + this.state.values.reports.length;

    if (accepted.length + attachmentsCount > ATTACHMENT_LIMIT) {
      accepted = accepted.splice(0, ATTACHMENT_LIMIT - attachmentsCount);
      store.snackbar = { active: true, message: __('You can not have more than %s attachments in a message, exceeding files will be ignored', ATTACHMENT_LIMIT), success: false, dismissAfter: 4000 };
    }

    if (accepted.length > 0) {
      let medias = this.state.values.medias;
      let index = medias.length - 1;

      const blacklist = [
        'application', 'gadget', 'msp', 'ade', 'adp', 'apk', 'appx', 'appxbundle', 'bat',
        'cab', 'chm', 'cmd', 'com', 'cpl', 'dll', 'dmg', 'exe', 'hta', 'ins', 'inf',
        'isp', 'iso', 'jar', 'js', 'jse', 'lib', 'lnk', 'mde', 'msc', 'msi', 'msix',
        'msixbundle', 'msp', 'mst', 'nsh', 'msh', 'msh1', 'msh2', 'mshxml', 'msh2xml',
        'msh1xml', 'pif', 'ps1', 'ps2', 'ps1xml', 'ps2xml', 'psc1', 'psc2', 'reg', 'scr',
        'sct', 'shb', 'sys', 'vb', 'vbe', 'vbs', 'vxd', 'wsc', 'wsf', 'wsh', 'html', 'htm'
      ];

      let hasNotAcceptedAudios = false;
      let hasNotAcceptedFiles = false;
      let hasNotAcceptedVideos = false;

      accepted = accepted.filter((file) => {
        if (!canSendAudio && utils.isAudioFile(file.type, file.name)) {
          if (utils.isEntityType(store, 'STUDENT')) {
            store.snackbar = { active: true, message: __('Your current plan does not allow the upload of the selected file type.'), success: false, dismissAfter: 4000 };
          } else {
            hasNotAcceptedAudios = true;
          }
          return false;
        }

        if (!canSendVideos && utils.isVideoFile(file.type, file.name)) {
          if (utils.isEntityType(store, 'STUDENT')) {
            store.snackbar = { active: true, message: __('Your current plan does not allow the upload of the selected file type.'), success: false, dismissAfter: 4000 };
          } else {
            hasNotAcceptedVideos = true;
          }
          return false;
        }

        if (blacklist.some(extension => file.name.endsWith('.' + extension))) {
          store.snackbar = { active: true, message: __('This file type is not allowed'), success: false, dismissAfter: 4000 };
          return false;
        }

        const isImage = utils.isImageFile(file.type);
        const isVideo = utils.isVideoFile(file.type, file.name);
        const isPdf = utils.isPdfFile(file.name);

        if (!canSendOtherFiles && !isPdf && !isImage && !isVideo) {
          rejected.push(file);
          if (utils.isEntityType(store, 'STUDENT')) {
            store.snackbar = { active: true, message: __('Your current plan does not allow the upload of the selected file type.'), success: false, dismissAfter: 4000 };
          } else {
            hasNotAcceptedFiles = true;
          }
          return false;
        }

        return true;
      });

      if (hasNotAcceptedAudios) this.openPaywall('AUDIOS');
      if (hasNotAcceptedVideos) this.openPaywall('VIDEO_MESSAGE');
      if (hasNotAcceptedFiles) this.openPaywall('MORE_FILES');

      accepted.forEach((file) => {
        file.loading = true;
        file.uniqueId = uniqueId();

        index += 1;
        medias = [...medias, file];

        this.setState({ values: { ...this.state.values, medias } });
        this.uploadFile(file, index);
        this.scrollToBottom();
      });
    }
  }

  uploadErrorModal = (errorMessage) => {
    const anotherModalIsOpen = !!this.props.store.appends.find(e => (e.props.id === 'uploadErrorModal'));
    if (!anotherModalIsOpen) {
      this.props.store.appends.push(<Modal
        id="uploadErrorModal"
        size="tiny"
        onClose={() => this.props.store.appends.pop()}
        header={__('Upload Error')}
        content={errorMessage}
        actions={[
          <Button
            data-action="close"
            key={0}
            basic
            content={__('Close')}
            onClick={() => this.props.store.appends.pop()}
          />
        ]}
      />);
    }
  }

  uploadFile = file => this.props.api.upload('media', {
    file,
    query: uploadMediaMutation,
    variables: `{"entityId": ${(this.props.values.entity && this.props.values.entity.id )|| this.props.entity.id }}`
  }, progressEvent => this.onUploadProgress(progressEvent, file.uniqueId))
    .then((data) => {
      file.loading = false;

      if (data.errors) throw new Error(data.errors[0].message);
      file.response = data.data.uploadMedia.media;

      const medias = this.state.values.medias;
      const index = medias.findIndex(m => m.uniqueId === file.uniqueId);
      medias[index] = file;

      this.setState({ values: { ...this.state.values, medias } });
    }).catch((error) => {
      file.loading = false;
      file.failed = true;

      const medias = this.state.values.medias;
      const index = medias.findIndex(m => m.uniqueId === file.uniqueId);
      medias.splice(index, 1);

      switch (error.message) {
        case 'This file type is not allowed':
          this.uploadErrorModal(__('This file type is not allowed'));
          break;
        case 'Organization has no permission to send audios':
          this.uploadErrorModal(__('Organization has no permission to send audios'));
          break;
        case 'Organization has no permission to send videos':
          this.uploadErrorModal(__('Organization has no permission to send videos'));
          break;
        case 'Organization has no permission to send files':
          this.uploadErrorModal(__('Organization has no permission to send files, except PDF'));
          break;
        default:
          this.uploadErrorModal(__("Some files couldn't be attached. Please check your internet connection and try again"));
      }

      this.setState({ values: { ...this.state.values, medias } });
    });

  onUploadProgress = (progressEvent, uId) => {
    const { loaded, total } = progressEvent;
    const medias = this.state.values.medias;

    const media = medias.find(m => m.uniqueId === uId);

    media.progress = Math.round((loaded * 100) / total);

    this.setState({ values: { ...this.state.values, medias } });
  }

  onAttachmentSubmit = (where, attachment, i = null) => {
    const { store } = this.props;
    const { values } = this.state;
    this.props.store.appends.pop();

    this.scrollToBottom();

    if (where === 'reports' && ((i === null && values.reports.find(report => report.id === attachment.id)) || (i !== null && values.reports.filter(report => report.id === attachment.id).length > 1))) {
      store.snackbar = { active: true, message: __('Equal reports will be merged when message is sent.'), dismissAfter: 5000 };
    }

    if (i === null) {
      this.pushValue(where, attachment);
    } else {
      this.replaceValue(where, attachment, i);
    }

    if (where === 'reports') {
      const { selectedAll, fetchedAll } = attachment;

      if (attachment.recipients.length && !this.state.values.recipients.length) {
        values.recipients = attachment.recipients;
      }

      if (attachment.allRecipients && attachment.allRecipients.length && !(this.state.values.allRecipients && this.state.values.allRecipients.length)) {
        values.allRecipients = attachment.allRecipients;
      }

      if (attachment.groups && attachment.groups.length && !this.state.values.groups.length) {
        values.groups = attachment.groups;
      }

      if (attachment.allGroups && attachment.allGroups.length && !(this.state.values.allGroups && this.state.values.allGroups.length)) {
        values.allGroups = attachment.allGroups;
      }

      this.setState({ values, selectedAll, fetchedAll });
      this.props?.refreshRecipients?.(values.recipients, values.groups);
    }
  }

  renderReportWarning = () => {
    const mobileStore = i18n.locale === 'pt' ? {
      android: { badge: config.android.badge.pt, link: config.android.link.br },
      ios: { badge: config.ios.badge.pt, link: config.ios.link.br }
    } :
      {
        android: { badge: config.android.badge.en, link: config.android.link.us },
        ios: { badge: config.ios.badge.en, link: config.ios.link.us }
      };

    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ height: '81px', width: '81px', marginBottom: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Image src={utils.asset('/logo/ClassApp-LOGO-SIMBOLO_azul.png')} size="small" style={{ display: 'flex' }} />
        </div>

        <div style={{ marginBottom: '16px', width: '80%', fontSize: '16px', textAlign: 'center' }}>{__('To access reports, use the app or access via computer')}</div>
        <BetaButton
          data-action="download"
          round
          text={__('Download the app')}
          onClick={() => { utils.isIOSDevice() ? window.open(`${mobileStore.ios.link}`) : window.open(`${mobileStore.android.link}`); this.props.store.appends.pop(); }}
          style={{ marginBottom: '23.5px', width: '90%', height: '47px' }}
        />
        <div onClick={() => this.props.store.appends.pop()} style={{ color: '#0069FF', marginBottom: '23.5px', fontSize: '16px' }}>{__('Not now')}</div>
      </div>
    );
  }

  openAttachmentForm = (type, i = null) => {
    const attachmentsCount = sum(['medias', 'surveys', 'commitments', 'reports', 'charges', 'forms'].map(attachmentType => this.state.values[attachmentType].length));

    if (i === null && attachmentsCount + 1 > ATTACHMENT_LIMIT) {
      this.props.store.snackbar = { active: true, message: __('You can not have more than %s attachments in a message', ATTACHMENT_LIMIT), success: false, dismissAfter: 4000 };
      return;
    }

    const attachment = this.attachments[type];

    const value = i === null ? undefined : this.state.values[attachment.plural][i];
    const items = i === null ? this.state.values[attachment.plural] : this.state.values[attachment.plural].filter((item, j) => j !== i);

    if (type !== 'report') {
      this.props.store.appends.push(
        <BetaModal
          id={`${type}AttachmentForm`}
          fullScreen={this.isMobile()}
          onClose={() => this.props.store.appends.pop()}
          closeOnRootNodeClick={false}
          size={type === 'charge' ? 'small' : 'tiny'}
          spaceFooter
          header={this.isMobile() ? null : attachment.modalTitle}
          portalHeader={this.isMobile()}
          content={
            <attachment.form
              modal
              values={value}
              organization_id={(this.props.organization) ? this.props.organization.id : null}
              entity_id={this.props.entity.id}
              onSubmit={val => this.onAttachmentSubmit(attachment.plural, val, i)}
              i={i}
              submitButton={{ text: i === null ? __('Add') : __('Save'), isActionButtom: true }}
              onCancel={() => this.props.store.appends.pop()}
              cancelButton={{ text: __('Cancel'), isActionButtom: true }}
              params={this.props.params}
              {...{ [attachment.plural]: items }}
            />
          }
        />
      );
    } else if (this.isMobile()) {
      this.props.store.appends.push(<BetaModal
        id="OptionsToast"
        toast
        invertCloseButton
        onClose={() => this.props.store.appends.pop()}
        closeOnRootNodeClick
        header={__('Warning')}
        spaceFooter
        content={this.renderReportWarning()}
      />);
    } else {
      this.props.store.appends.push(
        <attachment.form
          modal
          edit={i !== null}
          values={value}
          organization={this.props.organization}
          entity={this.props.entity}
          onSubmit={val => this.onAttachmentSubmit(attachment.plural, val, i)}
          submitButton={{ text: i === null ? __('Add') : __('Save'), isActionButtom: true }}
          onCancel={(recipients, selectedAll, fetchedAll, allRecipients, groups, allGroups) => {
            const { values } = this.state;

            if (recipients.length && !this.state.values.recipients.length) {
              values.recipients = recipients;
            }

            if (allRecipients && allRecipients.length && !this.state.values.allRecipients) {
              values.allRecipients = allRecipients;
            }

            if (groups && groups.length && !this.state.values.groups.length) {
              values.groups = groups;
            }

            if (allGroups && allGroups.length && !this.state.values.allGroups) {
              values.allGroups = allGroups;
            }

            this.setState({ selectedAll, fetchedAll, values });
            this.props?.refreshRecipients?.(values.recipients, values.groups);
            this.props.store.appends.pop();
          }}
          recipients={this.state.values.allRecipients ? this.state.values.allRecipients.filter(recipient =>
            recipient.selected || !!(this.state.values.groups.filter(g => g.entities.nodes.findIndex(e => e.id === recipient.id) > -1).length)
          ) : []}
          selectedAll={this.state.selectedAll}
          fetchedAll={this.state.fetchedAll}
        />
      );
    }
  }

  openEntityRecipientForm = (selected, openPersonalizedMediaModal = false) => {
    const { recipientButtonKeyDown } = this.state;
    if (!recipientButtonKeyDown) {
      this.props.store.appends.push(
        <BetaModal
          id={'recipientAttachmentForm'}
          size="tiny"
          fullScreen={this.isMobile()}
          marginBottom
          spaceFooter
          actions={!this.isMobile()}
          portalHeader
          scrolling
          content={
            <EntityRecipients
              modal
              isMobile={this.isMobile()}
              cancelButton={{ isActionButtom: true }}
              selectedOnly={selected}
              onSubmit={(data, recipients) => { this.props.store.appends.pop(); this.handleSelectedItems(data, openPersonalizedMediaModal, recipients); }}
              submitButton={{ isActionButtom: true }}
              onCancel={() => this.props.store.appends.pop()}
              organization={this.props.organization}
              entity={this.props.entity}
              fetchOnSubmit={!this.state.fetchedAll}
            />
          }
        />
      );
    }

    this.setState({ recipientButtonKeyDown: false });
  }

  openConsultantRecipientForm = (selected) => {
    const { consultantRecipientButtonKeyDown } = this.state;
    if (!consultantRecipientButtonKeyDown) {
      this.props.store.appends.push(
        <Modal
          id={'consultantRecipientAttachmentForm'}
          size="tiny"
          actions
          scrolling
          header={selected ? __('Selected recipients') : __('Recipients')}
          content={
            <AdminRecipients
              modal
              selectedOnly={selected}
              onSubmit={(data) => { this.props.store.appends.pop(); this.handleConsultantSelectedItens(data); }}
              submitButton={__('Save')}
              onCancel={() => this.props.store.appends.pop()}
              recipients={this.state.values.recipients}
              // organizations={this.state.organizations}
              organizationIds={this.props.organizationIds}
              fetchOnSubmit={!this.state.fetchedAll}
            />
          }
        />
      );
    }
    this.setState({ consultantRecipientButtonKeyDown: false });
  }

  beforeSubmit = (values) => {
    const { store } = this.props;
    const helpers = { reports: values.reports, forms: values.forms };

    helpers.recipients = ((!!values.allRecipients && !!values.allRecipients.length) || (!!values.allGroups && !!values.allGroups.length)) ? {
      id: store && store.entity && store.entity.id,
      selected: !!this.state.selectedAll,
      groups: {
        nodes: values.allGroups,
        __typename: 'EntityGroupsConnection'
      },
      recipients: {
        nodes: values.allRecipients,
        __typename: 'EntityRecipientsConnection'
      },
      __typename: 'Entity'
    } : undefined;

    values.helpers = JSON.stringify(helpers);

    //Get filename from medias
    if (values.medias.length > 0) {
      values.medias = values.medias.filter(media => media.response || media.loading).map((media) => {
        if (media.loading) return media;
        return {
          filename: media.response.filename,
          type: utils.getMediaType(media.type, media.name),
          size: media.size,
          uri: media.response.uri,
          origName: media.name
        };
      });
    }

    values.reports = values.reports.map((report) => {
      if (report.multiple) {
        report.entities = report.entities.filter(entity => !!values.recipients.find(r => r.id === entity.id) || !!values.groups.find(g => !!g.entities.nodes.find(r => r.id === entity.id)));
      }

      return report;
    });

    values.recipients = values.recipients.map(recipient => recipient.id);
    values.groups = values.groups.map(group => group.id);

    return values;
  }

  onError = (errors) => {
    if (errors.medias && this.validateContent(this.state.values)) {
      this.props.store.snackbar = { active: true, success: false, message: __("You can't send an empty message") };
    }
  }

  handleProps = () => {
    const { values } = this.state;
    const newProps = { ...this.props, modal: true };
    const { store } = newProps;
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';

    let disabled = false;
    let disabledStyle = false;

    if (newProps.shouldSkipDraft) {
      if ((values.medias.filter(media => media.loading).length) || (!this.hasModifications())) {
        if (!this.hasModifications()) {
          disabled = true;
        }
        disabledStyle = true;
      }
    } else if ((this.validateContent(values) || !(values.groups.length || values.recipients.length) || values.medias.filter(media => media.loading).length)) {
      disabledStyle = true;
    }

    newProps.actionButtons = [];
    newProps.actionButtonsRight = [];
    newProps.actionButtonsLeft = [];

    if (newProps.consultantForm) {
      if (newProps.cancelButton && newProps.onCancel && !this.isMobile()) newProps.actionButtonsLeft.push(this.renderCancelButton(values));
      if (!this.isMobile()) newProps.actionButtonsRight.push(this.renderAllAttachments());
      if (newProps.submitButton && newProps.onSubmit && !this.isMobile()) newProps.actionButtonsRight.push(this.renderSubmitButton());
    } else if (this.props.edit && newProps.shouldSkipDraft) {
      if (newProps.entity.type !== 'STUDENT' && !this.isMobile()) newProps.actionButtonsLeft.push(this.renderAdvancedOptions());
      if (newProps.cancelButton && newProps.onCancel && !this.isMobile()) newProps.actionButtonsRight.push(this.renderCancelButton(values));
      if (newProps.submitButton && newProps.onSubmit && !this.isMobile()) newProps.actionButtonsRight.push(this.renderSubmitButton());
    } else {
      if (newProps.cancelButton && newProps.onCancel && !this.isMobile()) newProps.actionButtonsLeft.push(this.renderCancelButton(values));
      if (newProps.entity.type !== 'STUDENT' && !newProps.shouldSkipDraft && !this.isMobile()) newProps.actionButtonsLeft.push(this.renderAdvancedOptions());
      if (!newProps.shouldSkipDraft && !this.isMobile() && newProps.id !== 'MessageToApproveEdit') newProps.actionButtonsRight.push(this.renderSendLater(lang, disabled, disabledStyle, values));
      if (newProps.submitButton && newProps.onSubmit && !this.isMobile()) newProps.actionButtonsRight.push(this.renderSubmitButton());
    }

    return newProps;
  }

  checkErrorsBeforeSubmit = (callback) => {
    const { values } = this.state;
    const newProps = { ...this.props, modal: true };
    if (newProps.shouldSkipDraft) {
      if ((values.medias.filter(media => media.loading).length) || (!this.hasModifications())) {
        return null;
      }
    } else if ((this.validateContent(values) || !(values.groups.length || values.recipients.length) || values.medias.filter(media => media.loading).length)) {
      if (!(values.groups.length || values.recipients.length)) {
        this.setState({ recipientError: __('Required field') });
      } else {
        this.setState({ recipientError: '' });
      }

      if (this.validateContent(values)) {
        this.setState({ messageError: __('Required field') }, () => {
          if ((values.groups.length || values.recipients.length)) {
            this.myRef.scrollIntoView({ behavior: 'smooth' });
          }
        });
      } else {
        this.setState({ messageError: '' });
      }

      if (values.medias.filter(media => media.loading).length) {
        this.props.store.appends.push(<BetaModal
          toast={this.isMobile()}
          invertCloseButton={this.isMobile()}
          id="ErrorModal"
          onClose={() => this.props.store.appends.pop()}
          actions={this.isMobile() ? <div /> : [
            <BetaButton
              data-action="ok"
              round
              text={__('Ok')}
              onClick={() => this.props.store.appends.pop()}
            />
          ]}
          header={__('Wait')}
          content={
            <div style={this.isMobile() ? { marginTop: '20px', marginBottom: '20px' } : {}}>
              <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>{__('Upload in progress.')}</span>
            </div>
          }
        />);
      }
      return null;
    }
    callback();
  }

  renderSubmitButton = () => {
    const { values } = this.state;
    const { store } = this.props;
    const newProps = { ...this.props, modal: true };
    let disabled = false;

    const isMessageWriter = utils.isMessageWriter({ store: newProps.store });
    const hasStudents = utils.hasStudentInGroup(values.groups) || utils.hasStudentInList(values.recipients);
    const { scope }  = newProps?.store?.entity || { scope: null };
    const isApprover = !scope || (scope && scope.indexOf('CONTENT_APPROVER') !== -1) || newProps?.store?.entity.type === 'ADMIN';

    let submitButtonText = '';

    if(newProps.edit && isApprover && newProps.id === 'MessageToApproveEdit') {
      const { messageToApproveId } = this.props
      newProps.values.messageToApproveId = messageToApproveId
    } else if (newProps.edit) {
        submitButtonText = values.sendAt || newProps.id === 'MessageEdit'
          ? __('Save')
          : (isMessageWriter && hasStudents) ? __('Submit for approval') : __('Send now');
          newProps.submitButton = { text: submitButtonText };
    } else if (isMessageWriter && !hasStudents && (values.groups.length > 0 || values.recipients.length > 0)) {
      submitButtonText = __('Send');
      newProps.submitButton = { text: submitButtonText };
    }

    if (newProps.shouldSkipDraft) {
      if ((values.medias.filter(media => media.loading).length) || (!this.hasModifications())) {
        if (!this.hasModifications()) {
          disabled = true;
        }
        newProps.submitButton = {
          ...newProps.submitButton,
          disabled,
          disabledStyle: true
        };
      }
    } else if ((this.validateContent(values) || !(values.groups.length || values.recipients.length) || values.medias.filter(media => media.loading).length)) {
      newProps.submitButton = {
        ...newProps.submitButton,
        disabled,
        disabledStyle: true
      };
    }
    const { submitButtonIcon, loading, disableSave = false } = this.props;
    const submitProps = (typeof newProps.submitButton === 'string') ? { text: newProps.submitButton } : newProps.submitButton;

    if (disableSave) {
      return (
        <Popup
          style={{ textAlign: 'center' }}
          position="top center"
          trigger={
            <div>
              <BetaButton
                data-action="submit"
                round
                style={loading ? { marginRight: this.isMobile() ? 'unset' : '10px', padding: '0px', height: '43.19px', width: this.isMobile() ? '77.36px' : '95px', fontSize: '16px', opacity: !submitProps.disabled && submitProps.disabledStyle ? '.45' : '1' } : { marginRight: this.isMobile() ? 'unset' : '10px', padding: '0.9rem 1.143rem', fontSize: '16px', minWidth: this.isMobile() ? '77.36px' : '95px', opacity: !submitProps.disabled && submitProps.disabledStyle ? '.45' : '1' }}
                loading={loading}
                disabled={disableSave || loading}
                icon={submitButtonIcon ? { name: submitButtonIcon } : null}
                red={this.props.red}
                onClick={() => this.checkErrorsBeforeSubmit(() => (newProps.shouldSkipDraft && !isNetwork(store.currentOrganization) ? this.showNotificationOptionsModal() : this.handleSubmit({ preventDefault: () => { } })))}
                {...submitProps}
              />
            </div>
          }
          content={__("Approved messages can only be edited by an reviewer")}
        />

      )
    }
    if (submitProps && submitProps.DSButton){
      return <DSButton
      loading={loading}
      disabled={disableSave || loading}
      data-action="submit"
      variation="success"
      size="medium"
      onClick={() => this.checkErrorsBeforeSubmit(() => {
        sendApprovalMessageEvent('Message edited and approved', this.props.store)
        (this.handleSubmit({ preventDefault: () => { } }))
      })}
      >
          <ThumbsUpLight style={{marginRight: 6, paddingTop: 2}} fill="#FFFFFF" width={16} height={16} />
        { __('Save and approve submission')}
        </DSButton>
    }

    return (<BetaButton
      data-action="submit"
      round
      style={loading ? { marginRight: this.isMobile() ? 'unset' : '10px', padding: '0px', height: '43.19px', width: this.isMobile() ? '77.36px' : '95px', fontSize: '16px', opacity: !submitProps.disabled && submitProps.disabledStyle ? '.45' : '1' } : { marginRight: this.isMobile() ? 'unset' : '10px', padding: '0.9rem 1.143rem', fontSize: '16px', minWidth: this.isMobile() ? '77.36px' : '95px', opacity: !submitProps.disabled && submitProps.disabledStyle ? '.45' : '1' }}
      loading={loading}
      disabled={disableSave || loading}
      icon={submitButtonIcon ? { name: submitButtonIcon } : null}
      red={this.props.red}
      onClick={() => this.checkErrorsBeforeSubmit(() => (newProps.shouldSkipDraft && !isNetwork(store.currentOrganization) ? this.showNotificationOptionsModal() : this.handleSubmit({ preventDefault: () => { } })))}
      {...submitProps}
    />);
  }

  renderFilesButton = () => (<Button
    className={'padding-icon'}
    data-action="cancel"
    type="button"
    floated="left"
    basic
    disabled={this.props.loading}
    content={null}
    icon={{ name: 'paperclip', style: { margin: '0px', color: '#000000', transform: 'rotate(45deg)' } }}
    style={{ boxShadow: 'none' }}
    onClick={() => this.props.store.appends.push(<BetaModal
      toast
      id={'FileToast'}
      header={__('Attachments')}
      closeOnRootNodeClick
      invertCloseButton
      scrolling
      onClose={() => this.props.store.appends.pop()}
      content={
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {this.props.consultantForm && this.renderAllAttachments(true)}
          {!this.props.consultantForm && this.renderStaffAttachments(true)}
        </div>
      }
    />)}
  />)

  renderCancelButton = (values) => {
    const { onCancel, cancelButton, cancelButtonStyle, loading, disableSave } = this.props;
    const cancelProps = (typeof cancelButton === 'string') ? { text: cancelButton, style: cancelButtonStyle || {} } : cancelButton;

    return (<Button
      className={`${!cancelProps.icon ? 'round black-content' : 'big-icon'}`}
      data-action="cancel"
      type="button"
      floated="left"
      basic
      disabled={disableSave || loading}
      content={cancelProps.text !== undefined ? cancelProps.text : __('Cancel')}
      onClick={() => onCancel({ ...values, onCancel: true })}
      {...cancelProps}
    />);
  }

  openDatePicker = (nextDayAvailable = null) => {
    if (nextDayAvailable) {
      this.props.store.appends.push(<DatePicker
        selectedDt={nextDayAvailable}
        minDate={new Date(nextDayAvailable - (60 * 60 * 24 * 1000))}
        onCancel={() => { }}
        message={
          <div style={{ margin: '12px 12px 0' }}>
            <span style={{ color: '#6C6C6C', fontSize: '12px' }}>{__("This month's message limit has been exceeded, please choose a date from the next available.")}</span>
            {' '}
            <span style={{ textDecoration: 'underline', cursor: 'pointer', color: '#6C6C6C', fontSize: '12px' }} onClick={() => openKnowMoreModal({ store })}>{__('Know More')}</span>
          </div>
        }
        onSubmit={(date) => {
          this.setValue('sendAt', date, () => this.handleSubmit({ preventDefault: () => { } }));
          if (this.isMobile()) this.props.store.appends.pop();
        }}
        submitButton={{
          text: __('Schedule'),
          icon: { name: 'check' }
        }}
      />);
    } else {
      const { sendAt } = this.state.values;
      const nextDay = utils.roundDate(new Date(today.getTime() + 86400000));

      this.props.store.appends.push(<DatePicker
        selectedDt={sendAt || nextDay}
        onCancel={() => { }}
        onSubmit={(date) => {
          this.setValue('sendAt', date, () => this.handleSubmit({ preventDefault: () => { } }));
          if (this.isMobile()) this.props.store.appends.pop();
        }}
        submitButton={{
          text: __('Schedule'),
          icon: { name: 'check' }
        }}
      />);
    }
  }

  openLabelForm = () => this.props.store.appends.push(
    <MessageLabel
      isMobile={this.isMobile()}
      label={this.state.values.labelId}
      labels={this.props.organization.labels}
      onCancel={() => this.props.store.appends.pop()}
      onSubmit={labelId => this.setValue('labelId', labelId, () => this.props.store.appends.pop())}
    />
  )

  renderAdvancedOptions = () => <Popup
    trigger={<Button
      className={'no-border padding-icon black-content padding-center big-icon'}
      icon="cog"
      data-action="open-setting"
      basic
      floated="left"
      style={{ boxShadow: 'none' }}
    />}
    content={
      <Menu vertical style={{ textAlign: 'left', width: '100%' }}>
        <Menu.Item header style={{ fontSize: '.8em' }}>{__('Advanced Options').toUpperCase()}</Menu.Item>
        <Menu.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ColoredCheckbox
              data-action="select-advanced-option"
              data-params="important"
              label={__('Mark as important')}
              name="pin"
              checked={this.state.values.pin}
              onClick={() => this.setValue('pin', !this.state.values.pin)}
            />
            <Icon name="star" style={{ marginLeft: '6px' }} />
          </div>
        </Menu.Item>
        <Menu.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ColoredCheckbox
              data-action="select-advanced-option"
              data-params="public"
              label={__('Public post')}
              name="public"
              checked={this.state.values.public}
              onClick={() => this.setValue('public', !this.state.values.public)}
            />
            <Icon name="globe" style={{ marginLeft: '6px' }} />
          </div>
        </Menu.Item>
        {/*<Menu.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ColoredCheckbox
              label={__('Public forum')}
              name="forum"
              checked={this.state.values.forum}
              onClick={() => this.setValue('forum', !this.state.values.forum)}
            />
            <Icon name="comments" style={{ marginLeft: '6px' }} />
          </div>
        </Menu.Item>*/}
        <Menu.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ColoredCheckbox
              data-action="select-advanced-option"
              data-params="no-reply"
              label={__('Disable replies')}
              name="noReply"
              checked={this.state.values.noReply}
              onClick={() => this.setValue('noReply', !this.state.values.noReply)}
            />
            <Icon name="ban" style={{ marginLeft: '6px' }} />
          </div>
        </Menu.Item>
      </Menu>
    }
    on="click"
    position="bottom left"
    style={{ padding: 0, border: 0, margin: 0 }}
    basic
    hideOnScroll
  />

  renderAdvancedOptionsButton = () => <Button
    className={'no-border padding-icon black-content padding-center big-icon'}
    icon="cog"
    data-action="open-setting"
    basic
    floated="left"
    style={{ boxShadow: 'none' }}
    onClick={() => this.toggleAdvancedOptionsToast()}
  />

  renderAdvancedOptionsToast = () => <BetaModal
    toast
    id={'AdvancedOptionsToast'}
    header={__('Options')}
    closeOnRootNodeClick
    invertCloseButton
    onClose={() => this.toggleAdvancedOptionsToast()}
    content={
      <div className="options-toast" style={{ display: 'flex', flexDirection: 'column', marginBottom: '12px' }}>
        <Button
          data-action="select-advanced-option"
          data-params="important"
          name="pin"
          onClick={() => this.setValue('pin', !this.state.values.pin)}
        >
          <span style={{ display: 'flex', fontSize: '16px' }}>
            <Icon name="star" style={{ marginRight: '16px' }} />
            {__('Mark as important')}
          </span>
          <ColoredCheckbox
            radio
            radioChecked
            checked={this.state.values.pin}
            color="#084FFF"
            iconStyle={{ fontSize: '24px' }}
          />
        </Button>
        <Button
          data-action="select-advanced-option"
          data-params="public"
          name="public"
          onClick={() => this.setValue('public', !this.state.values.public)}
        >
          <span style={{ display: 'flex', fontSize: '16px' }}>
            <Icon name="globe" style={{ marginRight: '16px' }} />
            {__('Public post')}
          </span>
          <ColoredCheckbox
            radio
            radioChecked
            checked={this.state.values.public}
            color="#084FFF"
            iconStyle={{ fontSize: '24px' }}
          />
        </Button>
        <Button
          data-action="select-advanced-option"
          data-params="no-reply"
          name="noReply"
          onClick={() => this.setValue('noReply', !this.state.values.noReply)}
        >
          <span style={{ display: 'flex', fontSize: '16px' }}>
            <Icon name="ban" style={{ marginRight: '16px' }} />
            {__('Disable replies')}
          </span>
          <ColoredCheckbox
            radio
            radioChecked
            checked={this.state.values.noReply}
            color="#084FFF"
            iconStyle={{ fontSize: '24px' }}
          />
        </Button>
      </div>
    }
  />

  toggleAdvancedOptionsToast = () =>
    this.setState((previousState => ({ advancedOptionsToast: !previousState.advancedOptionsToast })));

  renderOneRecipient = () => {
    const { errors, values, recipientError } = this.state;
    const { confChannelMetrics } = this.props.store.currentEntity.organization;

    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1 }}>

        <Form.Field
          title={__('Recipient')}
          error={errors && errors.recipients}
          style={{ flex: 1, }}
        >
          <SingleAutosuggest
            onSuggestionSelect={this.onSuggestionSelect}
            onSuggestionRemove={this.onSuggestionRemove}
            request={entityRecipientsQuery}
            requestArguments={{ entityId: this.props.entity.id, search: '' }}
            selected={values.singleRecipient}
            apiExtract={{ name: 'fullname' }}
            resultExtract={{ edgeName: 'recipients' }}
            style={{ image: true, icon: 'user' }}
            dropdownCssStyle={{ width: '100%', border: 'none', boxShadow: 'none', paddingLeft: 0, fontSize: '16px' }}
            placeholder={errors && errors.recipients ?
              __('Add one recipient') :
              __('Recipient')
            }
            isMobile={this.isMobile()}
            rightView={confChannelMetrics ? <div style={{ textAlign: 'center', fontSize: 12, color: '#999999' }}>{__('Reply Time')}</div> : null}
          />
          {!!recipientError &&
            <div className="error-warning-red" style={{ marginLeft: this.isMobile() ? '-12px' : '0px' }}>
              <Icon className="circle exclamation" />
              <span>{recipientError}</span>
            </div>
          }
        </Form.Field>
      </div>
    );
  }

  checkRemovedFromGroup = async () => {
    const { recipients, entity } = this.props;

    const __recipients = recipients && recipients.groups && recipients.groups.nodes ? recipients.groups.nodes : [];

    const __groupsIds = entity && entity.groups && entity.groups.nodes ? entity.groups.nodes.map(group => group.id) : [];
    const __groups = recipients && recipients.groups && recipients.groups.nodes ? recipients.groups.nodes.filter(group => (__groupsIds.indexOf(group.id) > -1) || group.id === 'staff') : [];

    const selectedRecipients = __recipients.length ? __recipients.filter(recipient => recipient.selected) : [];

    const removed = [];

    await selectedRecipients.forEach((selectedRecipient) => {
      for (let i = 0; i < __groups.length; i++) {
        if (__groups[i].id === selectedRecipient.id) return;
      }
      removed.push(selectedRecipient.name);
    });
    this.setState({ removedFromGroups: removed });
  }

  enableAddRecipients = () => {
    if (!this.state.recipientsFetched) this.setState({ recipientsFetched: true });
  }

  renderManyRecipients = () => {
    const { errors, values, removedFromGroups, recipientError } = this.state;
    let warningMessage = false;
    if (removedFromGroups && removedFromGroups.length === 1) {
      warningMessage = <span>{__('The following group was removed from recipients because you have lost access to it: ')} <br /> {removedFromGroups[0]} <br /></span>;
    } else if (removedFromGroups && removedFromGroups.length > 1) {
      warningMessage = <span>{__('The following groups were removed from recipients because you have lost access to them: ')} <br /> {removedFromGroups.map(group => <span>{group}<br /></span>)}</span>;
    }
    return (
      <Form.Field
        style={{ display: 'flex', flexDirection: 'column', minHeight: this.isMobile() ? '60px' : '72px' }}
        error={errors && (errors.groups || errors.recipients) && !(values.recipients.length || values.groups.length)}
        onClick={() => (this.isMobile() ? this.openEntityRecipientForm() : null)}
      >
        <div title={__('Recipients')} style={{ display: 'flex', alignItems: 'center', flexFlow: 'row nowrap', minHeight: this.isMobile() ? '60px' : '72px' }}>
          {!this.isMobile() &&
            <div style={{ width: '156px' }}>
              <BetaButton
                text={__('Recipients')}
                icon={{ name: 'plus', style: { fontSize: '16px' } }}
                style={{ display: 'flex', flexWrap: 'nowrap', padding: '0.788rem 0.875rem', margin: '0' }}
                blue
                data-action="open-entity-recipient-form"
                onClick={() => this.openEntityRecipientForm()}
                round
                disabled={!this.state.recipientsFetched}
              />
            </div>
          }

          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <MessageRecipients
              handleSelectedItems={this.handleSelectedItems}
              placeholder={errors && (errors.groups || errors.recipients) ?
                __('Search and add at least one recipient') :
                __('Search for a group or profile')
              }
              hideSearch={this.isMobile()}
              organization={this.props.organization}
              entity={this.props.entity}
              recipients={this.props.recipients}
              enableAddRecipients={this.enableAddRecipients}
              style={{ border: 'none', boxShadow: 'none' }}
              wrapType
              round
            />
          </div>
          {
            this.isMobile() &&
            <div>
              <BetaButton
                icon={{ name: 'plus', margin: 0 }}
                style={{ width: '24px', height: '24px' }}
                blue
                noMarginIcon
                data-action="open-entity-recipient-form"
                onClick={() => null}
                round
              />
            </div>
          }
          {
            warningMessage &&
            <div style={{ marginLeft: '0.5rem' }}>
              <Popup
                style={{ textAlign: 'center' }}
                trigger={<Icon style={{ margin: 'auto' }} name="exclamation triangle" color="red" />}
                content={warningMessage}
              />
            </div>
          }
        </div>
        {!!recipientError &&
          <div className="error-warning-red">
            <Icon className="circle exclamation" />
            <span>{recipientError}</span>
          </div>
        }
      </Form.Field>
    );
  }


  renderTags = () => this.props.organization && this.props.organization.tags && this.props.organization.tags.map((tag, i) =>
    <ColoredCheckbox
      data-params={tag.id}
      data-action="select-tag"
      className={`message-tag ${this.isMobile() ? 'mobile' : ''}`}
      key={i}
      radio
      bold={false}
      color="#084FFF"
      radioChecked
      background
      label={this.isMobile() ? utils.renderLongTextRaw(tag.name, 18) : tag.name}
      name={tag.name}
      value={tag.id}
      checked={!!this.state.values.tags.find(id => id == tag.id)}
      onClick={() => this.toggleValue('tags', tag.id)}
    />
  )

  renderMediaPreview = media => this.props.store.appends.push(<Modal
    size="small"
    closeIcon="times close"
    onClose={() => this.props.store.appends.pop()}
  >
    <Modal.Content image>
      <Container textAlign="center">
        {[
          media.type.includes('image') &&
          <Image key="image" src={media.preview} wrapped />,

          utils.isAudioFile(media.type, media.name) &&
          <AudioPlayer key="audio" url={media.response.uri} name={media.name} />,

          utils.isVideoFile(media.type, media.name) &&
          <div key="video" className="clsp-video" style={{ height: 320 }}>
            <VideoPlayer src={media.response.uri} name={media.name} thumbnail={media.thumbnail} />
          </div>
        ]}
      </Container>
    </Modal.Content>
  </Modal>);

  renderMedias = () => this.state.values.medias.map((media, key) => (
    <AttachmentPreview
      media={media}
      onDelete={() => this.popValue('medias', key)}
      onPreview={() => this.renderMediaPreview(media)}
      isMessageToApprove = {this.props.isMessageToApprove}
    />
  ));

  renderErrorForEditAttachments = (method = 'update') => {
    this.props.store.snackbar = {
      active: true,
      message: method === 'update' ? __('You can\'t edit attachments that were created before this message edit') : __('You can\'t delete this type of attachments if they were created before this message edit'),
      success: false,
      dismissAfter: 5000
    };
  };

  renderAttachment = (type) => {
    const attachment = this.attachments[type];
    const items = this.state.values[attachment.plural];
    const { shouldSkipDraft } = this.props;
    return items && items.map((item, i) => {
      const isMaster = this.props.store.currentUser.isMaster;
      const canEdit = !shouldSkipDraft || (item && !item.created) || isMaster;
      const canDelete = !shouldSkipDraft || attachment.options.canDeleteOnEdit
        || (item && !item.created) || isMaster;
      return (
        <div style={{ float: 'left', padding: 0, width: this.isMobile() ? '100%' : '' }}>
          <attachment.item
            key={i}
            id={i}
            edit
            onEdit={canEdit ? () => this.openAttachmentForm(type, i) : () => this.renderErrorForEditAttachments()}
            onDelete={canDelete ? () => this.popValue(attachment.plural, i) : () => this.renderErrorForEditAttachments('delete')}
            isMessageToApprove = {this.props.isMessageToApprove}
            {...{ [type]: item }} // eg: commitment={item}
          />
        </div>
      );
    });
  }

  renderMediaItem = (props, onToast, justIcon) => {
    const { store, consultantForm, edit } = this.props;
    const attachmentsCount = sum(['medias', 'surveys', 'commitments', 'reports', 'charges', 'forms'].map(attachmentType => this.state.values[attachmentType].length));

    const __shouldShowButton = shouldShowButton({
      organization: store?.currentOrganization,
      entity: store?.currentEntity,
      isEditPage: edit,
    });

    const style = getMediaButtonStyles({
      isMobile: this.isMobile(),
      shouldShowButton: __shouldShowButton,
      hasPersonalizedFilesAdded: this.state.hasPersonalizedFilesAdded,
    });

    return (
      <Popup
        disabled={!this.state.hasPersonalizedFilesAdded}
        trigger={
          <div>
            <Button
              as={Dropzone}
              ref={(node) => { this.dropzone = node; }}
              onDrop={(accepted, rejected) => {
                if (onToast) this.props.store.appends.pop();
                if (attachmentsCount + 1 > ATTACHMENT_LIMIT) {
                  store.snackbar = {
                    active: true,
                    message: __('You can not have more than %s attachments in a message, all files will be ignored', ATTACHMENT_LIMIT),
                    success: false,
                    dismissAfter: 4000
                  };
                  return;
                }
                this.onFileDrop(accepted, rejected);
              }}
              onFileDialogCancel={() => onToast && this.props.store.appends.pop()}
              multiple
              id="attach-file"
              maxSize={ATTACHMENT_SIZE_MB * BYTES_IN_ONE_MB}
              className={`hover${(onToast ? ' no-border margin-icons increase-font-size ' : (justIcon ? ' no-border padding-icon ' : (consultantForm ? ' no-border ' : ' round ')))}black-content padding-center`}
              basic
              {...props}
              icon={{ name: 'paperclip', style: { margin: '0px', color: '#000000', transform: 'rotate(45deg)' } }}
              style={style}
              disabled={this.state.hasPersonalizedFilesAdded}
            >
              {!justIcon ?
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <i ariaHidden className={`${onToast ? 'file' : 'paperclip'} icon`} style={{ fontSize: '0.875rem' }} />
                  <span>{__('File')}</span>
                </div>
                :
                null
              }
            </Button>
          </div>
        }
        position="top center"
        content={__('Cannot combine personalized files with other types of attachments')}
      />
    );
  }

  renderSendLater = (lang, disabled, disabledStyle, values = []) => {
    const { organization, store, disableSave } = this.props;
    if (!utils.canSubmitMessageWithRecipients(values, organization) && !(utils.isEntityType(store, 'STUDENT'))) {
      const nextDayAvailable = utils.roundDate(new Date(today.getFullYear(), today.getMonth() + 1, 1, 12, 30));

      if (this.isMobile()) {
        return (
          <Popup
            disabled={!this.state.hasPersonalizedFilesAdded}
            trigger={
              <div style={{ paddingTop: '4px' }}>
                <Button
                  id="SendLaterButtonMessage"
                  className={'padding-icon'}
                  data-action="cancel"
                  type="button"
                  floated="left"
                  basic
                  disabled={disableSave || disabled || this.state.hasPersonalizedFilesAdded}
                  content={null}
                  icon={{ name: 'clock', style: { margin: '0px', color: '#000000' } }}
                  style={{ boxShadow: 'none', opacity: !disabled && disabledStyle ? '.45' : '1' }}
                  onClick={() => this.checkErrorsBeforeSubmit(() => this.props.store.appends.push(<BetaModal
                    toast
                    id={'SendLaterToast'}
                    header={__('Send later')}
                    closeOnRootNodeClick
                    invertCloseButton
                    scrolling
                    onClose={() => this.props.store.appends.pop()}
                    content={
                      <div className="send-later" style={{ display: 'flex', flexDirection: 'column', marginBottom: '12px' }}>
                        <Button
                          onClick={(e) => {
                            this.checkErrorsBeforeSubmit(() => this.setValue('sendAt', nextDayAvailable, () => this.handleSubmit(e)));
                            this.props.store.appends.pop();
                          }}
                        >
                          <span style={{ fontSize: '16px' }}>
                            <Icon name="hourglass start" />
                            {__('Next available date')}
                          </span>
                          <span className="date">
                            {utils.simpleDate(nextDayAvailable, true, 'ddd, DD MMM, LT', lang)}
                          </span>
                        </Button>
                        <Button
                          onClick={() => this.checkErrorsBeforeSubmit(() => this.openDatePicker(nextDayAvailable))}
                        >
                          <span style={{ fontSize: '16px' }}>
                            <Icon name="calendar" />
                            {__('Pick Date & Time')}
                          </span>
                          <Icon name="angle right" />
                        </Button>
                      </div>
                    }
                  />))}
                />
              </div>
            }
            position="top center"
            content={__('Cannot combine personalized files with other types of attachments')}
          />
        )
      }

      return (
        <Popup
          disabled={!this.state.hasPersonalizedFilesAdded}
          trigger={
            <div>
              <Dropdown
                disabled={disableSave || disabled || this.state.hasPersonalizedFilesAdded}
                upward
                trigger={
                  <BetaButton
                    id="SendLaterButtonMessage" round transparent text={__('Send later')} frontIcon={{ name: 'angle down', color: '#000000' }} style={{ marginLeft: '1em', marginRight: '0.4em', padding: '0.9rem 1.143rem', fontSize: '16px', opacity: !disabled && disabledStyle ? '.45' : '1' }}
                  />
                }
                icon={null}
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                    onClick={e => this.checkErrorsBeforeSubmit(() => this.setValue('sendAt', nextDayAvailable, () => this.handleSubmit(e)))}
                  >
                    <span>{__('Next available date')}</span>
                    <span style={{ marginLeft: '12px', color: '#a0a0a0' }}>{utils.simpleDate(nextDayAvailable, true, 'ddd, DD MMM, LT', lang)}</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                    onClick={() => this.checkErrorsBeforeSubmit(() => this.openDatePicker(nextDayAvailable))}
                  >
                    {__('Pick Date & Time')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          }
          position="top center"
          content={__('Cannot combine personalized files with other types of attachments')}
        />
      );
    }

    const nextDay = utils.roundDate(new Date(today.getTime() + 86400000));
    const nextWeek = utils.roundDate(new Date(today.getTime() + (7 * 86400000)));

    if (this.isMobile()) {
      return (
        <Popup
          disabled={!this.state.hasPersonalizedFilesAdded}
          trigger={
            <div style={{ paddingTop: '4px' }}>
              <Button
                id="SendLaterButtonMessage"
                className={'padding-icon'}
                data-action="cancel"
                type="button"
                floated="left"
                basic
                disabled={disableSave || disabled || this.state.hasPersonalizedFilesAdded}
                content={null}
                icon={{ name: 'clock', style: { margin: '0px', color: '#000000' } }}
                style={{ boxShadow: 'none', opacity: !disabled && disabledStyle ? '.45' : '1' }}
                onClick={() => this.checkErrorsBeforeSubmit(() => this.props.store.appends.push(<BetaModal
                  toast
                  id={'SendLaterToast'}
                  header={__('Send later')}
                  closeOnRootNodeClick
                  invertCloseButton
                  scrolling
                  onClose={() => this.props.store.appends.pop()}
                  content={
                    <div className="send-later" style={{ display: 'flex', flexDirection: 'column', marginBottom: '12px' }}>

                      <Button
                        onClick={(e) => {
                          this.setValue('sendAt', nextDay, () => this.handleSubmit(e));
                          this.props.store.appends.pop();
                        }}
                        style={{ height: 'fit-content' }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon name="hourglass" />
                          </div>
                          <div style={{ textAlign: 'left' }} >
                            <span style={{ fontSize: '16px' }}>
                              {__('Tomorrow')}
                            </span>
                          </div>
                        </div>
                        <div>
                          <span className="date">{utils.simpleDate(nextDay, true, 'ddd, DD MMM, LT', lang)}</span>
                        </div>
                      </Button>

                      <Button
                        onClick={(e) => {
                          this.setValue('sendAt', nextWeek, () => this.handleSubmit(e));
                          this.props.store.appends.pop();
                        }}
                        style={{ height: 'fit-content' }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon name="hourglass" />
                          </div>
                          <div style={{ textAlign: 'left' }} >
                            <span style={{ fontSize: '16px' }}>
                              {__('Next Week')}
                            </span>
                          </div>
                        </div>
                        <div>
                          <span className="date">{utils.simpleDate(nextWeek, true, 'ddd, DD MMM, LT', lang)}</span>
                        </div>
                      </Button>

                      <Button
                        onClick={() => this.openDatePicker()}
                      >
                        <span style={{ fontSize: '16px' }}>
                          <Icon name="calendar" />
                          {__('Pick Date & Time')}
                        </span>
                        <Icon name="angle right" />
                      </Button>
                    </div>
                  }
                />))}
              />
            </div>
          }
          position="top center"
          content={__('Cannot combine personalized files with other types of attachments')}
        />
      )
    }

    return (
      <Popup
        disabled={!this.state.hasPersonalizedFilesAdded}
        trigger={
          <div>
            <Dropdown
                disabled={disableSave || disabled || this.state.hasPersonalizedFilesAdded}
                upward
                trigger={
                  <BetaButton
                    id="SendLaterButtonMessage"
                    round
                    transparent
                    text={__('Send later')}
                    frontIcon={{ name: 'angle down', color: '#000000' }}
                    style={{
                      marginLeft: '1em',
                      marginRight: '0.4em',
                      padding: '0.9rem 1.143rem',
                      fontSize: '16px',
                      opacity: !disabled && disabledStyle ? '.45' : '1'
                    }}
                  />
                }
                icon={null}
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                    onClick={e => this.checkErrorsBeforeSubmit(() => this.setValue('sendAt', nextDay, () => this.handleSubmit(e)))}
                  >
                    <span>{__('Next Day')}</span>
                    <span style={{ marginLeft: '12px', color: '#a0a0a0' }}>{utils.simpleDate(nextDay, true, 'ddd, DD MMM, LT', lang)}</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                    onClick={e => this.checkErrorsBeforeSubmit(() => this.setValue('sendAt', nextWeek, () => this.handleSubmit(e)))}
                  >
                    <span>{__('Next Week')}</span>
                    <span style={{ marginLeft: '12px', color: '#a0a0a0' }}>{utils.simpleDate(nextWeek, true, 'ddd, DD MMM, LT', lang)}</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                    onClick={() => this.checkErrorsBeforeSubmit(() => this.openDatePicker())}

                  >
                    {__('Pick Date & Time')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
        }
        position="top center"
        content={__('Cannot combine personalized files with other types of attachments')}
      />
    )
  }

  handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      document.execCommand('insertHTML', false, '&#009');
    }
  }

  renderStudentForm = () => {
    const { values, errors, messageError } = this.state;
    let { organization, store, shouldSkipDraft, edit } = this.props;
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';

    return (
      <Form style={{ display: 'flex', flexDirection: 'column' }} {...this.handleProps()} id="messageForm" onSubmit={e => (shouldSkipDraft ? this.showNotificationOptionsModal() : this.handleSubmit(e))} onCancel={() => this.setState({ values: { ...values, onCancel: true } }, () => this.handleCancel())}>
        <div style={{ overflowY: this.isMobile() ? 'unset' : 'scroll', maxHeight: 'calc(58vh)' }} ref={self => self && this.scrollOnAttachment.add(self)}>
          {
            !shouldSkipDraft ?
              <div style={{ ...this.styles.row, padding: '8px 24px' }}>
                {this.renderOneRecipient()}
              </div>
              :
              null
          }
          {
            values.sendAt ?
              <Segment inverted className="bold" style={{ backgroundColor: '#FFAE00', borderRadius: '8px' }}>
                <Icon name="clock" />
                {__('Sending on: %s', utils.simpleDate(values.sendAt, true, 'dddd DD MMM, LT', lang))}
                <Icon name="times" className="pointer" style={{ float: 'right' }} onClick={() => this.setValue('sendAt', null)} />
              </Segment>
              :
              null
          }
          <div title={__('Write message')}>
            {this.isMobile() && !edit ?
              <Form.FormTextArea
                title={__('Write message')}
                error={errors && errors.medias && !((values.content && values.content.length) || values.medias.length)}
                placeholder={errors && errors.medias ? __("You can't send an empty message") : __('Your message here...')}
                name="content"
                value={values.content}
                onChange={this.onInputChange}
                onKeyDown={this.handleKeyDown}
                autoHeight
                style={{
                  border: 0,
                  padding: this.isMobile() ? '12px 12px' : '14px 24px',
                  minHeight: 200,
                  fontSize: '1.143rem',
                  whiteSpace: 'pre-wrap',
                }}
              />
              :
              <QuillEditor
                title={__('Write message')}
                hideToolbar
                placeholder={errors && errors.medias ? __("You can't send an empty message") : __('Your message here...')}
                contentState={values.content}
                onChange={content => this.setValue('content', content)}
                style={{ minHeight: '23em' }}
              />
            }
          </div>
          {
            this.state.values.medias.length ?
              <Grid container columns={5} style={{ padding: '14px 17px', margin: 0 }}>
                {this.renderMedias()}
              </Grid>
              :
              null
          }
          {!!messageError &&
            <div ref={(el) => { this.myRef = el; }} className="error-warning-red" style={{ marginBottom: '8px', marginTop: '0px', paddingLeft: this.isMobile() ? '12px' : '24px' }}>
              <Icon className="circle exclamation" />
              <span>{messageError}</span>
            </div>
          }
        </div>
        {
          !!organization.confMediaStudent && !!organization.confMedia && organization.features.files && !this.isMobile() &&
          <Form.Field style={{ borderTop: '1px solid #f1f3f5', marginTop: 'auto' }}>
            <div style={{ margin: '14px 17px' }}>
              {this.renderMediaItem()}
            </div>
          </Form.Field>
        }
      </Form>
    );
  }

  renderStaffAttachments = (onToast) => {
    let { organization, store, edit } = this.props;
    const { values } = this.state;

    if (!((!!organization.confMedia && organization.features.files) ||
      Object.keys(this.attachments).filter(type => organization['conf' + type.replace(/\b\w/g, l => l.toUpperCase())] && organization.features[this.attachments[type].plural]).length)
    ) return null;

    const __shouldShowButton = shouldShowButton({
      organization: store?.currentOrganization,
      entity: store?.currentEntity,
      isEditPage: edit,
    });

    let styles = getAttachmentButtonStyles({
      shouldShowButton: __shouldShowButton,
      hasPersonalizedFilesAdded: this.state.hasPersonalizedFilesAdded,
    });

    return ([
      !!organization.confMedia && organization.features.files && this.renderMediaItem(undefined, onToast),
      __shouldShowButton &&
        <PersonalizedFile
          env={store?.app?.env}
          onOpenRecipientModal={() => { this.openEntityRecipientForm(false, true); }}
          hasAttachments={hasAttachments({ values: this.state.values })}
          recipients={getRecipients(this.state.values.groups, this.state.values.recipients)}
          openPersonalizedMediaModal={hasCustomFileRecipientsModal(store?.currentOrganization) && this.state.openPersonalizedMediaModal}
          closePersonalizedMediaModal={() => this.setState({ openPersonalizedMediaModal: false })}
          organizationId={store?.currentOrganization?.id}
          entityId={store?.currentEntity?.id}
          accessToken={store?.access_token}
          onSubmitFilesModal={({ folderName, totalFilesSize, totalFileQuantity }) => {
            this.setValue('folderName', folderName);
            this.setState({
              totalFilesSize,
              totalFileQuantity,
            });
          }}
        />,
      Object.keys(this.attachments).map((type, i) => {

        if (!organization['conf' + type.replace(/\b\w/g, l => l.toUpperCase())]
        || isNetwork(store?.currentOrganization)) return null;

        if (!organization.features[this.attachments[type].plural] && type !== 'charge') {
          const feature = this.attachments[type].plural === 'forms' ? 'FORMS' : 'REPORTS';

          return (
            <Popup
              disabled={!this.state.hasPersonalizedFilesAdded}
              trigger={
                <div>
                  <Button
                    className={`${onToast ? 'no-border margin-icons increase-font-size ' : 'round '}black-content padding-center`}
                    data-action="open-attachment-form"
                    data-params={type}
                    style={{ ...styles.button }}
                    key={i}
                    onClick={(e) => { onToast && this.props.store.appends.pop(); e.target.blur(); this.openPaywall(feature); }}
                    basic
                    disabled={this.state.hasPersonalizedFilesAdded}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Icon name={this.attachments[type].icon} />
                      <span>{this.attachments[type].title}</span>
                      <Icon name="stars" style={{ marginLeft: onToast ? '16px' : '3px', color: '#A5A5A5', ...styles.icon }} />
                    </div>
                  </Button>
                  </div>
                }
              position="top center"
              content={__('Cannot combine personalized files with other types of attachments')}
            />
          );
        }

        if (!organization.confCharge && type === 'charge') return null;

        return (
          <Popup
              disabled={!this.state.hasPersonalizedFilesAdded}
              trigger={
                <div>
                  <Button
                    className={`${onToast ? 'no-border margin-icons increase-font-size ' : 'round '}black-content padding-center`}
                    data-action="open-attachment-form"
                    data-params={type}
                    key={i}
                    style={{ ...styles.button }}
                    onClick={(e) => { onToast && this.props.store.appends.pop(); e.target.blur(); this.openAttachmentForm(type); }}
                    basic
                    disabled={this.state.hasPersonalizedFilesAdded}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon style={styles.icon} name={this.attachments[type].icon} />
                      <span>{this.attachments[type].title}</span>
                    </div>
                  </Button>
                </div>
              }
              position="top center"
              content={__('Cannot combine personalized files with other types of attachments')}
            />
        )
      })
    ]);
  }

  openPaywall(feature) {
    this.props.store.appends.push(<FeaturesModal feature={feature} />);
  }

  renderFeatureLabel = () => {
    const { values } = this.state;
    let { organization } = this.props;

    const isConsultant = !organization.features && !organization.labels;

    if (isConsultant) return null;

    const hasLabels = organization && organization.labels && organization.labels.length > 0;
    const hasFeatures = organization && organization.features;

    if (hasFeatures && hasFeatures.labels && !hasLabels) return null;

    if (hasFeatures && !hasFeatures.labels) {
      return (
        <Button
          size="mini"
          className="message-label empty"
          title={__('Add label')}
          style={{ margin: 'auto' }}
          onClick={() => this.openPaywall('LABELS')}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>{this.isMobile() ? __('Label') : __('Add label')}</span>
            <Icon name="stars" style={{ marginLeft: '5px', color: '#A5A5A5' }} />
          </div>
        </Button>
      );
    }

    return (
      values.labelId && organization.labels.find(label => label.id === values.labelId) ?
        <Button
          size="mini"
          className="message-label"
          // icon="times close"
          title={__('Add label')}
          style={{ color: 'white', backgroundColor: `#${organization.labels.find(label => label.id === values.labelId).color}` }}
          content={organization.labels.find(label => label.id === values.labelId).title}
          onClick={this.openLabelForm}
        />
        :
        <Button
          size="mini"
          title={__('Add label')}
          className="message-label empty"
          content={this.isMobile() ? __('Label') : __('Add label')}
          onClick={this.openLabelForm}
        />
    );
  }

  renderStaffForm = () => {
    const { values, errors, messageError } = this.state;
    let { data, shouldSkipDraft, edit, store } = this.props;
    const hasAttachments = values?.surveys?.length || values?.commitments?.length || values?.reports?.length || values?.medias?.length || values?.charges?.length || values?.forms?.length || values?.signatures?.length;
    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);

    return (
      <Form
        {...this.handleProps()} id="MessageForm" onSubmit={e => (shouldSkipDraft && !isNetwork(store.currentOrganization) ? this.showNotificationOptionsModal() : this.handleSubmit(e))} onCancel={() => this.setState({ values: { ...values, onCancel: true } }, () => this.handleCancel())}
      >
        <div style={{ overflowY: this.isMobile() ? 'unset' : 'scroll', maxHeight: 'calc(58vh)' }} ref={self => self && this.scrollOnAttachment.add(self)}>
          {
            shouldSkipDraft ?
              null
              :
              <div style={{ ...this.styles.row, padding: this.isMobile() ? '5px 12px' : '5px 24px' }}>
                {this.renderManyRecipients()}
              </div>
          }
          {
            shouldSkipDraft ?
              null
              :
              <div title={this.props.organization && this.props.organization.tags && this.props.organization && this.props.organization.tags.length > 0 ? __('Tags') : ''} className={`message-tags ${this.isMobile() ? 'mobile' : ''}`} style={{ ...this.styles.row, padding: this.isMobile() ? '8px 12px 0' : '8px 24px 0', display: '-webkit-inline-box', }}>
                {this.renderTags()}
              </div>
          }
          {
            values.sendAt ?
              <Segment inverted className="bold" style={{ backgroundColor: '#FFAE00', margin: 0, borderRadius: '8px' }}>
                <Icon name="clock" />
                {__('Sending on: %s', utils.simpleDate(values.sendAt, true, 'dddd DD MMM, LT', lang))}
                <Icon name="times" className="pointer" style={{ float: 'right' }} onClick={() => this.setValue('sendAt', null)} />
              </Segment>
              :
              null
          }

          {
            !values.userAuthorHasEntity &&
            <Segment style={{ backgroundColor: '#fff', color: '#f39c12', margin: 0, marginTop: '5px', border: 'none' }}>
              <Icon name="warning" />
              {__('The user: %s, author of the message, has been unlinked from the profile: %s', values.user.fullname, values.entity.fullname)}
              <a
                href="https://ajuda.classapp.com.br/hc/pt-br/articles/4410507195803"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'inherit', float: 'right' }}
              >
                <Icon name="question circle outline" size="normal" />
              </a>
            </Segment>
          }
          <Form.FormInput
            title={__('Subject')}
            className={`noMargin subject ${this.isMobile() ? 'mobile' : ''}`}
            style={{ borderBottom: this.isMobile() ? '1px solid #f1f3f5' : null }}
            autoComplete={this.isMobile() ? 'off' : 'on'}
            placeholder={__('Subject')}
            name="subject"
            value={values.subject}
            onChange={this.onInputChange}
          >
            <input style={{ padding: this.isMobile() ? '12px' : '24px', display: 'flex', width: '100%', border: 0, fontSize: '1.3em' }} />
            <div style={{ marginTop: '12px', marginBottom: '12px', marginRight: this.isMobile() ? '12px' : '24px', display: 'flex' }}>
              {
                this.renderFeatureLabel()
              }
            </div>
          </Form.FormInput>
          <div
            className={(errors && errors.medias && this.validateContent(values) ? 'errored' : '')}
            style={{ marginBottom: (values.fwMessageId !== null && (data && data.node)) ? '70px' : '48px', userSelect: 'auto' }}
          >
            <div title={__('Write message')}>
              {(this.isMobile() && !edit) ?
                <Form.FormTextArea
                  error={errors && errors.medias && !((values.content && values.content.length) || values.medias.length)}
                  placeholder={errors && errors.medias ? __("You can't send an empty message") : __('Your message here...')}
                  name="content"
                  value={values.content}
                  onChange={this.onInputChange}
                  onKeyDown={this.handleKeyDown}
                  autoHeight
                  style={{
                    border: 0,
                    padding: '12px 12px',
                    minHeight: 200,
                    fontSize: '1.143rem',
                    whiteSpace: 'pre-wrap',
                  }}
                />
                :
                <QuillEditor
                  placeholder={errors && errors.medias ? __("You can't send an empty message") : __('Your message here...')}
                  contentState={values.content}
                  onChange={content => this.setValue('content', content)}
                  style={{ minHeight: '14em' }}
                  hideToolbar={this.isMobile()}
                />
              }
            </div>


            {
              values.fwMessageId !== null && (data && data.node) &&
              <Segment className="forwardMessage" style={{ padding: '14px 24px', marginLeft: '15px', marginRight: '15px' }}>
                <span data-action="delete" style={{ float: 'right', marginLeft: '15px', marginTop: '15px' }} onClick={() => this.setState({ values: { ...values, fwMessageId: null } })}>
                  <Icon name="trash" style={{ cursor: 'pointer' }} />
                </span>
                <MessageItem message={data.node} />
              </Segment>
            }
            {
              hasAttachments ?
                <Grid columns={store?.message?.hasCustomMedia ? 1 : 5} style={{ padding: '12px 24px 24px 24px', margin: 0 }}>
                  {store?.message?.hasCustomMedia ?
                    <AttachmentFile env={store?.app?.env} folderName={message.folderName} />
                    :
                    this.state.values.medias.length ? this.renderMedias() : null
                  }
                  <span style={{ padding: 0, width: '100%' }}>{Object.keys(this.attachments).length ? Object.keys(this.attachments).map(type => this.renderAttachment(type)) : null}</span>
                </Grid>
                :
                null
            }
          </div>
          {!!messageError &&
            <div ref={(el) => { this.myRef = el; }} className="error-warning-red" style={{ marginBottom: this.isMobile() ? '8px' : '69px', marginTop: '0px', paddingLeft: this.isMobile() ? '12px' : '24px' }}>
              <Icon className="circle exclamation" />
              <span>{messageError}</span>
            </div>
          }
        </div>
        {!this.isMobile() &&
          <Form.Field /* style={{ borderTop: '1px solid #f1f3f5' }} */>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              margin: '14px 17px',
              justifyContent: 'flex-start'
            }}>
              {this.renderStaffAttachments(false)}
            </div>
          </Form.Field>
        }

      </Form>
    );
  }

  hasModifications() {
    const { message } = this.props;
    const {
      values
    } = this.state;

    return (
      !isEqual(values.subject, ((message && message.subject) || '')) ||
      !isEqual(values.content || '', ((message && message.content) || '')) ||
      !isEqual(values.commitments, ((message && message.commitments && message.commitments.nodes) || [])) ||
      !isEqual(values.surveys, ((message && message.surveys && message.surveys.nodes) || [])) ||
      !isEqual(values.reports, ((message && message.reports && message.reports.nodes) || [])) ||
      !isEqual(values.charges, ((message && message.charges && message.charges.nodes) || [])) ||
      !isEqual(values.forms, ((message && message.forms && message.forms.nodes) || [])) ||
      !isEqual(values && values.medias && values.medias.map(m => (m.uniqueId ? `uid:${m.uniqueId}` : `name:${m.name}`)), message && message.medias && message.medias.nodes && message.medias.nodes.map(m => `name:${m.origName}`)) ||
      !isEqual(values.fwMessageId, message && message.fwMessageId) ||
      !isEqual(values.labelId, message && message.labelId) ||
      !isEqual(values.pin, message && message.pin) ||
      !isEqual(values.public, message && message.public) ||
      !isEqual(values.noReply, message && message.noReply)
    );
  }

  renderShouldNotifyContent = () => (
    <div className="options-toast" style={{ display: 'flex', flexDirection: 'column', marginBottom: '12px' }}>
      <span style={{ fontSize: '1.143rem', fontWeight: 400, paddingLeft: '10px', marginBottom: '14px' }}>{__('Do you want to notify these changes?')}</span>
      {
        [
          <Button
            data-action="submit"
            key={0}
            onClick={(e) => { this.props.shouldNotifyUpdate(); this.handleSubmit(e); this.props.store.appends.pop(); }}
          >
            <span style={{ display: 'flex', fontSize: '16px', color: '#084FFF' }}>
              <Icon name="bell on" style={{ marginRight: '16px' }} />
              {__('Notify')}
            </span>
          </Button>,
          <Button
            data-action="submit"
            key={1}
            onClick={(e) => { this.props.shouldNotNotifyUpdate(); this.handleSubmit(e); this.props.store.appends.pop(); }}
          >
            <span style={{ display: 'flex', fontSize: '16px', color: '#000000' }}>
              <Icon name="bell slash" style={{ marginRight: '16px' }} />
              {__('Don\'t notify')}
            </span>
          </Button>,
          <Button
            data-action="submit"
            key={2}
            onClick={() => this.props.store.appends.pop()}
          >
            <span style={{ display: 'flex', fontSize: '16px', color: '#000000' }}>
              <Icon name="times" style={{ marginRight: '16px' }} />
              {__('Cancel')}
            </span>
          </Button>
        ]
      }
    </div>
  )

  showNotificationOptionsModal() {
    return this.props.store.appends.push(<BetaModal
      id="ShouldShowNotificationOptions"
      toast={this.isMobile()}
      invertCloseButton={this.isMobile()}
      onClose={() => this.props.store.appends.pop()}
      closeOnRootNodeClick={false}
      header={this.isMobile() ? __('Send notification') : __('Send notification?')}
      spaceFooter

      content={this.isMobile() ?
        this.renderShouldNotifyContent() : <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>{__('Do you want to notify these changes?')}</span>}
      actions={this.isMobile() ? null : [
        <BetaButton
          data-action="cancel"
          key={0}
          round
          transparent
          floated="left"
          text={__('Cancel')}
          onClick={() => this.props.store.appends.pop()}
        />,
        <div>
          <BetaButton
            data-action="noNotify"
            key={1}
            blue
            round
            style={{ marginRight: '12px' }}
            text={__('Don\'t notify')}
            onClick={(e) => { this.props.shouldNotNotifyUpdate(); this.handleSubmit(e); this.props.store.appends.pop(); }}
          />
          <BetaButton
            data-action="notify"
            key={2}
            round
            icon={{ name: 'check' }}
            text={__('Notify')}
            onClick={(e) => { this.props.shouldNotifyUpdate(); this.handleSubmit(e); this.props.store.appends.pop(); }}
          />
        </div>
      ]}
    />);
  }

  renderAllAttachments = onToast => ([
    this.renderMediaItem({
      consultantForm: this.props.consultantForm,
    }, onToast),
    Object.keys(this.attachments)?.filter(type => !['report', 'form'].includes(type)).map((type, i) => {
      if (this.props.consultantForm && type === 'signature') return null;
      return (
        <Button
          className={`${onToast ? 'no-border margin-icons increase-font-size ' : 'no-border '}black-content padding-center`}
          data-action="open-attachment-form"
          data-params={type}
          key={i}
          onClick={() => this.openAttachmentForm(type)}
          basic
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Icon name={this.attachments[type].icon} />
            <span>{this.attachments[type].title}</span>
          </div>
        </Button>);
    })
  ])

  cleanSelected = () => {
    const { recipientFormData } = this.state;

    if (recipientFormData) {
      recipientFormData.updateQuery((previousResult) => {
        const nextResult = cloneDeep(previousResult);
        nextResult.nodes.forEach((org) => {
          org.selected = false;
          org.entities.nodes.forEach((ent) => {
            ent.selected = false;
          });
        });
        return nextResult;
      });
    }
  }

  scrollOnAttachment = new Set();

  scrollToBottom = () => {
    setTimeout(() => {
      [...this.scrollOnAttachment].forEach((element) => { element.scrollTop = element.scrollHeight; });
    }, 50);
  }

  renderConsultantForm = () => {
    const { values, errors, messageError, recipientError } = this.state;

    let { data, edit, store } = this.props;
    const hasAttachments = values.surveys.length || values.commitments.length || values.reports.length || values.medias.length || values.forms.length;
    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);
    return (
      <Form
        {...this.handleProps()}
        id="MessageForm"
        onSubmit={(values) => {
          this.handleSubmit(values);
          this.cleanSelected();
        }}
        onCancel={() => this.setState({ values: { ...values, onCancel: true } },
          () => {
            this.cleanSelected();
            this.handleCancel();
          }
        )}
      >
        <div style={{ overflowY: this.isMobile() ? 'unset' : 'scroll', maxHeight: 'calc(70vh)' }} ref={self => self && this.scrollOnAttachment.add(self)}>
          <div style={{ ...this.styles.row, padding: '5px 24px' }}>
            <Form.Field>
              <Input type="text">
                <div style={{ width: '156px' }}>
                  <BetaButton
                    text={__('Recipients')}
                    icon={{ name: 'plus' }}
                    onKeyDown={e => this.setState({ consultantRecipientButtonKeyDown: true })}
                    style={{ display: 'flex', flexWrap: 'nowrap', padding: '0.9rem 1.143rem' }}
                    blue
                    data-action="open-entity-recipient-form"
                    onClick={() => this.openConsultantRecipientForm()}
                    round
                  />
                </div>
                <Dropdown
                  open={false}
                  fluid multiple scrolling selection
                  onClick={() => this.openConsultantRecipientForm()}
                  onLabelClick={() => this.openConsultantRecipientForm()}
                  icon={null}
                  style={{ border: 'none', boxShadow: 'none' }}
                  options={values.recipients.map(recipient => ({
                    text: recipient.fullname,
                    value: recipient.id,
                    content: <EntityItem isMobile={this.isMobile()} id={recipient.id} indicator="typeLabel" entity={recipient} />,
                    recipient
                  }))}
                  value={values.recipients.map(recipient => recipient.id)}
                  renderLabel={(label, i) => {
                    if (i == 2) {
                      return (<Label
                        className="image round no-close-button"
                        size="tiny"
                        style={{ backgroundColor: '#fff' }}
                      >
                        {`+ ${values.recipients.length - 2}`}
                      </Label>);
                    } else if (i < 2) {
                      return (<Label
                        className="image round no-close-button"
                        size="tiny"
                        style={{ backgroundColor: '#fff' }}
                      >
                        <Avatar avatar spaced="right" src={label.recipient.picture && label.recipient.picture.uri} alt={label.recipient.fullname} />
                        {label.text}
                      </Label>);
                    }
                    return null;
                  }}
                />
              </Input>
            </Form.Field>
            {!!recipientError &&
              <div className="error-warning-red">
                <Icon className="circle exclamation" />
                <span>{recipientError}</span>
              </div>
            }
          </div>

          <Form.FormInput
            title={__('Subject')}
            className={`noMargin subject ${this.isMobile() ? 'mobile' : ''}`}
            style={{ borderBottom: this.isMobile() ? '1px solid #f1f3f5' : null }}
            placeholder={__('Subject')}
            name="subject"
            autoComplete={this.isMobile() ? 'off' : 'on'}
            value={values.subject}
            onChange={this.onInputChange}
          >
            <input style={{ padding: '14px 24px', border: 0, fontSize: '1.3em' }} />
          </Form.FormInput>
          <div
            title={__('Write message')}
            className={(errors && errors.medias && this.validateContent(values) ? 'errored' : '')}
            style={{ marginBottom: '68px', userSelect: 'auto' }}
          >
            <div title={__('Write message')}>
              {this.isMobile() && !edit ?
                <Form.FormTextArea
                  error={errors && errors.medias && !((values.content && values.content.length) || values.medias.length)}
                  placeholder={errors && errors.medias ? __("You can't send an empty message") : __('Your message here...')}
                  name="content"
                  value={values.content}
                  onChange={this.onInputChange}
                  onKeyDown={this.handleKeyDown}
                  autoHeight
                  style={{
                    border: 0,
                    padding: '12px 12px',
                    minHeight: 200,
                    fontSize: '1.143rem',
                    whiteSpace: 'pre-wrap',
                  }}
                />
                :
                <QuillEditor
                  placeholder={errors && errors.medias ? __("You can't send an empty message") : __('Your message here...')}
                  contentState={values.content}
                  onChange={content => this.setValue('content', content)}
                  style={{ minHeight: '14em' }}
                  toolbarStyle={{ bottom: '-12px' }}
                  hideToolbar={this.isMobile()}
                />
              }
            </div>
            {!!messageError &&
              <div ref={(el) => { this.myRef = el; }} className="error-warning-red" style={{ marginBottom: '8px', marginTop: '0px', paddingLeft: this.isMobile() ? '12px' : '24px' }}>
                <Icon className="circle exclamation" />
                <span>{messageError}</span>
              </div>
            }
            {
              values.fwMessageId !== null && (data && data.node) &&
              <Segment className="forwardMessage" style={{ padding: '14px 24px', marginLeft: '15px', marginRight: '15px' }}>
                <span data-action="delete" style={{ float: 'right', marginLeft: '15px', marginTop: '15px' }} onClick={() => this.setState({ values: { ...values, fwMessageId: null } })}>
                  <Icon name="trash" style={{ cursor: 'pointer' }} />
                </span>
                <MessageItem message={data.node} />
              </Segment>
            }
            {
              hasAttachments ?
                <Grid columns={store?.message?.hasCustomMedia ? 1 : 5} style={{ padding: '12px 24px 0px 24px', margin: 0 }}>
                  {store?.message?.hasCustomMedia ?
                    <AttachmentFile env={store?.app?.env} folderName={message.folderName} />
                    :
                    this.state.values.medias.length ? this.renderMedias() : null
                  }
                  <span style={{ padding: 0, width: '100%' }}>{Object.keys(this.attachments).length ? Object.keys(this.attachments).map(type => this.renderAttachment(type)) : null}</span>
                </Grid>
                :
                null
            }
          </div>
        </div>

      </Form>
    );
  }

  renderTopBar = () => {
    const { values } = this.state;
    const newProps = { ...this.props, modal: true };
    const { store } = newProps;
    const { organization } = this.props;
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';

    let disabled = false;
    let disabledStyle = false;

    if (newProps.shouldSkipDraft) {
      if ((values.medias.filter(media => media.loading).length) || (!this.hasModifications())) {
        if (!this.hasModifications()) {
          disabled = true;
        }
        disabledStyle = true;
      }
    } else if ((this.validateContent(values) || !(values.groups.length || values.recipients.length) || values.medias.filter(media => media.loading).length)) {
      disabledStyle = true;
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {newProps.entity.type === 'STUDENT' ? !!organization.confMediaStudent && !!organization.confMedia && organization.features.files && this.renderMediaItem(undefined, false, true) : this.renderFilesButton()}
        {!newProps.shouldSkipDraft && !this.props.consultantForm && newProps.id !== 'MessageToApproveEdit' && this.renderSendLater(lang, disabled, disabledStyle, values)}
        {(newProps.entity.type !== 'STUDENT' && !newProps.shouldSkipDraft && !this.props.consultantForm) && this.renderAdvancedOptionsButton()}
        {(newProps.submitButton && newProps.onSubmit) && this.renderSubmitButton()}
      </div>
    );
  }

  render() {
    const { data, entity, id, modalTitle, consultantForm, store, organization } = this.props;
    const { advancedOptionsToast } = this.state;
    if (data && ((data.loading && !data.node) || !data.node)) return <Modal loading />;
    const pathname = this.props.store.currentLocation.pathname;

    return (
      <BetaModal
        id={id}
        hasDivider={this.isMobile()}
        fullScreen={this.isMobile()}
        onClose={() => this.setState({ values: { ...this.state.values, onCancel: false } },
          () => {
            this.cleanSelected();
            this.handleCancel();
          })}
        size={null}
        heightSize={pathname.includes('/entities') && entity.type === 'STUDENT' && !this.isMobile() ? '45rem' : null}
        closeOnRootNodeClick={false}
        header={this.isMobile() ? '' : modalTitle}
        invertCloseButton={this.isMobile()}
        headerItem={this.isMobile() ? this.renderTopBar() : <EntityLabel entity={this.props.entity} />}
        consultantForm={consultantForm}
        content={
          <div style={{ backgroundColor: 'white', padding: 0, maxHeight: 'calc(80vh)' }}>
            <ExpiredLimitBanner
              user={store && store.currentUser}
              organization={organization}
              onPress={(e) => {
                e.preventDefault();
                openKnowMoreModal({ store });
              }}
            />
            {
              !consultantForm ? (
                entity.type === 'STUDENT' ? this.renderStudentForm() : this.renderStaffForm()
              ) : this.renderConsultantForm()
            }
            {
              advancedOptionsToast && this.renderAdvancedOptionsToast()
            }
          </div>
        }
        actions={!this.isMobile()}
      />
    );
  }
}

