import gql from 'graphql-tag';
import { Query } from '../../DataSource/ApolloDataSource';

/**
 * @param {{
 *  id: string
 * }} variables
 *
 * @returns {Promise<import("apollo-boost").QueryResult>}
 */
export function getOrganizationUnleashRepository(variables) {
  const query = gql`
    query WalleGetOrganizationUnleash($id: ID!) {
      node(id: $id) @connection(key: "Organization", filter: ["id"]) {
        ... on Organization {
          id: dbId
          unleashStatus {
            approve_message
            approve_reply
            feature_arquivos
            web_menu_conversations
            show_banner_menu_conversations
            menu_conversations_organization_direct_list
            menu_conversations_organization_dashboard
            network_communication
            show_banner_network_communication
            communication_status_feedback
            recipients_list_screen
            custom_media_button
            custom_media
            pagarme_v5_tokenize_credit_card
          }
        }
      }
    }
  `;

  return Query(query, variables);
}
