/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { HeaderEntity } from '../HeaderEntity';
import { render } from '../../../../../newRender';
import { resendCommunication } from '../../helpers/resendCommunication';

jest.mock('../../helpers/trackEvent.js', () => ({
  trackButtonClickEvent: jest.fn(),
}));

jest.mock('../../helpers/resendCommunication', () => ({
  resendCommunication: jest.fn(),
}));
describe('HeaderEntity', () => {
  beforeEach(() => {
    jest.clearAllMocks();
    resendCommunication.mockResolvedValue({
      data: {
        resendMessageNetwork: {
          success: true,
          error: null,
        },
      },
    });
  });

  it('should render the component', () => {
    const message = {
      toEntity: {
        picture: {
          uri: 'https://example.com/picture.jpg',
        },
      },
    };

    const { container } = render(<HeaderEntity message={message} />);
    expect(container).toBeDefined();
  });

  it('should render the component with error status', () => {
    const message = {
      toEntity: {
        picture: {
          uri: 'https://example.com/picture.jpg',
        },
      },
      statusSend: 'ERROR',
    };
    const { getByText } = render(<HeaderEntity message={message} />);
    expect(
      getByText('Failed to send: no recipients received.'),
    ).toBeInTheDocument();
  });
});
