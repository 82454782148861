import { sendGoogleAnalyticsEvent } from '../../../../lib/analytics';
import { sendEvent } from '../../../../lib/mixpanel';
import { TRACK_EVENT_SCOPE } from '../../../../lib/mixpanel/scopes';
import { TRACK_EVENT_PAGE_NAME } from '../../../../lib/mixpanel/pages';
import { TRACK_EVENT_NAME } from '../../../../lib/mixpanel';

export const sendApprovalMessageEvent = (category, store) => {
  const organizationId =
    store && store.currentOrganization && store.currentOrganization.id;

  sendGoogleAnalyticsEvent(
    {
      name: 'Approval Message',
      category,
      label: `OrganizationID: ${organizationId}`,
    },
    { store },
  );

  sendEvent({
    name: TRACK_EVENT_NAME.BUTTON_CLICKED,
    scope: TRACK_EVENT_SCOPE.CONVERSATIONS,
    pageName: TRACK_EVENT_PAGE_NAME.CONVERSATIONS,
    identifierName: 'encaminhar_mensagens',
    customProperties: {
      button_name: 'enviar_comunicado',
      modal_name: 'escrever_mensagem',
      selected_messages: 2,
      type: ['Arquivo', 'Relatório'],
      tags: ['Aluno', 'Responsável pedagógico'],
      labels: ['Importante'],
      scheduling: 'Sim',
      type_scheduling: 'Próxima semana',
      advanced_settings: 'Sim',
      advanced_settings_options: ['Importante', 'Pública'],
      quantity_recipients_selected: 5,
      quantity_users: 15,
      organization_id: organizationId,
    },
  });
};
