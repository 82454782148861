import React, { useEffect, useMemo, useState } from 'react';
import Banner from './Banner';
import { getAvailableTopBannerUseCase } from '../app/Domain/UseCases/Banner/getAvailableTopBanner';
import { createStore } from '../store';

/**
 * @param {{
 *  entityId?: string | number;
 *  onActionButtonClick?: () => void;
 *  isMobile?: boolean;
 * }} props
 * @returns {JSX.Element}
 */

export function AvailableTopBanner({
  entityId,
  onActionButtonClick,
  isMobile,
}) {
  const store = createStore();
  const [bannerTheme, setBannerTheme] = useState({});

  useEffect(() => {
    if (!entityId) {
      return;
    }

    getAvailableTopBannerUseCase({ entityId })
      .then((banner) => {
        setBannerTheme(banner);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [entityId]);

  const actionButtonEventLabel = useMemo(
    () =>
      `
        organizationId: ${store.currentOrganization?.id};
        organizationName: ${store.currentOrganization?.fullname};
        userId: ${store.currentUser?.id};
        userName: ${store.currentUser?.fullname};
        entityId: ${store.currentEntity?.id};
        entityType: ${store.currentEntity?.type};
      `,
    [store],
  );

  if (!bannerTheme?.name || isMobile) {
    return null;
  }

  return (
    <Banner
      shouldUseRemoteImages
      images={bannerTheme.images}
      content={bannerTheme.content}
      actionButton={bannerTheme.actionButton}
      closeButton={bannerTheme.closeButton}
      backgroundColor={bannerTheme.backgroundColor}
      backgroundSize={bannerTheme.backgroundSize}
      backgroundPosition={bannerTheme.backgroundPosition}
      name={bannerTheme.name}
      onActionButtonClick={onActionButtonClick}
      actionButtonEventLabel={actionButtonEventLabel}
    />
  );
}
