import gql from 'graphql-tag';
import { Mutation } from '../../DataSource/ApolloDataSource';

/**
 * @param {string} folderName
 */
export async function deleteFolderRepository(folderName) {
  const mutation = gql`
    mutation WalleDeleteMessageFolder($folderName: ID!) {
      deleteMessageFolder(input: { folderName: $folderName }) {
        success
      }
    }
  `;

  return Mutation(mutation, { folderName });
}
