/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { Grid } from 'semantic-ui-react';
import Avatar from '../../../../components/Avatar';
import { __ } from '../../../../i18n';
import { Icon } from 'semantic-ui-react';
import { ButtonTryAgain } from './TryAgainButton';
import { trackButtonClickEvent } from '../helpers/trackEvent';

const styles = {
  moreRecipients: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '32px',
    height: '32px',
    backgroundColor: '#9696a0',
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: '4em',
    border: 'solid 2px #ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textbelow: {
    display: 'inline-flex',
    color: '#616161',
    fontSize: '13px',
    fontWeight: 'bold',
    width: '100%',
  },
  typeIcon: {
    color: '#9696a0',
  },
  textHeader: {
    display: 'flex',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '1rem',
    marginBottom: '5px',
    marginTop: '-10px',
  },
};

/**
 * @typedef {{
 *   organization: string,
 *   recipientsCount: number,
 *   toEntity: {
 *     fullname: string,
 *     status: number
 *   },
 *   deleted: boolean,
 *   subject: string,
 *   statusSend: string
 *   type: string
 * }} MessageModal
 *
 * @typedef {{
 *   message: MessageModal,
 *   params: {
 *     organization_id: string
 *   },
 * }} Props
 * @param {Props} props
 * @returns {React.JSX.Element}
 */

export function HeaderEntity({ message, client, messageId, onClose }) {
  return (
    <Grid.Row
      columns={2}
      style={{
        display: 'flex',
        backgroundColor:
          message.statusSend === 'ERROR' ||
          message.statusSend === 'PARTIAL_ERROR'
            ? '#FFEBE6'
            : '#F5F5F5',
        width: '100%',
      }}
    >
      <Grid.Column width={13} style={{ display: 'flex', alignItems: 'center' }}>
        <span className="thirteen wide column" style={{ display: 'flex' }}>
          <div
            style={{
              width: '40px',
              height: '40px',
              position: 'relative',
              marginBottom: 3,
            }}
          >
            <Avatar
              avatar
              src={
                message.toEntity &&
                message.toEntity.picture &&
                message.toEntity.picture.uri
              }
              alt={message.toEntity && message.toEntity.fullname}
              style={
                message.deleted
                  ? message.recipientsCount > 1
                    ? { width: '32px', height: '32px', opacity: 0.65 }
                    : { width: '40px', height: '40px', opacity: 0.65 }
                  : message.recipientsCount > 1
                    ? { width: '32px', height: '32px' }
                    : { width: '40px', height: '40px' }
              }
            />
            {
              <div style={styles.moreRecipients}>
                <span>...</span>
              </div>
            }
          </div>
          <div
            style={{
              display: 'flex',
              marginLeft: 10,
              marginTop: 10,
              color: 'black',
              width: '100%',
            }}
          >
            {message.statusSend === 'ERROR' ? (
              <span>
                {message.subject ? (
                  <span style={styles.textHeader}>{message.subject}</span>
                ) : (
                  <div style={{ height: '20px', width: '100%' }} />
                )}
                <div>
                  <div style={styles.textbelow}>
                    <div style={{ display: 'inline-flex', width: '100%' }}>
                      <Icon
                        data-name="error"
                        name="circle exclamation"
                        style={
                          message.deleted
                            ? { opacity: 0.65 }
                            : { ...styles.typeIcon }
                        }
                      />
                      {__(
                        // eslint-disable-next-line quotes
                        'Failed to send: no recipients received.',
                      )}
                    </div>
                    <div
                      style={{
                        display: 'inline-flex',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <ButtonTryAgain
                        client={client}
                        messageId={messageId}
                        closeModal={onClose}
                      />
                    </div>
                  </div>
                </div>
              </span>
            ) : message.statusSend === 'PARTIAL_ERROR' ? (
              <span>
                {message.subject ? (
                  <span style={styles.textHeader}>{message.subject}</span>
                ) : (
                  <div style={{ height: '20px', width: '100%' }} />
                )}
                <div>
                  <div style={styles.textbelow}>
                    <div style={{ display: 'inline-flex', width: '100%' }}>
                      <Icon
                        data-name="error"
                        name="circle exclamation"
                        style={
                          message.deleted
                            ? { opacity: 0.65 }
                            : { ...styles.typeIcon }
                        }
                      />
                      {__(
                        // eslint-disable-next-line quotes
                        'Fail to send: some recipients did not receive.',
                      )}
                    </div>
                    <div
                      style={{
                        display: 'inline-flex',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <ButtonTryAgain
                        client={client}
                        messageId={messageId}
                        closeModal={() => {
                          onClose();
                          trackButtonClickEvent();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </span>
            ) : (
              <div>{__('Sending')}</div>
            )}
          </div>
        </span>
      </Grid.Column>
    </Grid.Row>
  );
}
