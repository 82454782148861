export function mergeGroupsWithRoles(groups, recipients) {
  if(!groups || !recipients) return [];

  return groups.map((group) => {
    group.entities.nodes = group.entities.nodes.map((entity) => {
      entity.roles = recipients.find(r => r.id === entity.id)?.roles;
      return entity;
    });
    return group;
  })
}