import React from 'react';
import { Icon, Loader, Popup } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import cookie from 'react-cookie';

import { __ } from '../../i18n';
import GroupForm from '../../containers/Group/ui/Form';
import BetaModal from './../ui/Modal';
import Input from './../ui/Input';
import Tag from './../ui/Tag';
import ColoredCheckbox from './../ColoredCheckbox';
import Avatar from './../Avatar';
import Button from './Button';
import Responsive from '../Responsive';
import { sendEvent } from '../../lib/mixpanel';
import { asset, isEntityType } from '../../utils';
import { trackAddGroupEvent } from '../../helpers/trackAddGroupEvent';

const entityTypes = {
  ADMIN: __('Admin'),
  STAFF: __('Staff'),
};

const styles = {
  modal: {
    borderRadius: '12px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: '1.429rem',
    color: '#000000',
    fontWeight: 700,
  },
  icon: {
    fontSize: '1.5rem',
    cursor: 'pointer',
    fontWeight: 400,
    color: 'rgba(0, 0, 0, 0.6)',
  },
  primaryButton: {
    backgroundColor: '#084FFF',
    color: '#FFF',
    fontWeight: 'normal',
    marginLeft: '0.75rem',
  },
  cancelButton: {
    backgroundColor: '#FFFFFF',
    color: '#000000',
    border: '1px solid rgba(0, 0, 0, 0.16)',
    fontWeight: 'normal',
    fontSize: '1rem',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  block: {
    boxShadow: '-1px 0px 0px rgba(0, 0, 0, 0.1)',
    height: '2.5rem',
    margin: '2rem 0',
    display: 'flex',
    alignItems: 'center',
  },
};

@inject('store', 'api', 'client')
@graphql(
  gql`
    query OrganizationGroupsQuery(
      $id: ID!
      $search: String
      $orderBy: [OrganizationGroupsOrder]
    ) {
      node(id: $id) @connection(key: "Organization", filter: ["id"]) {
        ... on Organization {
          id: dbId
          fullname
          groups(limit: 400, search: $search, orderBy: $orderBy) {
            nodes {
              id: dbId
              name
              type
            }
            totalCount
          }
        }
      }
    }
  `,
  {
    options: (ownProps) => ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      variables: {
        id: ownProps.store.currentOrganization
          ? ownProps.store.currentOrganization.id
          : ownProps.organizationId,
        search: '',
        orderBy: {
          column: 'CREATED',
          direction: 'DESC',
        },
      },
    }),
  },
)
@graphql(
  gql`
    mutation createGroup($createGroupMutation: CreateGroupInput!) {
      createGroup(input: $createGroupMutation) {
        group {
          id: dbId
          name
          type
        }
        clientMutationId
      }
    }
  `,
  {
    options: {
      refetchQueries: [
        'EntityAddQuery',
        'EntityEditQuery',
        'OrganizationGroupsQuery',
      ],
    },
  },
)
export default class SelectGroupModal extends Responsive {
  constructor(props) {
    super(props);

    let groupsSelected = [];

    if (props.selectedGroupsIds && props.allGroups) {
      groupsSelected = props.selectedGroupsIds.map((id) => {
        const group = props.allGroups.find((obj) => obj.id === id);
        return { id, name: group.name, type: group.type };
      });
    }
    if (props.selectedGroupsObjects) {
      groupsSelected = props.selectedGroupsObjects;
    }

    this.state = {
      loading: false,
      selectedItemsProps: [...groupsSelected],
      selectedItems: groupsSelected,
      searchText: '',
    };
  }

  onGroupAdd = () => {
    const { searchText } = this.state;
    const { store } = this.props;
    const organization = store && store.currentOrganization;

    store.appends.push(
      <BetaModal
        id="AddGroup"
        onClose={() => store.appends.pop()}
        closeOnBack
        actions={[
          <Button
            data-action="cancel"
            transparent
            round
            text={__('Cancel')}
            style={{ marginBottom: '0px' }}
            onClick={() => store.appends.pop()}
          />,
        ]}
        header={__('Add group')}
        content={
          <GroupForm
            modal
            values={{ name: searchText }}
            loading={this.state.loading}
            onSubmit={this.onSubmitGroupAdd}
            submitButton={this.isMobile() ? __('Add') : __('Add group')}
            submitButtonIcon="check"
            tags={organization && organization.tags}
          />
        }
      />,
    );
  };

  onSubmitGroupAdd = (values) => {
    const { mutate, store } = this.props;
    const organization = store && store.currentOrganization;

    this.setState({ loading: true });

    mutate({
      variables: {
        createGroupMutation: {
          ...values,
          organizationId: organization
            ? organization.id
            : this.props.organizationId,
        },
      },
    }).then((res) => {
      const group = res.data.createGroup.group;
      const selectedItems = [...this.state.selectedItems];
      selectedItems.push({
        id: group.id,
        name: group.name,
        type: group.type,
      });
      this.setState({ selectedItems, searchText: group.name, loading: false });
      this.refreshQuery();
      store.appends.pop();
      trackAddGroupEvent({
        groupName: group.name,
        markerIds: values.markerIds,
      });
    });
  };

  validate = () => {
    const selectedItems = [...this.state.selectedItems].map((i) => i.id).sort();
    const selectedItemsProps = [...this.state.selectedItemsProps]
      .map((i) => i.id)
      .sort();
    if (selectedItems.length === 0) {
      return false;
    }
    const isEqual = selectedItems.filter(
      (item, index) => item !== selectedItemsProps[index],
    ).length;
    return isEqual !== 0;
  };

  isSelectAllChecked = () => {
    const { data, usedGroups } = this.props;
    const { selectedItems } = this.state;

    if (usedGroups) {
      if (this.isSelectAllDisabled()) return false;
      const availableItems = data.node.groups.nodes.filter(
        (item) => !usedGroups.includes(item.id),
      );
      if (availableItems.length === selectedItems.length) return true;
    } else if (data.node.groups.nodes.length === selectedItems.length)
      return true;
    return false;
  };

  isSelectAllDisabled = () => {
    const { data, usedGroups } = this.props;
    return usedGroups && usedGroups.length === data.node.groups.nodes.length;
  };

  handleClick = () => {
    const { store } = this.props;
    const { selectedItems } = this.state;
    this.props.onSubmit(selectedItems);
    store.appends.pop();
  };

  handleTextChange = (e) => {
    clearTimeout(this.lastRequestId);
    let id;
    this.setState({ searchText: e.target.value });
    // eslint-disable-next-line no-multi-assign
    this.lastRequestId = id = setTimeout(() => this.refreshQuery(), 500);
  };

  handleToggleCheckbox = (data) => {
    const selectedItems = [...this.state.selectedItems];
    const foundSelected = selectedItems.findIndex(
      (item) => item.id === data.id,
    );

    if (foundSelected !== -1) {
      selectedItems.splice(foundSelected, 1);
    } else {
      selectedItems.push(data);
    }

    this.setState({ selectedItems });
  };

  handleToggleSelectAll = () => {
    const { data, usedGroups } = this.props;
    if (!this.isSelectAllChecked()) {
      let selectedItems = [...data.node.groups.nodes];
      if (usedGroups && usedGroups.length > 0) {
        selectedItems = selectedItems.filter(
          (item) => !usedGroups.includes(item.id),
        );
      }

      this.setState({ selectedItems });
    } else {
      this.setState({ selectedItems: [] });
    }
  };

  refreshQuery = () => {
    const { data, store } = this.props;
    const { searchText } = this.state;

    data.refetch({
      id: store.currentOrganization
        ? store.currentOrganization.id
        : this.props.organizationId,
      search: searchText,
      orderBy: {
        column: 'CREATED',
        direction: 'DESC',
      },
    });
  };

  renderGroupAdd = () => {
    const { searchText } = this.state;

    return (
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Button
          icon={{ name: 'plus' }}
          text={
            <span>
              {__('Add')}{' '}
              <span style={{ fontWeight: 'bold' }}>{searchText}</span>
            </span>
          }
          onClick={() => this.onGroupAdd()}
          style={{
            width: '100%',
            height: '24px',
            textAlign: 'left',
            backgroundColor: '#FFFFFF',
            color: '#000000',
          }}
        />
      </div>
    );
  };

  renderItem = (data) => {
    const { withSelectAll } = this.props;
    const isChecked =
      this.state.selectedItems.findIndex((item) => item.id === data.id) !== -1;
    const { name, id } = data;
    const backgroundColor = !isChecked ? '#FFF' : '#F2F7FF';

    return (
      <div
        key={id}
        style={{
          backgroundColor,
          padding: '8px 8px 8px 16px',
          borderRadius: '8px',
          margin: withSelectAll ? '1px 0 1px 8px' : '1px 0',
          cursor: 'pointer',
        }}
        onClick={() => this.handleToggleCheckbox(data)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <ColoredCheckbox
            radioChecked
            checked={isChecked}
            color="#084FFF"
            disableWithoutBanIcon
            style={{
              width: '24px',
              height: '24px',
              fontWeight: 'bold',
              marginRight: '10px',
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '1rem',
            }}
          >
            <span>{name}</span>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { selectedItems } = this.state;
    const { data, withSelectAll, showSelectedTotalCount, usedGroups } =
      this.props;

    if ((data.loading && !data.node) || !data.node)
      return <Loader active inline="centered" />;

    const actionStyle = this.isMobile()
      ? {}
      : {
        paddingLeft: '14px',
        paddingRight: '14px',
      };

    let className;
    if (showSelectedTotalCount && !this.isMobile()) className = 'small-modal';

    return (
      <BetaModal
        id="SelectGroup"
        style={{ margin: '0 0 0 -275px' }}
        className={className}
        header={__('Add group')}
        fullScreen={this.isMobile()}
        fixHeader={this.isMobile()}
        fixActions={this.isMobile()}
        actions={
          <div
            style={{
              ...styles.footer,
              marginTop: this.isMobile() ? '0px' : '3rem',
              ...actionStyle,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {showSelectedTotalCount ? (
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    lineHeight: '3rem',
                    color: '#00000099',
                  }}
                >
                  {selectedItems.length > 0
                    ? selectedItems.length > 1
                      ? __('%s selected', selectedItems.length)
                      : __('1 selected')
                    : __('None selected')}
                </span>
              ) : (
                <Button
                  transparent
                  round
                  style={{ marginRight: '12px' }}
                  text={__('Cancel')}
                  onClick={() => {
                    this.props.store.appends.pop();
                  }}
                />
              )}
              <Button
                round
                disabled={!this.validate()}
                text={__('Add')}
                style={styles.button}
                icon={{ name: 'check' }}
                onClick={() => {
                  this.handleClick();
                }}
              />
            </div>
          </div>
        }
        onClose={() => this.props.store.appends.pop()}
        subHeader={
          this.isMobile() && (
            <div style={{ padding: '5px 20px 5px 16px', lineHeight: '1.4' }}>
              <Input
                type="search"
                onChange={this.handleTextChange}
                placeholder={__('Search... (Select Group)')}
                onClear={() => this.setState({ searchText: '' })}
              />
            </div>
          )
        }
        content={
          <div
            style={{
              ...styles.container,
              paddingBottom: this.isMobile() ? '150px' : '0px',
            }}
          >
            {!this.isMobile() && (
              <Input
                type="search"
                onChange={this.handleTextChange}
                placeholder={__('Search... (Select Group)')}
                onClear={() => this.setState({ searchText: '' })}
              />
            )}
            <div
              style={{
                marginTop: '1rem',
                overflow: this.isMobile() ? 'unset' : 'auto',
                height: this.isMobile()
                  ? 'unset'
                  : showSelectedTotalCount
                    ? 200
                    : 400,
              }}
            >
              {withSelectAll && data.node.groups.nodes.length > 0 && (
                <div
                  key={'selectAll'}
                  style={{
                    backgroundColor: this.isSelectAllChecked()
                      ? '#F2F7FF'
                      : '#0000000F',
                    padding: '8px 8px 8px 16px',
                    borderRadius: '8px',
                    opacity: this.isSelectAllDisabled() ? '0.4' : null,
                    cursor: this.isSelectAllDisabled() ? null : 'pointer',
                  }}
                  onClick={() => this.handleToggleSelectAll()}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <ColoredCheckbox
                      radioChecked
                      checked={this.isSelectAllChecked()}
                      disabled={this.isSelectAllDisabled()}
                      disableWithoutBanIcon
                      color="#084FFF"
                      style={{
                        width: '24px',
                        height: '24px',
                        fontWeight: 'bold',
                        marginRight: '10px',
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '1rem',
                      }}
                    >
                      <span>{__('Select all')}</span>
                    </div>
                  </div>
                </div>
              )}
              {data.node.groups.nodes.length > 0
                ? data.node.groups.nodes.map((item) => this.renderItem(item))
                : this.renderGroupAdd()}
            </div>
            {!showSelectedTotalCount && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  marginTop: '1rem',
                  paddingLeft: this.isMobile() ? '11px' : '0px',
                  paddingRight: this.isMobile() ? '11px' : '0px',
                }}
              >
                {selectedItems.map((item) => (
                  <Tag
                    style={{ margin: '0.4rem 0.3rem' }}
                    tooltipOnOverflow
                    name={item.name}
                    className="transparent big"
                    onDelete={() => {
                      this.handleToggleCheckbox({ ...item });
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        }
        size="tiny"
      />
    );
  }
}
