export const getAvailableTopBannerMock = {
  data: {
    node: {
      webAvailablesTopBanner: {
        name: 'Top Banner',
        backgroundColor: '#000',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        images: {
          left: 'left-image.jpg',
          right: 'right-image.jpg',
        },
        content: {
          plainText: ['plain text'],
          boldText: 'bold text',
          color: '#fff',
          textAlign: 'center',
        },
        actionButton: {
          text: 'Action',
          color: '#fff',
          backgroundColor: '#000',
          url: 'https://example.com',
        },
        closeButton: {
          color: '#fff',
          backgroundColor: '#000',
        },
      },
    },
  },
};
