/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import { AttachmentFile } from '../AttachmentFile';
import { render } from '../../../../../newRender';
import PubSub from 'pubsub-js';

jest.mock('@classapp/sebastiao-orchestrator', () => ({
  RemoteMountedComponent: jest.fn(() => (
    <div data-testid="remote-mounted-component" />
  )),
}));

jest.mock('../../../../../utils', () => ({
  createUrlWithHash: jest.fn(() => 'mocked-url'),
}));

describe('<AttachmentFile />', () => {
  let props;

  beforeEach(() => {
    jest.clearAllMocks();

    PubSub.publish = jest.fn();
    PubSub.subscribeOnce = jest.fn().mockReturnValue(1);
    props = {
      env: 'local',
      folderName: 'test-folder',
    };
  });

  it('should render component', () => {
    const { container } = render(<AttachmentFile {...props} />);
    expect(container).not.toBeEmpty();
  });

  it('updates correctly when folderName changes', () => {
    const { rerender } = render(<AttachmentFile folderName="test-folder" />);
    const publishMock = PubSub.publish;

    rerender(<AttachmentFile folderName="new-test-folder" />);

    expect(publishMock).toHaveBeenCalledTimes(2);
    expect(publishMock).toHaveBeenCalledWith('SULLY_MICROFRONTEND_DATA', {
      folderName: 'new-test-folder',
    });
  });

  it('executes useEffect correctly on mount', () => {
    const publishMock = PubSub.publish;

    render(<AttachmentFile folderName="test-folder" />);

    expect(PubSub.subscribeOnce).toHaveBeenCalledTimes(1);
    expect(PubSub.subscribeOnce).toHaveBeenCalledWith(
      'MICROFRONTEND_READY',
      expect.any(Function),
    );

    expect(publishMock).toHaveBeenCalledTimes(1);
    expect(publishMock).toHaveBeenCalledWith('SULLY_MICROFRONTEND_DATA', {
      folderName: 'test-folder',
    });
  });
});
