import { getAvailableTopBannerRepository } from '../../../Data/Repository/Banner/getAvailableTopBanner';

/**
 * @param {{
 *  entityId: string | number;
 * }} param
 * @returns {Promise<import('../../../Data/Repository/Banner/getAvailableTopBanner').TResponse['data']['node']['webAvailablesTopBanner']>}
 */
export async function getAvailableTopBannerUseCase({ entityId }) {
  const { data, errors } = await getAvailableTopBannerRepository({ entityId });

  if (errors) {
    throw new Error(errors[0].message);
  }

  return data.node.webAvailablesTopBanner;
}
